import { Box, Flex, Grid, Heading, Image, ListItem, Spacer, Text, UnorderedList } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { useEffect, useRef } from "react";

function AboutUs() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  return (
    <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../../img/pagecovers/about-us-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Our Story
        </Text>
      </Box>

        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "1fr 1fr"]}
          gap={4}
          p={4}
          mt={2}
          mx="auto">
        <Flex flex={'1'} flexDirection={'column'}>
        <Box position="relative" width="100%" height="auto" p={4} aspectRatio="16/9">
          {/* <video ref={videoRef} width="100%" controls autoPlay style={{ borderRadius: "10px"}}>
            <source src={require('../video/NHCoverVideo.mp4')} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}

        <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/rh8KnOCP1RI"
            frameBorder="0"
            allowFullScreen
            style={{ borderRadius: "10px" }}
          />

        {/* <iframe width="100%" 
        height="100%" 
        src="https://www.youtube.com/embed/rh8KnOCP1RI" 
        title="YouTube video player" 
        // frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullscreen
        
        >

        </iframe> */}

          {/* <Image
            ref={thumbnailRef}
            src={require('../img/nawaloka-hospital-about.jpg')}
            alt="Video Thumbnail"
            width="100%"
            height="auto"
            display={!isPlaying ? "block" : "none"}
            position="absolute"
            top="0"
            left="0"
          /> */}
        </Box>
        <Text p={5} width="100%" textAlign={'justify'} fontWeight={'semibold'}>
        NAWALOKA HOSPITALS PLC IS SRI LANKA’S FIRST PRIVATE PREMIUM HEALTHCARE SERVICES PROVIDER, 
        DELIVERING PRIMARY, SECONDARY, AND TERTIARY HEALTHCARE SERVICES UNDER THE NAWALOKA HOSPITALS GROUP.
        </Text>
        <Text pr={5} pl={5} pb={5} width="100%" textAlign={'justify'}>
        The Nawaloka Hospital was established in 1985 by our founder Chairman Deshamanya H K Dharmadasa. 
        It has since expanded to a Group consisting of Nawaloka Hospitals PLC and four fully owned 
        subsidiaries. Nawaloka Hospitals PLC was listed on the Colombo Stock Exchange in 2004.
        <Box as="br" /><Box as="br" />

        Over the last 37 years, Nawaloka Hospital has set the benchmark and led the way for private 
        healthcare in Sri Lanka, being the pioneer to introduce advanced medical technology and 
        multi-specialist consultation and medical care in the private healthcare sector.


        </Text>
        </Flex>
        <Box>
        <Box>
          <Box mt={5} ml={10} pr="15px">
            <Heading size="md">VISION</Heading>
            <Heading size="sm">“To Be the Hospital of Tomorrow”</Heading>
            <Text p={2} width="100%" textAlign={'justify'}>
              To provide quality and safe healthcare to the people whilst maintaining
              leadership and excellence in the healthcare facility.
            </Text>
          </Box>
          <Box mt={5} ml={10} mb={5} pr="15px">
            <Heading size="md">MISSION</Heading>
            <Heading size="sm">“Healing with Feeling”</Heading>
            <Text p={2} width="100%" textAlign={'justify'}>
              To provide the best quality healthcare in accordance with international
              standards to the needy in a cost effective, timely and professional manner
            </Text>
          </Box>
          <Box mt={5} ml={10} mb={5} pr="15px">
            <Heading size="md">OUR VALUES</Heading>
            <Text p={2} width="100%" textAlign={'justify'}>
            Nawaloka Hospitals PLC understands the dynamic and urgent nature of the healthcare sector, 
            which has evolved over the decades from a reactive to a proactive service. In this rapidly 
            changing environment, Nawaloka Hospital medical and support teams have identified five 
            strategic areas to focus and invest time and resources. 
            </Text>
            <Text p={2} width="100%">These are:</Text>
            <UnorderedList p={2} width="100%">
                <ListItem>Strengthen Safety and Quality</ListItem>
                <ListItem>Drive Innovation, Technology and Research</ListItem>
                <ListItem>Enable Our People</ListItem>
                <ListItem>Plan for a Sustainable Future</ListItem>
                <ListItem>Achieve Financial Health</ListItem>
            </UnorderedList>
          </Box>
          <Box mt={5} ml={10} mb={5} pr="15px">
            <Heading size="md">QUALITY POLICY</Heading>
            <Text p={2} width="100%" textAlign={'justify'}>
            Nawaloka Hospitals will at all times work towards a common goal of total customer satisfaction by providing 
            affordable services whilst maintaining highest standards of professionalism with quality, speed, accuracy, 
            safety, integrity, and courtesy, committing to achieve statutory, regulatory, and ethical requirements.
            <Box as="br" /><Box as="br" />
            This shall be achieved through;
            </Text>
            <UnorderedList p={2} pl={4} width="100%" styleType="circle">
                <ListItem>
                Utilization of
                  <UnorderedList pl={5} styleType="square">
                    <ListItem>Better Management Practices </ListItem>
                    <ListItem>Modern & Latest Technologies in the Healthcare Industry</ListItem>
                    <ListItem>NHL’s Strengths and Affiliations</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>A Quality Management System which conforms to ISO 9001.</ListItem>
                <ListItem>Continual Improvement and Effective Measures through service feedback.</ListItem>
                <ListItem>Staff  Dedication, Commitment and Team Work to bring that smile of content to every customer.</ListItem>
            </UnorderedList>
          </Box>

          {/* <Flex flexDirection={['column','row']}>
          <Grid templateColumns={['1fr','2fr','1fr 2fr']}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/jci-gold-seal.png')} w={'100px'}
            h={'100px'} alt="test"/>
        </Box>

        <Box mb={5} pr="15px">
            <Heading size="md">MISSION</Heading>
            <Heading size="sm">“Healing with Feeling”</Heading>
            <Text p={2} width="100%">
              To provide the best quality healthcare in accordance with international
              standards to the needy in a cost effective, timely and professional manner
            </Text>
          </Box>
      </Grid>
            </Flex> */}


        </Box>
        </Box>
        </Grid>
        <FooterItem/>
        </Box>
    </div>
  )
}

export default AboutUs
