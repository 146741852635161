import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
    Flex, FormControl, Grid, Heading, Input, Link, List, ListItem, OrderedList, Select, Stack, 
    Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
  import React, { useState }  from 'react'
  import Header from '../../components/Header'
  import FooterItem from '../../components/FooterItem'
  import { Link as RouterLink, useNavigate } from "react-router-dom";
  import { SearchIcon } from '@chakra-ui/icons';
  import { useSelector, useDispatch } from "react-redux";
  import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
  import * as actions from "../store/actions";
  import LatestItems from '../../components/LatestItems';
  import LatestItems1 from '../../components/LatestItems1';
  
  function Nutrition() {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
      const [selectedDate, setSelectedDate] = useState("Any");
      const [doctorName, setDoctorName] = useState("*");
      const handleDateChange = (event) => {
          setSelectedDate(event.target.value);
        };
      const handleClearDate = () => {
          setSelectedDate(null);
        };
  
          //--------------------------------------------------------------------------------------------------------------
    const getDoctorList = () => {
      // console.log(selectedDate);
      // console.log(spId);
      // console.log(doctorName);
  
      sessionStorage.setItem('selectedDate', selectedDate);
      sessionStorage.setItem('spId', '45');
      sessionStorage.setItem('doctorName', doctorName);
  
      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          sp_id: '45',
          date: selectedDate,
          name: doctorName,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {
            const payload = {
              data: object.doctort_list,
            };
            // console.log(object.doctort_list);
            dispatch(actions.getDoctorList(payload));
            // Link
            navigate("/doctorsearch");
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    };
  
    return (
      <div>
          <Header/>
        {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}
  
        <Box
          backgroundImage={require('../../img/pagecovers/fertlity-strip.png')}
          backgroundSize="cover"
          backgroundPosition="center"
          height="200px"
          display="flex"
          mt="10px"
          alignItems="center"
        >
          <Text
            fontSize="4xl"
            fontWeight="bold"
            ml="40px"
            color="white"
            textShadow="0 2px 4px rgba(0,0,0,0.5)"
          >
            Nutrition & Dietetics
          </Text>
        </Box>
  
        <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
          <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
          <Flex>
          <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
        </Flex>
        <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
          <Heading size="xs" color="white" mb="5px">Dietician And Nutrician</Heading>
          <FormControl>
          {/* <FormLabel>First name</FormLabel> */}
          <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
          onChange={(event) => setDoctorName(event.target.value)} />
          <Box display="flex" alignItems="center">
            <Input
              type="date"
              value={selectedDate || ''}
              onChange={handleDateChange}
              px={3}
              py={2}
              borderRadius="md"
              borderColor="gray.300"
              background="white"
              top="5px"
              mb={25}
              width="100%"
              _hover={{ borderColor: 'gray.400' }}
              _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
            />
            {/* {selectedDate && (
              <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
              top="50px"
              left="30px">
                Clear
              </Button>
            )} */}
          </Box>
          </FormControl>
  
          <Stack direction='row' spacing={4}>
              {/* <Link as={RouterLink} to='/doctorsearch'> */}
              <Button leftIcon={<SearchIcon />} 
              bg="#0E5453" 
              color="white" 
              variant='solid'
              // top="170px"
              // left="-190px"
              _hover={{
                bg: '#004242',
              }}
              _focus={{
                bg: '#004242',
              }}
              onClick={() => getDoctorList()}
              >
                  Find
              </Button>
              {/* </Link> */}
              </Stack>
        </Flex>
        {/* <Flex>
          <Heading mt="20px" ml="20px" size={'md'} mb="5px">Resident Rheumatologist</Heading>
        </Flex>
        <Flex borderWidth="1px" borderRadius="lg" p={4} 
        display="flex" width="100%" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
          <Avatar size="2xl" src={require('../../img/doctorImages/dr-harindu.jpg')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
          <VStack spacing={0} alignItems="center">
              <Text fontSize="xl" fontWeight="bold" color="white">Dr. Harindu Wijesinghe</Text>
              <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                  MBBS IS.L.I, MD [Coll, MCP (UK)]{'\n'}
                  Consultant in Rheumatology & Rehabilitation{'\n'}
                  Specialist in Sports Medicine
              </Text>
          </VStack>
        </Flex> */}
  
        <Flex>
          <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
        </Flex>
        <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
        display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
          <VStack spacing={0} alignItems="center">
              <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
              0115 777 777 {'\n'}
              0115 777 888
              </Text>
          </VStack>
        </Box>
        </Flex>
        <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
          <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
          <Box textAlign={'justify'}>
         
  
          <Text whiteSpace={"pre-line"}>
          Welcome to the world of nutritional wellness, where well trained Dietitians and Nutritionists are poised to 
          guide you on a journey toward optimal health. 
          {'\n'}{'\n'}
          We understand that each person is an individual, and there is no one-size-fits-all solution for health. 
          Whether you're seeking balanced diets for different stages of life cycle (pediatric, adults, geriatric, 
          pregnancy and lactation), weight management, tackling specific health concerns or disease conditions or 
          simply aiming for a more balanced lifestyle, we Dietitians, craft tailored nutrition plans that resonate 
          with your goals.
          </Text>
          <Text mt={4} mb={2} fontWeight={'bold'}>
          Join us on the path to a healthier, more vibrant you – where nutrition isn't just a prescription but a 
          transformative force for a life well-lived.
            </Text>
          
          <Text mt={4} mb={2} fontWeight={'semibold'}>
          Here are some key services that a Hospital Dietitian typically provides:
          </Text>
  
          <OrderedList>
            <ListItem>
              <Text mt={4} fontWeight={'semibold'}>
              Disease Specific Diet Counseling
              </Text>
              <Text>
              Design and implement therapeutic diets tailored to specific medical conditions such as diabetes, 
              cardiovascular diseases (heart diseases), kidney diseases, fatty liver, liver cirrhosis, gastro-intestinal 
              disorders (gastritis, irritable bowel syndrome etc..)
              </Text>
            </ListItem>
            <ListItem>
              <Text mt={4} fontWeight={'semibold'}>
              Weight Management
              </Text>
              <Text>
              Plan diet schedules for weight gain, weight reduction & weight maintenance.
              </Text>
            </ListItem>
            <ListItem>
              <Text mt={4} fontWeight={'semibold'}>
              Specialized Diets 
              </Text>
              <Text>
              Plan texture modified diets such as liquid, semi-solid, soft diets.
              </Text>
            </ListItem>
            <ListItem>
              <Text mt={4} fontWeight={'semibold'}>
              Enteral (Tube Feeds: NG/PEG) and Parenteral Nutrition Support
              </Text>
              <Text>
              Manage the nutritional needs of patients who cannot consume food orally. They administer enteral or 
              parenteral nutrition and monitor and adjust nutrition support regimens based on patients' evolving 
              medical conditions.
              </Text>
            </ListItem>
            <ListItem>
              <Text mt={4} fontWeight={'semibold'}>
              Nutrition Education
              </Text>
              <Text>
              Provide individual and group counseling sessions for patients & also public awareness sessions on 
              healthy eating habits, lifestyle modifications, weight management, malnutrition, and nutritional 
              deficiencies.
              </Text>
            </ListItem>
          </OrderedList>
          </Box>    
 
          <Text whiteSpace={"pre-line"} mt={4}>
          Hospital Dietitians play a pivotal role in the comprehensive care of patients, contributing significantly to 
          their overall well-being and recovery. They work closely with your physicians and surgeons in routine 
          healthcare practices to ensure the best for you.   
          </Text>
              
        </Flex>
        </Grid>
        <Flex flexDirection={'column'} alignItems="left" p={4}>
          <LatestItems1/>
        </Flex>
        </Grid>
        <FooterItem/>
      </div>
    )
  }
  
  export default Nutrition
  