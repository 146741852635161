import { Box, Button, Flex, Grid, GridItem, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem';

function EyeCare() {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };


  const gridItems = [
    {
      imageSrc: 'https://bizimages.withfloats.com/tile/62b077812488bb0001136357.jpg',
      title: 'Eye Investigations',
      description: ' Our comprehensive range of diagnostic services aims to assess visual acuity, detect refractive errors, glaucoma (Eye Pressure), cataracts, macular degeneration, and various other eye conditions. Our experienced team of ophthalmologists and state-of-the-art equipment ensure accurate and detailed evaluations. Whether you require a routine eye exam or need a specific condition diagnosed, trust us to provide thorough investigations and expert insights into your eye health. Schedule an appointment at Nawaloka Hospital for comprehensive and precise eye investigations.',
    },
    {
      imageSrc: 'https://media.istockphoto.com/id/1199908661/photo/physiotherapist-treatment-patient-she-holding-patients-hand-shoulder-joint-treatment.jpg?s=612x612&w=0&k=20&c=yghgsRCfhifTxzIS8UqlHIxpyyHDUNXkfqwQHABDRuY=',
      title: 'Refractive Services',
      description: 'Discover the freedom of clear vision with our Refractive Services at Nawaloka Hospital. Our skilled ophthalmologists specialize in correcting common vision problems such as nearsightedness, farsightedness, and astigmatism. Using advanced techniques like LASIK and other refractive surgeries, we offer personalized treatment plans tailored to your specific needs. Experience the joy of clear vision without the hassle of glasses or contact lenses. Explore our Refractive Services at Nawaloka Hospital and take the first step towards a life of visual clarity.',
    },
    {
      imageSrc: 'https://5.imimg.com/data5/SM/TR/GF/SELLER-60121706/refractive-lens-exchange-surgery-service.jpeg',
      title: 'Treatments for Eye conditions',
      description: 'At Nawaloka Hospital, we offer specialized treatments for a wide range of eye conditions. Our dedicated team of ophthalmologists is experienced in managing various conditions, including dry eyes, conjunctivitis (pink eye), glaucoma, diabetic retinopathy, and corneal diseases. With a focus on personalized care, we provide comprehensive treatment plans tailored to each patient`s unique needs. Whether you require advanced therapies, surgical interventions, or ongoing management, our expert team is here to help you achieve optimal eye health.',
    },
    {
      imageSrc: 'https://www.dishaeye.org/blog/wp-content/uploads/2020/12/r1oTf9uP5C.jpg',
      title: 'Surgical Procedures',
      description: 'Experience cutting-edge surgical procedures at Nawaloka Hospital. Our skilled team of surgeons specializes in a variety of advanced treatments, including cataract surgery, corneal transplantation, refractive surgeries, and treatment for retinal disorders. With state-of-the-art technology and a patient-centered approach, we provide safe and effective solutions to restore and enhance your vision. From removing cataracts to correcting refractive errors and addressing retinal conditions, our surgical procedures are tailored to meet your individual needs. Trust us for exceptional surgical care and achieve optimal eye health at Nawaloka Hospital Colombo.',
    },{
      imageSrc: 'https://d2jx2rerrg6sh3.cloudfront.net/image-handler/picture/2021/4/shutterstock_1175895889.jpg',
      title: 'Lasik Treatments',
      description: 'Experience life without glasses or contact lenses with LASIK treatments at Nawaloka Hospital. Our skilled ophthalmologists utilize state-of-the-art technology and advanced laser techniques to correct common vision problems like nearsightedness, farsightedness, and astigmatism. LASIK offers a safe and effective solution for achieving clear vision, often with minimal downtime. With personalized treatment plans and a commitment to your comfort and safety, we strive to deliver exceptional results. Discover the freedom of clear vision and schedule a consultation for LASIK treatments at Nawaloka Hospital today.',
    },{
      imageSrc: 'https://blog.icarelabs.com/hs-fs/hubfs/Stock%20images/Woman%20wearing%20glasses%20after%20taking%20a%20vision%20test%20at%20the%20doctor.jpeg?width=1200&name=Woman%20wearing%20glasses%20after%20taking%20a%20vision%20test%20at%20the%20doctor.jpeg',
      title: 'Optical Needs',
      description: 'Catering to all your optical needs, Nawaloka Hospital offers a comprehensive range of services to support your vision requirements. Our dedicated team of professionals is here to assist you with expert guidance in selecting the right eyewear, including prescription glasses and contact lenses. We provide accurate eye examinations to ensure precise prescriptions and offer a wide selection of high-quality eyewear options to suit your style and preferences. Trust us to meet your optical needs and enhance your visual comfort and clarity. Visit Nawaloka Hospital for personalized optical solutions that prioritize your eye health and satisfaction.',
    },
  ];

  return (
    <Box>
        <Header/>
        <Box
        backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Eye Care
        </Text>
      </Box>

      
      <Box mt={5} pl={20} pr={20} textAlign={'justify'}>
      <Text fontSize={'lg'}>
      Welcome to Nawaloka Hospital's Eye Care! Our experienced team of ophthalmologists offers comprehensive 
      Eye care services using state-of-the-art technology. From routine exams to specialized treatments and surgeries, 
      we provide personalized care for various eye conditions. Trust us to prioritize your visual health and well-being. 
      Schedule an appointment today and experience the exceptional eye care services at Nawaloka Hospital Colombo.
      </Text>
      </Box>
      
      <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
      
      <Grid
      templateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)']}
      gap={6}
      mt={6}
      mb={5}
      pl={5}
      pr={5}
      width="80%"
      textAlign="center"
    >
      {gridItems.map((item, index) => (
        <GridItem key={index} w="100%" h="280" position="relative">
          <img src={item.imageSrc} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%' }} />
          <Box
            onClick={() => onOpen(index)}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            background="rgba(0, 150, 255, 1.0)"
            opacity="0.5"
            _hover={{
              background: 'rgba(0, 150, 255, 0.3)',
              cursor: 'pointer',
            }}
          ></Box>
          <Box
            onClick={() => onOpen(index)}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="#ffffff"
            fontWeight="bold"
            fontSize="20px"
            textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)"
            _hover={{
              cursor: 'pointer',
            }}
          >
            {item.title}
          </Box>
        </GridItem>
      ))}

      {selectedModalIndex !== null && (
        <Modal isOpen={isOpen} onClose={onClose} >
          <ModalOverlay />
          <ModalContent>
          <Box
            bgImage="url('https://www.dishaeye.org/blog/wp-content/uploads/2020/12/r1oTf9uP5C.jpg')" // Replace '/path/to/cover-image.jpg' with the actual path to your cover image
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            borderBottom="1px solid"
            borderColor="gray.200"
            p={4}
          >
            <ModalHeader h="200px" color="white" textShadow="0 2px 4px rgba(0,0,0,0.5)">{gridItems[selectedModalIndex].title}</ModalHeader>
            <ModalCloseButton />
          </Box>
            <ModalBody mb={'5'}>{gridItems[selectedModalIndex].description}</ModalBody>
            {/* <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button> */}
              {/* <Button variant="ghost">Secondary Action</Button> */}
            {/* </ModalFooter> */}
          </ModalContent>
        </Modal>
      )}
    </Grid>

      </Box>

      <Flex flexDirection={['column', 'row']} pl={10} pr={10}>
        <Box width={['100%', '60%']} p={8} order={[2, 1]}>
            
        </Box>
        <Box width={['100%', '40%']} padding={10} order={[1, 2]}>
            
        </Box>
      </Flex>

        <FooterItem />
    </Box>
  )
}

export default EyeCare