import { Box, Button, Card, CardBody, CardHeader, Flex, Grid, Heading, Image, Link, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { motion } from 'framer-motion';
import { HiOutlineDocument } from 'react-icons/hi';
import DownloadPDF from '../../components/DownloadPDF';

const cardHeight = '370px';

function Reports() {

  const cardVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };


  const [pdfs, setPdfs] = useState([]);

  const pdfEmbedUrl = 'https://drive.google.com/uc?id=15KrAXM60LUOg8z-SiJJ1vuiA70uoDX42';

  useEffect(() => {
    // Fetch PDF files from your backend or import locally
    // For demonstration purposes, let's assume pdfsData is an array of PDF objects
    const pdfsData = [
      { 
        id: 1, 
        title: 'Annual Report 2023-2024', 
        url: '../Nawaloka_Hospitals_PLC_AR_2023-24.pdf', 
        imageURL: require('../../img/icons/report23-24pic.png'),
      },
      { 
        id: 2, 
        title: 'Annual Report 2022-2023', 
        url: '../Nawaloka_Hospitals_PLC_AR_2022-23.pdf', 
        imageURL: require('../../img/icons/report22-23pic.png'),
      },
      { 
        id: 3, 
        title: 'Annual Report 2021-2022', 
        url: '../Nawaloka_Hospitals_PLC_AR_2021-22.pdf', 
        imageURL: require('../../img/icons/report21-22pic.png'),
      },
      { 
        id: 4, 
        title: 'Annual Report 2020-2021', 
        url: '../Nawaloka_Hospitals_PLC_AR_2020-21.pdf', 
        imageURL: require('../../img/icons/report20-21pic.png'),
      },

      { id: 5, 
        title: 'Annual Report 2019-2020', 
        url: '../Nawaloka_Hospitals_PLC_AR_2019-20.pdf',
        imageURL: require('../../img/icons/report19-20pic.png'), 
      },

      { id: 6, 
        title: 'Annual Report 2018-2019', 
        url: '../Nawaloka_Hospitals_PLC_AR_2018-19.pdf',
        imageURL: require('../../img/icons/report18-19pic.png'), 
      },

      { id: 7, 
        title: 'Annual Report 2017-2018', 
        url: '../Nawaloka_Hospitals_PLC_AR_2017-18.pdf',
        imageURL: require('../../img/icons/report17-18pic.png'), 
      },
      // ...
    ];
    setPdfs(pdfsData);
  }, []);

  const handleOpenInNewTab = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  const handleOpenInNewTab1 = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  const links = [
    { label: "Policy on matters relating to the Board of Directors - NHL", url: "../(a)-Policy-on-matters-relating-to-the-Board-of-Directors-NHL-(Approved).pdf"},
    { label: "Board Charter - NHL", url: "../(aa)-Board-Charter-NHL-(Approved).pdf"},
    { label: "Policy on Board Committees - NHL", url: "../(b)-Policy-on-Board-Committees-NHL-(Approved).pdf"},
    { label: "Policy on Corporate Governance Nominations and Re-election - NHL", url: "../(c)-Policy-on-Corporate-Governance-Nominations-and-Re-election-NHL-(Approved).pdf"},
    { label: "Board Remuneration Policy - NHL", url: "../(d)-Board-Remuneration-Policy-NHL-(Approved).pdf"},
    { label: "Policy on Internal Code of Business Conduct & Ethics - NHL", url: "../(e)-Policy-on-Internal-Code-of-Business-Conduct-&-Ethics-NHL-(Approved).pdf" },
    { label: "Policy on Risk Managment and Internal Controls - NHL", url: "../(f)-Policy-on-Risk-Managment-and-Internal-Controls-NHL-(Approved).pdf"},
    { label: "Policy on Relations with Shareholders and Investors - NHL", url: "../(g)-Policy-on-Relations-with-Shareholders-and-Investors-NHL-(Approved).pdf"},
    { label: "Policy on Environmental, Social and Governance Sustainability - NHL", url: "../(h)-Policy-on-Environmental-Social-and-Governance-Sustainability-NHL(Approved).pdf"},
    { label: "Policy on Control and Management of Company Assets and Shareholder Investments - NHL", url: "../(i)-Policy-on-Control-and-Management-of-Company-Assets-and-Shareholder-Investments-NHL-(Approved).pdf"},
    { label: "Policy on Corporate Disclosures - NHL", url: "../(j)-Policy-on-Corporate-Disclosures-NHL-(Approved).pdf"},
    { label: "Policy on Whistleblowing - NHL", url: "../(k)-Policy-on-Whistleblowing-NHL-(Approved).pdf"},
    { label: "Anti-Bribery & Corruption Policy - NHL", url: "../(l)-Anti-Bribery-&-Corruption-Policy-NHL-(Approved).pdf"},
    // Add more links as needed
  ];

  const handleOpenInNewTab2 = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box>
        <Header/>
        <Box
        id="parallaxBackground"
        backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Annual Reports
        </Text>
      </Box>

<Box m={10}>
    <Grid templateColumns={{ base: '1fr', md: '1fr 3fr 1fr' }} gap={2}>
      <Box>
        <Card key='filled' variant='filled'>
          <CardBody>
          <Image src={require('../../img/icons/logo.png')} alt="LOGO" w="200px" h="200px"  mx="auto" display="block" />
          <Text textAlign={'center'} fontSize={'sm'} fontWeight={'semibold'}>
              Welcome to Nawaloka Hospitals, where transparency meets triumph! Explore a wealth 
              of information encapsulating our journey, milestones, and financial prowess. <Box as="br" />
              Immerse yourself in a narrative 
              that goes beyond numbers, illustrating our commitment to healthcare excellence and unwavering dedication.
              <Box as="br" /> 
              From fiscal highlights to strategic insights, this repository is your gateway to understanding how Nawaloka 
              continues to redefine healthcare standards. 
              <Box as="br" />
              Join us in celebrating our successes, acknowledging challenges, 
              and embracing a future where every report tells a story of resilience, innovation, and compassionate care.
            </Text>
          </CardBody>
        </Card>
      </Box>

      <Box>
      <Grid 
      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} 
      
      mt="30px" 
      mb="40px"
      >
      {pdfs.map(pdf => (
        <Flex key={pdf.id} direction="column" alignItems="center" mb={4}>
          <Box mb={2}>
            <Text fontWeight={'semibold'}>{pdf.title}</Text>
          </Box>
          <Image src={pdf.imageURL} alt="PDF Icon" mt={2} mb={4} w="160px" h="200px" shadow={'xl'}/>
          {/* <a href={pdf.url} download={pdf.title}>
              <Button colorScheme="blue">
                Download PDF
              </Button>
            </a> */}
            {/* <Button colorScheme="green" size={'sm'} onClick={() => handleOpenInNewTab(pdf.url)}>
              View More
            </Button>
            {pdf.title === 'Annual Report 2022-2023' && (
              <Stack direction='row' spacing={2} align='center'>
              <Button colorScheme="yellow" size={'sm'} onClick={() => handleOpenInNewTab(pdf.url)}>
              Notice
            </Button>
            <Button colorScheme="yellow" size={'sm'} onClick={() => handleOpenInNewTab(pdf.url)}>
              Notice
            </Button>
            </Stack>
            )} */}


          {pdf.title === 'Annual Report 2022-2023' || pdf.title === 'Annual Report 2023-2024' ? (
              <Stack direction='row' spacing={2} align='center'>
                <Button colorScheme="green" size={'xs'} onClick={() => handleOpenInNewTab(pdf.url)}>
                  View More
                </Button>
                {pdf.title === 'Annual Report 2022-2023' ? (
                  <Button colorScheme="yellow" size={'xs'} onClick={() => handleOpenInNewTab1('../Shareholder_Circular.pdf')}>
                    Shareholder Circular
                  </Button>
                ) : (
                  <Button colorScheme="yellow" size={'xs'} onClick={() => handleOpenInNewTab1('../Shareholder_Circular_23_24.pdf')}>
                    Shareholder Circular
                  </Button>
                )}
              </Stack>
            ) : (
              <Button colorScheme="green" size={'xs'} onClick={() => handleOpenInNewTab(pdf.url)}>
              View More
              </Button>
            )}
        </Flex>
      ))}
    </Grid>

      </Box>

      <Box>
      <Card key='filled' variant='filled'>
        <CardHeader>
          <Text fontSize={"2xl"} fontWeight={700} textAlign="center">Policies</Text>
        </CardHeader>
          <CardBody>
          {/* <Image src={require('../../img/icons/logo.png')} alt="LOGO" w="200px" h="200px"  mx="auto" display="block" /> */}
          <UnorderedList spacing={2}>
      {links.map((link, index) => (
        <ListItem key={index}>
          <Link
            href={link.url}
            isExternal
            color='blue.500'
            _hover={{ textDecoration: 'underline' }}
          >
            {link.label}
          </Link>
        </ListItem>
      ))}
    </UnorderedList>
          </CardBody>
        </Card>

      </Box>
    </Grid>
    </Box>
      

    {/* <DownloadPDF/> */}

      <FooterItem />
    </Box>
  )
}

export default Reports