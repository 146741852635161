import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, Flex, FormControl, Grid, GridItem, Heading, Input, Link, ListItem, Select, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AddIcon, MinusIcon, SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "./../store/actions";
import LatestItems2 from '../../components/LatestItems2';
import LatestItems3 from '../../components/LatestItems3';

function ServicePage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '13');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '13',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  // ---------------------------------------------------------------------------

  const viewDocProfile = (doc_id)=>{
    sessionStorage.setItem('doc_id',doc_id);
    navigate("/doctorprofile");
  }

  const videos = [
    {
      id: 5,
      title: 'හිසරදය දරුණු රෝග ලක්ශණයක් විය හැකිය.',
      url: 'https://www.youtube.com/watch?v=evK3zyYx1Ws',
    },

    // Add more video objects as needed
  ];

  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Neuro Care</Heading> */}
      <Box
        backgroundImage={require('../../img/neuro-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Neuro Care
        </Text>
      </Box>
      
      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" textAlign="center" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Box background="#1A908F" p={5} borderRadius="8px" width="100%">
        <Heading size="xs" color="white" mb="5px">Neurology</Heading>
        <FormControl width="100%">
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
          </Stack>
      </Box>
      <Flex>
        <Heading mt="20px" textAlign="center" ml="20px" size={'md'} mb="5px">Resident Neuro Surgeon</Heading>
      </Flex>
      <Box borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column"
      onClick={()=>viewDocProfile('D1866')} cursor={'pointer'}
      >
        <Avatar size="2xl" src={require('../../img/doctorImages/dr-punsith.jpg')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
        <VStack spacing={0} alignItems="center">
            <Text fontSize="xl" fontWeight="bold" color="white">Dr. Punsith Gunawardena</Text>
            <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
              MBBS, MS (Surgery ),{'\n'}Board certified Neurosurgeon, Medicine
            </Text>
        </VStack>
      </Box>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0111 234 590{'\n'}
            0111 234 567
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0776 557 711{'\n'}
            0779 686 135
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Box>
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box>
        <Text whiteSpace={"pre-line"} textAlign={'justify'}>
        Neuro Care Services is a comprehensive healthcare provider specializing in the diagnosis, treatment, 
        and management of neurological conditions and disorders. Our dedicated team of neurologists, 
        neurosurgeons, and other healthcare professionals are committed to delivering high-quality care 
        to patients with conditions affecting the brain, spinal cord, and nervous system.{"\n"}{"\n"}
        
        At Neuro Care Services, we offer a range of specialized services to address the unique needs of 
        individuals with neurological conditions.{"\n"}{"\n"}
        These services include:{"\n"}
        </Text>

        <Accordion allowMultiple mb={4}>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton _expanded={{ bg: 'red.100', color: 'Black' }}>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Diagnostic Evaluations
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize='12px' />
                    ) : (
                      <AddIcon fontSize='12px' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                Our state-of-the-art diagnostic capabilities allow 
                us to accurately assess and diagnose various neurological conditions. We utilize advanced imaging 
                technologies, such as MRI, CT scans, and EEG, to provide detailed insights into the patient's 
                condition.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton _expanded={{ bg: 'red.100', color: 'Black' }}>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Treatment Options
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize='12px' />
                    ) : (
                      <AddIcon fontSize='12px' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                We employ a multidisciplinary approach to treatment, 
                tailoring care plans to the specific needs of each patient. Our services encompass a wide range 
                of treatment modalities, including medication management, surgical interventions, neurorehabilitation 
                programs, pain management techniques, and non-invasive procedures.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton _expanded={{ bg: 'red.100', color: 'Black' }}>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Specialized Care for Neurological Conditions
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize='12px' />
                    ) : (
                      <AddIcon fontSize='12px' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                Our expertise extends to the 
                management of various neurological conditions, such as stroke, epilepsy, Parkinson's disease, 
                multiple sclerosis, Alzheimer's disease, brain tumors, spinal cord injuries, and peripheral nerve 
                disorders. We stay updated on the latest research and treatment advancements to provide the most 
                effective care options.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton _expanded={{ bg: 'red.100', color: 'Black' }}>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Rehabilitation Services
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize='12px' />
                    ) : (
                      <AddIcon fontSize='12px' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                We recognize the importance of neurorehabilitation in helping patients regain function, independence, 
                and quality of life. Our team of specialists offers comprehensive rehabilitation programs that may 
                include physical therapy, occupational therapy, speech therapy, and cognitive rehabilitation.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton _expanded={{ bg: 'red.100', color: 'Black' }}>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Patient-Centered Approach
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize='12px' />
                    ) : (
                      <AddIcon fontSize='12px' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                At Neuro Care Services, we prioritize the well-being and comfort of our patients. We provide 
                compassionate, patient-centered care, ensuring that individuals and their families are actively 
                involved in the decision-making process and receive the necessary support throughout their journey.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton _expanded={{ bg: 'red.100', color: 'Black' }}>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Research and Innovation
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize='12px' />
                    ) : (
                      <AddIcon fontSize='12px' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                We are committed to advancing the field of neurological care through ongoing research initiatives and 
                participation in clinical trials. By staying at the forefront of medical advancements, we strive to 
                offer patients access to innovative treatments and therapies.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

        </Accordion>

        <Text whiteSpace={"pre-line"} textAlign={'justify'}>
        At Neuro Care Services, our mission is to improve the lives of individuals with neurological 
        conditions by delivering personalized, comprehensive care. We understand the challenges these 
        conditions present, and we are dedicated to providing the highest level of expertise, support, 
        and treatment options to our patients. Contact us today to learn more or schedule an appointment 
        with our experienced team

        </Text>
        </Box>        

<Box mt={5}>
<Heading size="sm" mb={4}>Neuro Care Diagnostics+ Surgeries, Services & Computed.</Heading>
        <UnorderedList>
            <ListItem>Tomography (CT) Scan</ListItem>
            <ListItem>Electroencephalogram (EEG)</ListItem>
            <ListItem>Electromyogram (EMG)</ListItem>
            <ListItem>Magnetic Resonance Imaging (MRI) Scan</ListItem>
            <ListItem>Digital X-Ray</ListItem>
            <ListItem>Carotid Ultrasound Imaging</ListItem>
            <ListItem>Brain and Spinal Surgery</ListItem>
            <ListItem>Neurovascular Surgery</ListItem>
            <ListItem>Interventional Neuro Radiology</ListItem>
            <ListItem>Stroke Management</ListItem>
            <ListItem>Neuro Rehabilitation</ListItem>
        </UnorderedList>
</Box>

</Flex>
  <Box p={2} mt={'-10'}>
  <Heading size={'md'} mb={5}>Related Media</Heading>
  <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6} pr={6} pl={6}>
        {videos.map((video) => (
          <GridItem key={video.id}>
            <Box
              as="iframe"
              width="100%"
              height="250px"
              src={`https://www.youtube.com/embed/${video.url.split('=')[1]}`}
              title={video.title}
              allowFullScreen
              borderRadius="md"
              boxShadow="md"
            />
            <Heading as="h3" size="xs" mt={4}>
              {video.title}
            </Heading>
          </GridItem>
        ))}
      </Grid>
  </Box>
      </Grid>
      </Box>
      <Box>
          
<Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems3/>
      </Flex>
      </Box>
      </Grid>
      <FooterItem/>
    </div>
  )
}

export default ServicePage
