import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Header from "../components/Header";
import {
  ArrowForwardIcon,
  EmailIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import FooterItem from "../components/FooterItem";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { JsonToForm, JsonToParams } from "../utils";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import * as actions from "./store/actions";
import Preloader from '../components/Preloader';
import { FcDonate, FcPhone } from "react-icons/fc";
import { BiUpArrow, BiUpload } from "react-icons/bi";
import { FaEdit, FaEye } from "react-icons/fa";

export default function Access() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(null);
  const [spList, setSpList] = useState([]);
  const [doctorName, setDoctorName] = useState(null);
  const [spId, setSpId] = useState("*");
  const [isLoading, setIsLoading] = useState(false);

  //const doctorList = useSelector(doctorSelector);

  useEffect(() => {
    const body = {
      // key: "12345",
    };
    getSpList(Methods.POST, JsonToForm(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSpList = (method, body) => {
    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.specializations;
    fetch(URL, {
      method,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      body,
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          setSpList(object.specialityList);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  //--------------------------------------------------------------------------------------------------------------

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getDoctorList();
    }
  };

  const getDoctorList = () => {
    setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {
      sessionStorage.setItem('selectedDate', selectedDate);
      sessionStorage.setItem('spId', spId);
      sessionStorage.setItem('doctorName', doctorName);
      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          sp_id: spId,
          date: selectedDate,
          name: doctorName,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {
            // const payload = {
            //   data: {
            //     sp_id : spId,
            //     date : selectedDate,
            //     name : doctorName,
            //   },
            // };
            const payload = {
              data: object.doctort_list,
            };

            dispatch(actions.getDoctorList(payload));
            // Link
            navigate("/doctorsearch");
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);

    }
  };

  const physioForm = () => {
    navigate("/clinics/physiotherapy");
  }

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <Box>
          <Header />
          {/* <Heading mt={10} mb={10} ml={10}>
          Doctor Channeling
        </Heading> */}

          <Box
            backgroundImage={require('../img/pagecovers/channeling-strip.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
          // justifyContent="center"
          >
            <Flex flexDirection="column">
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              Online Orders
            </Text>
            {/* <Text
            fontSize="xl"
            fontWeight="semibold"
            ml="40px"
            color="white"
            width={{base: '100%', md: '80%', lg: '80%'}}
            // textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
                Description of the Function.
                
            </Text> */}
            </Flex>
          </Box>

        <Grid templateColumns={["1fr", "1fr", "1fr", "2fr 1fr 1fr"]}
            justifyItems="center"
            >
            <Box width="98%" mt="10px">
            <Flex
            background="#bee9e8"
            p={5}
            mb="30px"
            borderRadius="8px"
            alignItems="center"
            mx="auto"
            flexDirection="column"
            width="100%"
            height="293px"
            _hover={{
                boxShadow: 'xl',
                bg: '#aae3e2',
            }}
            style={{
              backgroundImage: 'url(https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZG9jdG9yc3xlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80)', // Replace with the URL of your desired background image
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              // opacity: 0.7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Heading size="md" color="white" mb="10px" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Doctor Channeling | Nawaloka Hospital - Colombo</Heading>
            <FormControl onKeyPress={handleKeyPress}>
              {/* <FormLabel>First name</FormLabel> */}
              <Input
                backgroundColor={"white"}
                mt="5px"
                placeholder="Doctor's Name"
                mb={2}
                onChange={(event) => setDoctorName(event.target.value)}
              />
              <Select
                backgroundColor={"white"}
                placeholder="Select Speciality"
                mt="5px"
                onChange={(event) => setSpId(event.target.value)}
                value={spId}
              >
                {/* <option>ENT</option> */}
                {spList.map((option) => (
                  <option key={option.sp_id} value={option.sp_id}>
                    {option.sp_name}
                  </option>
                ))}
                {/* <option>Cardiac</option> */}
              </Select>
              <Box>
                <Input
                  type="date"
                  value={selectedDate || ""}
                  onChange={handleDateChange}
                  px={3}
                  py={2}
                  borderRadius="md"
                  borderColor="gray.300"
                  background="white"
                  top="15px"
                  mb={65}
                  _hover={{ borderColor: "gray.400" }}
                  _focus={{
                    outline: "none",
                    borderColor: "blue.500",
                    boxShadow: "0 0 0 2px rgba(66, 153, 225, 0.6)",
                  }}
                />
                {/* {selectedDate && (
                  <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
                  top="50px"
                  left="30px">
                    Clear
                  </Button>
                )} */}
              </Box>
            </FormControl>

            <Stack>
              {/* <Link as={RouterLink} to="/doctorsearch"> */}
              <Button
                leftIcon={<SearchIcon />}
                bg="#62b6cb"
                color="black"
                variant="solid"
                // top="170px"
                // left="-190px"
                mt="-30px"
                _hover={{
                  bg: "#1b4965",
                  color: "white",
                }}
                _focus={{
                  bg: "#1b4965",
                }}
                onClick={() => getDoctorList()}
              >
                Find Your Doctor
              </Button>
              {/* </Link> */}
            </Stack>
          </Flex>
            
            </Box>

        <Box width="98%" mt="10px">

        <Box
            w="{'full'}"
            h={'293px'}
            bg="#b7efc5"
            rounded={'md'}
            overflow={'hidden'}
            _hover={{
                boxShadow: 'xl',
                bg: '#a4ebb6',
            }}
            style={{
              backgroundImage: 'url(https://media.istockphoto.com/id/1319363931/photo/asian-female-patient-receive-medication-package-box-free-first-aid-from-pharmacy-hospital.jpg?s=612x612&w=0&k=20&c=pXpTHQg_BknvIp0Tdxc29XEYwK8ocnhuMYFX7wt6db8=)', // Replace with the URL of your desired background image
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              // opacity: 0.7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
            

            <Box p={6} alignContent={'center'} color={'white'} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
            <Stack spacing={0} align={'center'} mb={5}>
                <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'} >
                Medicine Delivery
                </Heading>
                {/* <Text color={'gray.500'}>Frontend Developer</Text> */}
            </Stack>

            <Stack direction="row" align="center" mb="10px" justify="center" spacing={2} mt="auto">
                    <FcPhone size={24} style={{ color: 'white' }}/>
                    <Text fontSize="lg" fontWeight={600}>
                        0763 594 632
                    </Text>
            </Stack>
            <Stack align="center" mt="auto" justifyContent="center" alignItems="center">
                <Button
                    leftIcon={<BiUpload />}
                    bg="#4ad66d"
                    color="black"
                    variant="solid"
                    // top="170px"
                    // left="-190px"
                    _hover={{
                    bg: "#2dc653",
                    color: "white",
                    }}
                    _focus={{
                    bg: "#2dc653",
                    }}
                    onClick={() => {
                      window.location.href = 'https://labreport.lk/upload-prescription'; // Replace 'https://example.com' with the URL you want to redirect to
                    }}
                >
                    Upload your Prescription
                </Button>
            </Stack>

          </Box>
            
         </Box>
        </Box>

        <Box width="98%" mt="10px">
            <Box
            w="{'full'}"
            h={'293px'}
            bg="#edc4b3"
            rounded={'md'}
            overflow={'hidden'}
            _hover={{
                boxShadow: 'xl',
                bg: '#cfa493',
            }}
            style={{
              backgroundImage: 'url(https://e1.pxfuel.com/desktop-wallpaper/25/343/desktop-wallpaper-physiotherapy.jpg)', // Replace with the URL of your desired background image
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              // opacity: 0.7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >

            <Box p={6} alignContent={'center'} color={'white'} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
            <Stack spacing={0} align={'center'} mb={5}>
                <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                Physiotherapy
                </Heading>
                {/* <Text color={'gray.500'}>Frontend Developer</Text> */}
            </Stack>

            <Stack direction="row" align="center" mb="10px" justify="center" spacing={2} mt="auto">
                    <FcPhone size={24} />
                    <Text fontSize="lg" fontWeight={600}>
                        0763 594 632
                    </Text>
                    </Stack>
                    <Stack align="center" mt="auto">
                    <Button 
                    leftIcon={<BiUpload />}
                    bg="#cd9777"
                    color="black"
                    variant="solid"
                    // top="170px"
                    // left="-190px"
                    _hover={{
                    bg: "#c38e70",
                    color: "white",
                    }}
                    _focus={{
                    bg: "#c38e70",
                    }}
                    onClick={() => physioForm()}
                >
                    Channel a Session
                </Button>
            </Stack>

            </Box>
            </Box>
        </Box>
        </Grid>

        <Grid templateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
            justifyItems="center"
            >
        <Box width="98%" mt="10px">
        <Box
            w="{'full'}"
            bg="#eeeeee"
            h={'250px'}
            rounded={'md'}
            overflow={'hidden'}
            _hover={{
                boxShadow: 'xl',
                bg: '#dbd5d5',
            }}
            style={{
              backgroundImage: 'url(https://images.pexels.com/photos/256262/pexels-photo-256262.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500)', // Replace with the URL of your desired background image
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              // opacity: 0.7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
            {/* <Flex justify={'center'} mt={-12}>
            <Avatar
                size={'xl'}
                src={
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5_bZeqnZsjqj-jJ55kTgEri2uHfF28P6deA&usqp=CAU'
                }
                css={{
                border: '2px solid #4b4e6d',
                }}
            />
            </Flex> */}

            <Box p={6} alignContent={'center'} color={'white'} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
            <Stack spacing={0} align={'center'} mb={5}>
                <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                Lab Mobile
                </Heading>
                {/* <Text color={'gray.500'}>Frontend Developer</Text> */}
            </Stack>

            <Stack direction="row" align="center" mb="10px" justify="center" spacing={2} mt="auto">
                    <FcPhone size={24} />
                    <Text fontSize="lg" fontWeight={600}>
                    0761 634 118
                    </Text>
                    </Stack>
                    <Stack align="center" mt="auto">
                    <Button 
                    leftIcon={<BiUpload />}
                    bg="#cccccc"
                    color="black"
                    variant="solid"
                    // top="170px"
                    // left="-190px"
                    _hover={{
                    bg: "#bbbbbb",
                    color: "black",
                    }}
                    _focus={{
                    bg: "#bbbbbb",
                    }}
                    onClick={() => {
                      window.location.href = 'https://labreport.lk/upload-prescription'; // Replace 'https://example.com' with the URL you want to redirect to
                    }}
                >
                    Upload your Reports
                </Button>
            </Stack>

            </Box>
            </Box>
            
        </Box>

    <Box width="98%" mt="10px" mb="20px">
        <Box
            w="{'full'}"
            h={'250px'}
            bg="#fff0f3"
            rounded={'md'}
            overflow={'hidden'}
            _hover={{
                boxShadow: 'xl',
                bg: '#e3c8cd',
            }}
            style={{
              backgroundImage: 'url(https://thumbs.dreamstime.com/b/emergency-admission-doctor-movement-blur-hospital-motion-corridor-waiting-standing-139849788.jpg)', // Replace with the URL of your desired background image
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              // opacity: 0.7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
            

            <Box p={6} alignContent={'center'} color={'white'} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.9)' }}>
            <Stack spacing={0} align={'center'} mb={5}>
                <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                Admission Reservation
                </Heading>
                {/* <Text color={'gray.500'}>Frontend Developer</Text> */}
            </Stack>

            <Stack direction="row" align="center" mb="10px" justify="center" spacing={2} mt="auto">
                    {/* <FcPhone size={24} />
                    <Text fontSize="lg" fontWeight={600}>
                        0763 594 632
                    </Text> */}
            </Stack>
            <Stack align="center" mt="auto">
                <Button
                    leftIcon={<FaEdit />}
                    bg="#ff8fa3"
                    color="black"
                    variant="solid"
                    // top="170px"
                    // left="-190px"
                    _hover={{
                    bg: "#ff758f",
                    color: "black",
                    }}
                    _focus={{
                    bg: "#ff758f",
                    }}
                    onClick={() => {
                      window.location.href = '/admresev'; // Replace 'https://example.com' with the URL you want to redirect to
                    }}
                    // onClick={() => {
                    //   window.location.href = 'https://portal.labreport.lk/Patient/login'; // Replace 'https://example.com' with the URL you want to redirect to
                    // }}
                >
                    Reserve
                </Button>
            </Stack>

          </Box>

          {/* <Flex justify={'center'} mb={-12}>
            <Avatar
                size={'xl'}
                src={
                'https://png.pngtree.com/png-vector/20190826/ourlarge/pngtree-page-png-image_1697788.jpg'
                }
                css={{
                border: '2px solid #a4133c',
                }}
            />
          </Flex> */}
            
         </Box>
    </Box>

    {/* <Box width="98%" mt="10px">
            
        </Box> */}
        </Grid>


          
          <FooterItem />
        </Box>
      )}
    </div>
  );
}
