import { takeEvery } from "redux-saga/effects";
import * as types from "../store/types";
import {
  getSpecializationListSaga,
  getDoctorListSaga,
  getDoctorSessionsSaga,
} from "./Sagas";

export const Sagas = [
  takeEvery(types.GET_SPECIALIZATIONS, getSpecializationListSaga),
  takeEvery(types.GET_DOCTOR_LIST, getDoctorListSaga),
  takeEvery(types.GET_DOCTOR_SESSIONS, getDoctorSessionsSaga),
];
