import React, { ReactElement } from "react";
import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Divider, 
  Flex, Grid, GridItem, Heading, Icon, Image, SimpleGrid, Spacer, Stack, StackDivider, 
  Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import Header from '../components/Header'
import FooterItem from "../components/FooterItem";
import HeaderPic from "../components/HeaderPic";
import { FcAddressBook, FcAdvance, FcAssistant, FcCustomerSupport, FcDecision, FcDeleteRow, FcDepartment, FcDonate, FcHighPriority, FcInTransit, FcPhone } from 'react-icons/fc';
// import EmergencyStrip from '"../img/EmergencyStrip.png"'';
// import EntStrip from '../img/EntStrip.png';

export default function Emergency() {

  interface FeatureProps {
    title: string;
    text: string;
    phoneNumber: string;
    icon: ReactElement;
  }
  
  const Feature = ({ title, text, icon, phoneNumber }: FeatureProps) => {
    const handleCall = () => {
      window.location.href = `tel:${phoneNumber}`;
    };

    return (
      <Stack>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.100'}
          mb={1}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{title}</Text>
        <Text color={'gray.600'}>{text}</Text>
        <Stack direction="row" align="center" spacing={2} mt="auto">
        <a href={`tel:${phoneNumber}`} onClick={handleCall}>
        <Flex alignItems="center">
          <FcPhone size={24} />
          
          <Text fontSize="lg" fontWeight={600} ml={2}>
            {phoneNumber}
          </Text>
        </Flex>
        </a>
        </Stack>
        </Stack>
    );
  };

    return(
        <div>
            <Box>
            <Header/>
            {/* <Heading mt={10} ml={10}>Emergency Care</Heading> */}
            <Box
              style={{
                backgroundImage: `url(${useBreakpointValue({ base: require('../img/EmergencyStrip.png'), lg: require('../img/EmergencyStrip.png') })})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
              // backgroundImage={require('../img/EmergencyStrip.png')}
              // backgroundSize="cover"
              // backgroundPosition="center"
              height="200px"
              display="flex"
              mt="10px"
              alignItems="center"
        // justifyContent="center"
            >
              <Text
                fontSize="4xl"
                fontWeight="bold"
                ml="40px"
                color="white"
                textShadow="0 2px 4px rgba(0,0,0,0.5)"
              >
                Emergency Care
              </Text>
            </Box>
            {/* <HeaderPic /> */}

            {/* <Box box-sizing= {'border-box'}
            background= {'rgba(217, 217, 217, 0.43)'}
            border= {'1px solid #000000'}
            border-radius= {'10px'}
            width= {'517px'}
            height= {'261px'}
            left= {'95px'}
            top= {'569px'}
            >
                <Heading 
                font-family= {'Myriad Pro'}
                font-style= {'normal'}
                font-weight= {600}
                font-size= {'32px'}
                line-height= {'38px'}
                >ETU
                </Heading>
            </Box> */}
        {/* <SimpleGrid spacing={4}  templateColumns='repeat(3, 1fr)'>
  <Card>
    <CardHeader>
      <Heading size='md'> ETU</Heading>
    </CardHeader>
    <CardBody>
      <Text>The emergency Treatment Unit of Nawaloka Hospital provides effective and efficient 
        care in emergency situations 24/7. You’ll be safe in the hands of experienced consultants 
        and trained medical staff.</Text>
    </CardBody>
    <CardFooter>
      <Text>0115 577 208</Text>
      <Spacer/>
      <Text> 0115 577 222</Text>
    </CardFooter>
  </Card>
  <Card>
    <CardHeader>
      <Heading size='md'> Ambulance</Heading>
    </CardHeader>
    <CardBody>
      <Text>24/7 service including mobile ICU, Home nursing visits with professional, 
        Patient transfers, and Event coverage</Text>
    </CardBody>
    <CardFooter>
    <Text>0779 511 929</Text>
    </CardFooter>
  </Card>
  <Card>
    <CardHeader>
      <Heading size='md'> Hotline</Heading>
    </CardHeader>
    <CardBody>
      <Text>24/7 online service. Works with patients who have complaints, appointments, 
        or require information about hospital services</Text>
    </CardBody>
    <CardFooter>
    <Text>011 5 777 777</Text>
    </CardFooter>
  </Card>
</SimpleGrid> */}

<Box p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="55px" ml="20px" mr="20px">
        <Feature
          icon={<Icon as={FcDepartment} w={10} h={10} />}
          title={'Emergency Treatment Unit'}
          text={
            'The emergency Treatment Unit of Nawaloka Hospital provides effective and efficient care in emergency situations 24/7. You’ll be safe in the hands of experienced consultants and trained medical staff.'
          }
          phoneNumber={'0115 577 208 / 0115 577 222'}
        />
        <Feature
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={'Ambulance'}
          text={
            '24/7 service including mobile ICU, Home nursing visits with professional, Patient transfers, and Event coverage'
          }
          phoneNumber={'0779 511 929'}
        />
        <Feature
          icon={<Icon as={FcCustomerSupport} w={10} h={10} />}
          title={'Hotline'}
          text={
            '24/7 online service. Works with patients who have complaints, appointments, or require information about hospital services'
          }
          phoneNumber={'0115 777 777'}
        />
      </SimpleGrid>
    </Box>

            <FooterItem/>
            </Box>
        </div>
    );
}