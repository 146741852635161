import { Box, Card, Flex, Grid, Heading, Image, Spacer, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { useEffect, useRef } from "react";

function Awards() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  return (
    <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../../img/pagecovers/awards-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Awards & Recognitions
        </Text>
      </Box>

      {/* <Box m={5}>
        <Card p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.2)">
          <Flex flexDirection={['column','row']}>
          <Grid templateColumns={['1fr','2fr','1fr 2fr']}columnGap={4}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/jci-gold-seal.png')} w={'200px'}
            h={'200px'} alt="test"/>
        </Box>

        <Box p={5}>
        <Heading size={'md'} mb={2}>JCI ACCREDITATION</Heading>
      <Text textAlign={'justify'}>
            Nawaloka Hospital achieved the Joint Commission International’s (JCI) gold seal approval for 
            hospital accreditation on 18th of January 2020. The accreditation comes in with the hospital’s 
            continued compliance with internationally-recognized standards and represents a symbol of the 
            highest quality. It also reflects the organization's commitment to providing safe and effective 
            patient care.
      </Text>
      </Box>
      </Grid>
            </Flex>
        </Card>
      </Box> */}


      <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "1fr 1fr"]}
          gap={6}
          p={4}
          mt={2}
          mx="auto">
            
          <Box>
          <Card p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.2)">
            <Box position="relative" width="100%" height="auto" p={4}>
              <Heading size={'md'} mb={2}>ISO 9001:2015</Heading>
              <Text textAlign={'justify'}>
              ISO 9001:2015 Nawaloka Hospitals PLC has established a quality management system in accordance 
              with its ISO 9001: 2015 international standard. The quality management system implemented at 
              Nawaloka Hospitals PLC encompasses all functional areas and follows naturally and sequentially 
              from the customer related functions through the operational to the delivery or discharge function 
              so as to meet the specified requirements of customers. Nawaloka Hospitals PLC is the first ISO 
              certified Private Hospital in Sri Lanka (Year 2000) and has retained the standard for the past 
              Twenty years up to present.
              </Text>
            </Box>
          </Card>
          </Box>


          <Box>
          <Card p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.2)">
            <Box position="relative" width="100%" height="auto" p={4}>
              <Heading size={'md'} mb={2}>5 CROWN AWARD FOR FOOD HYGIENE</Heading>
              <Text textAlign={'justify'}>
              Leading healthcare provider Nawaloka Hospitals PLC received praise for the quality and hygiene 
              of its food when presented with the prestigious 5 Crown Award for Food Hygiene by Ind-Expo 
              Certification Ltd. for the fifth consecutive year. The hospital chain clinched the first of 
              these awards in 2012 on its very first attempt and has gone on to further strengthen its food 
              storage and preparation practices each subsequent year.
              </Text>
            </Box>
          </Card>
          </Box>

          <Box>
          <Card p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.2)">
          <Box position="relative" width="100%" height="auto" p={4}>
            <Heading size={'md'} mb={2}>NATIONAL QUALITY AWARD</Heading>
            <Text textAlign={'justify'}>
            The Sri Lanka National Quality Award is presented annually to recognize Sri Lankan Organizations 
            that excel in quality management and quality achievement. The Quality award program is organized 
            and implemented by the Marketing and Promotion division of Sri Lanka Standards Institution (SLSI). 
            Nawaloka Hospitals PLC was the only Hospital to win the Sri Lankan National Quality Award in the 
            year 1998.
            </Text>
          </Box>
          </Card>
          </Box>

          <Box>
          <Card p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.2)">
            <Box position="relative" width="100%" height="auto" p={4}>
              <Heading size={'md'} mb={2}>NATIONAL BUSINESS
                EXCELLENCE AWARD
                </Heading>
              <Text textAlign={'justify'}>
              This is awarded by the National Chamber of Commerce (NCCSL) which recognizes business enterprises 
              that have demonstrated excellence in business, whilst contributing to the economic progress of 
              the country. ISO 15189:2012 adhering to National and International Standards applicable to 
              Clinical Testing Laboratory and Good Laboratory Practices (GLP). Nawaloka Green Cross 
              Laboratories (Pvt) Limited. Will, at all times, works towards a common goal of providing cost 
              effective total customer satisfaction by way of efficient and effective pathology testing services 
              that combine precision, accuracy, quality, safety, integrity and courtesy.
              </Text>
            </Box>
          </Card>
          </Box>

        </Grid>
        <FooterItem/>
        </Box>
    </div>
  )
}

export default Awards
