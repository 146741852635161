import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Button,
  Flex,
  Card,
  Heading,
  Grid,
  Text,
  useColorModeValue,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import surgeryIcon1 from "../../img/healthcheck1.jpeg";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { isEmpty, isSet } from "lodash";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
// import surgeryIcon1 from "../../img/healthcheck1.jpeg";

const productData = {
  "product-1": {
    id: 1,
    name: "Under 40 years",
    image: surgeryIcon1,
    price: "$19.99",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac aliquet ligula.",
  },
  "product-2": {
    id: 2,
    name: "Product 2",
    image: surgeryIcon1,
    price: "$24.99",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac aliquet ligula.",
  },
  // Add more product details as needed
};

function PackageDetails() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { productId } = useParams();
  const product = productData["product-1"];

  const [name, setName] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState(null);

  //const { name, image_url, price, description } = details;

  const navigate = useNavigate();

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    console.log(searchParams.get("selected_package_id"));
    try {
      const method = Methods.POST;
      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.viewPackage;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          //session_id: sessionStorage.getItem("sessionId"),
          package_id: searchParams.get("selected_package_id"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            console.log(object.data);
            setName(object.data.name);
            setImageURL(object.data.image_url);
            setPrice(object.data.price);
            setDescription(object.data.description);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const addToCart = () => {
    //const sessionId = generateUniqueId();

    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.addCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          package_id: searchParams.get("selected_package_id"),
          qty: 1,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            navigate("/healthchecks/cart");
            //window.location.reload();
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };
  const isValidImageURL = imageURL && imageURL.trim() !== "";
  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/DoorstepStripNew.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Package Details
        </Text>
      </Box>
      <Grid templateColumns={["1fr", "1fr", "3fr 1fr"]} gap={4}>
        <Box>
          <Card m={5} p={2} boxShadow={"outline"}>
            <Flex>
              <Box flex="1" p={4}>
                {searchParams
                  .get("type") == "MEN" ? (
                  <Image
                    src={isValidImageURL ? imageURL : surgeryIcon2}
                    alt={name}
                  />
                ) : (
                  <Image
                    src={isValidImageURL ? imageURL : surgeryIcon1}
                    alt={name}
                  />
                )}              </Box>

              <Box flex="2" p={5}>
                <Box fontWeight="bold" mb={2}>
                  <Heading size={"md"}>{name}</Heading>
                </Box>

                <Box mb={5}>
                  <Heading size={"sm"} color={"blackAlpha.700"}>
                    Rs: {price}/=
                  </Heading>
                </Box>

                <Box mb={4}>
                  <Heading
                    color={useColorModeValue("yellow.500", "yellow.300")}
                    size={"sm"}
                    mb={2}
                  >
                    Description
                  </Heading>
                  <Box ml={5}>
                    <Text mb={2} whiteSpace={"pre-line"}>{description}</Text>
                    {/* <Text mb={2}>
                      <Heading size={"sm"} fontWeight={"semibold"}>
                        Lipid Profile
                      </Heading>
                      S Cholesterol, HDL Cholesterol, LDL Cholesterol,
                      Triglycerides, Ratio of Cholesterol, Ratio of HDL, Total
                      proteins, SGPT, SGOT, S Alkaline phosphatase, S
                      Creatinine, TSH, UFR,
                    </Text>

                    <Text mb={2}>
                      <Heading size={"sm"} fontWeight={"semibold"}>
                        Other
                      </Heading>
                      ECG, Chest X-Ray with reporting, US Scan for abdomen,
                    </Text>

                    <Text mb={2}>
                      <Heading size={"sm"} fontWeight={"semibold"}>
                        Consultation
                      </Heading>
                      Consultation with physician
                    </Text>  */}
                  </Box>
                </Box>

                <Button
                  backgroundColor="#1A908F"
                  onClick={() => addToCart()}
                  color="white"
                  size="sm"
                  _hover={{
                    bg: "#004242",
                  }}
                  _focus={{
                    bg: "#004242",
                  }}
                >
                  Add to Cart
                </Button>
              </Box>
            </Flex>
          </Card>
        </Box>

        <Box flex="1" pr={4}>
          <Heading as="h3" size="md" mt={10} mb={4}>
            Other Packages
          </Heading>
          <UnorderedList>
            <ListItem
              key={1}
              borderWidth="1px"
              borderRadius="md"
              padding={2}
              borderColor="gray.300"
              _hover={{ borderColor: "blue.500" }}
              mb={2}
            >
              Premier Under 40 Men
            </ListItem>
            <ListItem
              key={1}
              borderWidth="1px"
              borderRadius="md"
              padding={2}
              borderColor="gray.300"
              _hover={{ borderColor: "blue.500" }}
              mb={2}
            >
              Standard Over 40
            </ListItem>
            <ListItem
              key={1}
              borderWidth="1px"
              borderRadius="md"
              padding={2}
              borderColor="gray.300"
              _hover={{ borderColor: "blue.500" }}
              mb={2}
            >
              Canser Screening Over 40
            </ListItem>
            <ListItem
              key={1}
              borderWidth="1px"
              borderRadius="md"
              padding={2}
              borderColor="gray.300"
              _hover={{ borderColor: "blue.500" }}
              mb={2}
            >
              Senior Citizens Over 50
            </ListItem>
          </UnorderedList>
        </Box>
      </Grid>

      <FooterItem />
    </Box>
  );
}

export default PackageDetails;
