import { Badge, Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";

function BookingCard({
  sessionDate,
  sessionTime,
  activePatientCount,
  remark,
  onPress,
}) {
  return (
    <div>
      <Flex
        width="100%"
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        bg="white"
        boxShadow="md"
        alignItems="center"
        gap={4}
      >
        <Flex flexDirection="column">
          <Heading size="sm" mb={1}>
            {sessionDate}
          </Heading>
          <Text fontSize="xs" mb={1}>
            {sessionTime}
          </Text>
        </Flex>
        <Flex gap={2}>
          <Badge
            colorScheme="green"
            borderRadius="10px"
            fontSize="0.9rem"
            m="1px"
          >
            {activePatientCount}
          </Badge>
          {remark == "AVAILABLE" ? (
            <Button
              colorScheme="yellow"
              size="xs"
              borderRadius="8px"
              onClick={onPress}
            >
              Book Now
            </Button>
          ) : (
            <Button
              colorScheme="red"
              size="xs"
              borderRadius="8px"
              disabled
            >
              {remark}
            </Button>
          )}
        </Flex>
      </Flex>
    </div>
  );
}

BookingCard.propTypes = {
  sessionDate: PropTypes.any,
  sessionTime: PropTypes.any,
  activePatientCount: PropTypes.any,
  remark: PropTypes.any,
  onPress: PropTypes.func,
};
export default BookingCard;
