import React from 'react';
import { Box, Button, Container, Heading, IconButton, Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';
import { Link as RouterLink } from "react-router-dom";

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Carousel() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState<Slider | null>(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '10px' });
  const left = useBreakpointValue({ base: '60%', md: '50%', lg: '5%' });

  // These are the images used in the slide
  const cards = [
    // {
    //   url: require('../img/sliderImgs/website-slider-anniversary.png'),
    //   // title: 'Experience the best Healthcare',
    //   // text: "Book Your Appoinment through Online",
    //   linkURL: ''
    // },
    // {
    //   url: require('../img/sliderImgs/website-slider-discount-2.png'),
    //   title: 'Enjoy Our Anniversary Discounts',
    //   text: "38 Discounts for 38th Anniversary",
    //   linkURL: '/discounts/anniversary-discounts'
    // },

    // {
    //   url: require('../img/sliderImgs/vesak-web-banner.png'),
    //   title: '',
    //   text: "",
    //   linkURL: ''
    // },
    {
      url: require('../img/sliderImgs/kt500_web_cover.jpeg'),
      title: '',
      text: "",
      linkURL: ''
    },
    {
      url: require('../img/sliderImgs/web-slider-3.jpg'),
      title: 'Experience the best Healthcare',
      text: "Book Your Appoinment through Online",
      linkURL: ''
    },
    {
      url: require('../img/sliderImgs/web-slider-2.jpg'),
      title: 'Our People',
      text: "We will look after you like Home",
      linkURL: ''
    },
    {
      url: require('../img/sliderImgs/web-slider-4.jpg'),
      title: 'Technology & Research',
      text: "Empowering Health through Innovations",
      linkURL: ''
    },
  ];

  const goToLink = (linkURL) => {
    window.open(linkURL, '_blank');
  };

  // const cardWords = [
  //   {
  //     title: 'Experience the best Healthcare',
  //     title2: 'with',
  //     title3: 'Feeling',
  //     text: "Book Your Appoinment through Online",
  //     image:
  //     require('../img/sliderImgs/slider-1.png'),
  //   },
  //   {
  //     title: 'Our People',
  //     title2: 'is',
  //     title3: 'Wealth',
  //     text: "We will look after you like Home",
  //     image:
  //     require('../img/sliderImgs/slider-2.png'),
  //   },
  //   {
  //     title: 'Innovation, Technology & Research',
  //     title2: 'with',
  //     title3: 'Love',
  //     text: "Empowering Health through Innovations",
  //     image:
  //     require('../img/sliderImgs/slider-3.png'),
  //   },
  // ];

  return (
    <Box>
    <Box
      position={'relative'}
      height={'650px'}
      width={'full'}
      overflow={'hidden'}
      marginTop="-80px"
      >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        bg="whiteAlpha.300"
        _hover={{
          bg: "whiteAlpha.500"
        }}
        aria-label="left-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}>
        <BiLeftArrowAlt />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        bg="whiteAlpha.300"
        _hover={{
          bg: "whiteAlpha.500"
        }}
        aria-label="right-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}>
        <BiRightArrowAlt />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
            height={'650px'}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${card.url})`}
          >
            <Container size="container.lg" height="400px" position="relative">
              <Stack
                spacing={6}
                w={'full'}
                maxW={'lg'}
                position="absolute"
                top="80%"
                // right={{ base: '50%', md: '50%', lg: '70%' }}
                left={left}
                transform="translate(-50%, -50%)">
                <Box>
                <Heading fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }} color={'whiteAlpha.900'} 
                fontFamily={'revert'} mb={'-5'} textShadow="0px 2px 4px rgba(0, 0, 0, 0.8)" >
                  {card.title}
                </Heading>
                </Box>
                {/* <Box>
                <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} color={'blackAlpha.800'} 
                fontFamily={'revert'} mb={'-8'} ml="60px">
                  {card.title2}
                </Heading>
                </Box>
                <Box>
                <Heading fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }} color={'blackAlpha.800'} 
                fontFamily={'revert'} mb={'-8'} ml={'20'}>
                  {card.title3}
                </Heading>
                </Box> */}
                <Box>
                <Text fontSize={{ base: 'lg', lg: '2xl' }} color="whiteAlpha.800" textShadow="0px 2px 4px rgba(0, 0, 0, 0.7)">
                  {card.text}
                </Text>
                </Box>
                <Box>
                {card.linkURL !== '' ? (
                  <Link as={RouterLink} to="/discounts/anniversary-discounts">
                  <Button
                    // onClick={() => goToLink(card.linkURL)}
                    width="30%"
                    bg={'blue.400'}
                    rounded={'full'}
                    color={'white'}
                    _hover={{ bg: 'blue.500' }}
                  >
                    See More
                  </Button>
                  </Link>
                ) : ''}
                </Box>
              </Stack>
            </Container>

          </Box>
        ))}
      </Slider>
    </Box>
    <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="10vh"
        marginTop={{ base: "80px", lg: "50px"}}
        mb={{base: "150px", lg: "120px"}}
        >
          <Heading color={"yellow.600"} textAlign="center" fontSize={28} mt={10} marginBottom={0} fontWeight={"bold"}>Welcome to Nawaloka Hospitals</Heading>
          <Text color={"gray.600"} fontSize={22} fontWeight={"bold"}>"Immersed in Excellence"</Text>
          <Text as="p" mb="10px" textAlign={"center"} p={2} ml={{ base: "10px", lg: "300px"}} mr={{ base: "10px", lg: "300px"}} >Nawaloka Hospital Colombo - Where 400 beds and 
          600 medical consultants unite, creating the epitome of premier healthcare in a single, 
          extraordinary location
          </Text>
          <Link as={RouterLink} to="/aboutus">
            <Button colorScheme="yellow" fontSize="xl" px={8} borderRadius={20}>
              About us
            </Button>
          </Link>
        </Box>
    </Box>
  );
}