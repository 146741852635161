/* eslint-disable import/no-anonymous-default-export */
export default {
  PRE_PROD: {
   // BASE_URL: "https://test.nawalokacare.com/nawalokacare/api/",
    BASE_URL: "https://nawaloka.com/nawalokacare/api/",
    ECHANNELLING: {
      //BASE_URL: "https://test.nawalokacare.com/nawaloka-care/care_backend/",
      BASE_URL: "https://nawaloka.com/nawaloka-care/care_backend/",
      BASE_URL_SANDBOX: "http://203.115.26.38:8080/api/",
      API_KEY: {
        PRODUCTION :"53A1891ABDB7708628F518FF5794D5021AD12C1FDD36AD575F5A990C81AC8D2B",
        SANDBOX :"bf27c2104bd2a100bfe3efd0efcd8779215a26c17c37172bb074e0c78486c043",
      },
      VERSION: "v1/",
      SCOPE: "SANDBOX",
      HEADER_AUTHORIZATION_CODE: {
        PRODUCTION:
          "53A1891ABDB7708628F518FF5794D5021AD12C1FDD36AD575F5A990C81AC8D2B",
        SANDBOX:
          "bf27c2104bd2a100bfe3efd0efcd8779215a26c17c37172bb074e0c78486c043",
      },
      HEADER_ACCESS: "Gtyd32@2dswS210",
    },
    NAWALOKA_HIS: {
      SERVICE: "http://203.143.31.74:6192/CareAPI/Care/services/",
    },
  },
  PROD: {
    BASE_URL: "",
    DOC990_ENV: {
      BASE_URL: undefined,
      VERSION: "",
      SCOPE: "PRODUCTION",
      HEADER_AUTHORIZATION_CODE: undefined,
      HEADER_ACCESS: undefined,
    },
  },
  DEV_LOGS: false,
};
