import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function DoctorCard({ docName, spName, docImage, onPress }) {
  return (
    <>
      <Link to="">
        <Box
          borderWidth="1px"
          boxShadow="md"
          borderRadius="lg"
          p={4}
          display="flex"
          alignItems="center"
          bg={"gray.200"}
          onClick={onPress}
        >
          <Avatar
            size="md"
            src={
              docImage != null
                ? docImage
                : require("../img/default-doctor.jpeg")
            }
            borderWidth="2px"
            borderColor={"blackAlpha.600"}
          />
          <VStack ml={4} spacing={0} alignItems="flex-start">
            <Text fontSize="sm" fontWeight="bold">
              {docName}
            </Text>
            <Text fontSize="xs" color="gray.700">
              {spName}
            </Text>
          </VStack>
        </Box>
      </Link>
    </>
  );
}

DoctorCard.propTypes = {
  docName: PropTypes.any,
  spName: PropTypes.any,
  onPress: PropTypes.func,
  docImage: PropTypes.any,
};

export default DoctorCard;
