import { Box, Card, CardBody, CardHeader, Grid, Heading, Image, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Tag, TagLabel, TagLeftIcon, Text, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
// import ImageGallery from '../../components/ImageGallery';
import { Carousel } from 'react-responsive-carousel';
import { AddIcon } from '@chakra-ui/icons';
import { MdAcUnit, MdFreeBreakfast, MdHelp, MdHotTub, MdKitchen, MdLaunch, MdPanTool, MdPhone, MdRoom, MdTv } from 'react-icons/md';
import { FaBath, FaBed, FaCoffee, FaCouch, FaHospital, FaWifi } from 'react-icons/fa';
import { BiArea, BiBed } from 'react-icons/bi';

const ImageGallery = ({ images }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    onOpen();
  };

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      {images.map((image, index) => (
        <Box key={index} onClick={() => handleImageClick(image)}>
          <Image src={image} alt={`Image ${index}`} cursor="pointer" />
        </Box>
      ))}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && (
              <Carousel width="800px">
                {images.map((imageUrl, index) => (
                  <Box key={index} w="800px" h="550px">
                    <img src={imageUrl} alt={`Image ${index}`} />
                  </Box>
                ))}
              </Carousel>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

function Room01Page() {  

  const superPremium = [
    require('../../img/rooms/superPremium/IMG_7112.jpeg'),
    require('../../img/rooms/superPremium/IMG_7107.jpeg'),
    require('../../img/rooms/superPremium/IMG_7102.jpeg'),
    // 'https://drive.google.com/uc?id=1JvAWX-R-kMDGzALBY-skdug70WtuC8OF',
    // 'https://drive.google.com/uc?id=1BWqGGs8vwFx3lZlDdG4mwM9myq-kAfhG',
    // 'https://drive.google.com/uc?id=1_pC3fGcaqnTDDONvXqywEoJ5AcW17T-K',
    // 'https://drive.google.com/uc?id=1DDG1tBD4szjgK29eQHNHypBzHiz0gZuV',
    
    // Add more image URLs as needed
  ];

  const vipPremium = [
    require('../../img/rooms/vipPremium/IMG_7153.jpeg'),
    require('../../img/rooms/vipPremium/IMG_7155.jpeg'),
    require('../../img/rooms/vipPremium/IMG_7150.jpeg'),
    require('../../img/rooms/vipPremium/IMG_7162.jpeg'),
    // 'https://drive.google.com/uc?id=1X-_cgL6QHQECvNv2dAXxc1jrazOI_zXo',
    // 'https://drive.google.com/uc?id=1wbBg44NRGPyLtNs30GLVlY_y5WolPVN_',
    
    // Add more image URLs as needed
  ];

  const premium = [
    require('../../img/rooms/premium/img07.jpg'),
    require('../../img/rooms/premium/img10.jpg'),
    require('../../img/rooms/premium/img11.jpg'),
    require('../../img/rooms/premium/img13.jpg'),

    require('../../img/rooms/premium/img15.jpg'),
    require('../../img/rooms/premium/img16.jpg'),
    require('../../img/rooms/premium/img18.jpg'),
   
    // 'https://drive.google.com/uc?id=1wBUNzfTkgpbclxu9t5u2ryPmCPUc8V2C',
    // 'https://drive.google.com/uc?id=18tUzsuWE-SDGmX6yv_-LUIYgQEESLu3g',
    
    // Add more image URLs as needed
  ];
  
  const deluxSuperior = [
    require('../../img/rooms/deluxSuperior/IMG_7099.jpeg'),
    require('../../img/rooms/deluxSuperior/IMG_7097.jpeg'),
    require('../../img/rooms/deluxSuperior/IMG_7090.jpeg'),
    // 'https://drive.google.com/uc?id=13WQKk1lPXwEIG1enFRnCuKUQKWrooopV',
    
  ];

  const executive = [
    require('../../img/rooms/executive/IMG_7166.jpeg'),
    require('../../img/rooms/executive/IMG_7167.jpeg'),
    require('../../img/rooms/executive/IMG_7168.jpeg'),
    // 'https://drive.google.com/uc?id=1j9TwF4eU9Aof-2KTwhcrqn2VpxrmcvhA',
    
    // Add more image URLs as needed
  ];

  const delux = [
    require('../../img/rooms/delux/IMG_7172.jpeg'),
    require('../../img/rooms/delux/IMG_7173.jpeg'),
    require('../../img/rooms/delux/IMG_7175.jpeg'),
    // 'https://drive.google.com/uc?id=1gpQ4Pjo0JlwE4gpepS-SRfjdBdOVbITO',
    
    // Add more image URLs as needed
  ];

  const imagesPremium = [
    require('../../img/rooms/other/IMG_01.jpeg'),
    require('../../img/rooms/other/IMG_02.jpeg'),
    require('../../img/rooms/other/IMG_03.jpeg'),
    
    // Add more image URLs as needed
  ];

  const wardbeds = [
    require('../../img/rooms/wardBeds/img1180_2.jpg'),
    require('../../img/rooms/wardBeds/img1180_1.jpg'),
    require('../../img/rooms/wardBeds/img1180_3.jpg'),
    // 'https://drive.google.com/uc?id=1ukLdNEUPQ9dfcAdQU4PKyf0BEkLBzv7v',
    // 'https://drive.google.com/uc?id=1gMonjdKzor6lzHZNAQJgnLLyqh-wEiWi',
    
    // Add more image URLs as needed
  ];

  const daycare = [
    require('../../img/rooms/wardBeds/img1180_3.jpg'),
    require('../../img/rooms/wardBeds/img1180_1.jpg'),
    require('../../img/rooms/wardBeds/img1180_2.jpg'),
    // 'https://drive.google.com/uc?id=1gMonjdKzor6lzHZNAQJgnLLyqh-wEiWi',
    // 'https://drive.google.com/uc?id=1ukLdNEUPQ9dfcAdQU4PKyf0BEkLBzv7v',
    
    // Add more image URLs as needed
  ];


  const { roomNumber } = useParams();

    if (roomNumber == "01") {
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              SUPER PREMIUM
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {superPremium.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              {/* <CardHeader fontSize={'lg'}></CardHeader> */}
              <CardBody>
              <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdAcUnit} />
                  <TagLabel>Air conditioning</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdTv} />
                  <TagLabel>TV</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdPhone} />
                  <TagLabel>Telephone</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdHotTub} />
                  <TagLabel>Hot water</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBed} />
                  <TagLabel>Side bed</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaCouch} />
                  <TagLabel>Sofa</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaCoffee} />
                  <TagLabel>Coffee Table</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaWifi} />
                  <TagLabel>Free WIFI</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={BiArea} />
                  <TagLabel>Visitng Area</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdKitchen} />
                  <TagLabel>Pantry</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBath} />
                  <TagLabel>Two Washrooms</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdLaunch} />
                  <TagLabel>Dinning Area</TagLabel>
                </Tag><Box as="br" />
                {/* <List listStyleType="disc" ml={5}>
                  <ListItem>A/C</ListItem>
                  <ListItem>TV</ListItem>
                  <ListItem>Telephone</ListItem>
                  <ListItem>Hot Water</ListItem>
                  <ListItem>Side Bed</ListItem>
                  <ListItem>Sofa</ListItem>
                  <ListItem>Coffee Table</ListItem>
                  <ListItem>Free WIFI</ListItem>
                  <ListItem>Visitng Area</ListItem>
                  <ListItem>Pantry</ListItem>
                  <ListItem>Two Washrooms</ListItem>
                  <ListItem>Dinning Area</ListItem>
                </List> */}
              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
    } else if (roomNumber == '02'){
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              VIP PREMIUM
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {vipPremium.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              {/* <CardHeader fontSize={'lg'}></CardHeader> */}
              <CardBody>
                <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdAcUnit} />
                  <TagLabel>Air conditioning</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdTv} />
                  <TagLabel>TV</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdPhone} />
                  <TagLabel>Telephone</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdHotTub} />
                  <TagLabel>Hot water</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBed} />
                  <TagLabel>Side bed</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaCouch} />
                  <TagLabel>Sofa</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaCoffee} />
                  <TagLabel>Coffee Table</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaWifi} />
                  <TagLabel>Free WIFI</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={BiArea} />
                  <TagLabel>Visitng Area</TagLabel>
                </Tag><Box as="br" />

              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
    } else if(roomNumber == '03') {
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              PREMIUM
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {premium.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              <CardBody>
              <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdAcUnit} />
                  <TagLabel>Air conditioning</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdTv} />
                  <TagLabel>TV</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdPhone} />
                  <TagLabel>Telephone</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdHotTub} />
                  <TagLabel>Hot water</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBed} />
                  <TagLabel>Side bed</TagLabel>
                </Tag><Box as="br" />
              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
    } else if(roomNumber == '04'){
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              DELUX SUPERIOR
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {deluxSuperior.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              <CardBody>
              <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdAcUnit} />
                  <TagLabel>Air conditioning</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdTv} />
                  <TagLabel>TV</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdPhone} />
                  <TagLabel>Telephone</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdHotTub} />
                  <TagLabel>Hot water</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBed} />
                  <TagLabel>Side bed</TagLabel>
                </Tag><Box as="br" />
              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
    }else if(roomNumber == '05'){
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              EXECUTIVE
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {executive.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              <CardBody>
              <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdAcUnit} />
                  <TagLabel>Air conditioning</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdTv} />
                  <TagLabel>TV</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdPhone} />
                  <TagLabel>Telephone</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdHotTub} />
                  <TagLabel>Hot water</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBed} />
                  <TagLabel>Side bed</TagLabel>
                </Tag><Box as="br" />
              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
      
    }else if(roomNumber == '06'){
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              DELUX
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {delux.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              <CardBody>
              <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdAcUnit} />
                  <TagLabel>Air conditioning</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdTv} />
                  <TagLabel>TV</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdPhone} />
                  <TagLabel>Telephone</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={MdHotTub} />
                  <TagLabel>Hot water</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBed} />
                  <TagLabel>Divan</TagLabel>
                </Tag><Box as="br" />
              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
    }else if(roomNumber == '07'){
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              WARD BEDS
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {wardbeds.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              <CardBody>
              <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={BiBed} />
                  <TagLabel>Patient's Bed</TagLabel>
                </Tag><Box as="br" />
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={FaBed} />
                  <TagLabel>Divan</TagLabel>
                </Tag><Box as="br" />
              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
    }else if(roomNumber == '08'){
      return (
        <Box>
    
    <Header/>
            <Box
            id="parallaxBackground"
            backgroundImage={require('../../img/WebsiteStrip1.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
      // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              DAY CARE/WARD BED
            </Text>
          </Box>
    
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "2fr 1fr"]}>
          <Box p={5}>
          <Carousel width="100%">
          {daycare.map((imageUrl, index) => (
            <Box key={index} w="100%" h="550px">
              <img src={imageUrl} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </Box>
          ))}
        </Carousel>
          </Box>
          <Box p={5}>
            <Card boxShadow={'lg'}>
              <CardBody>
              <Heading size="lg" mb={4}>
                Room Facilities
                </Heading>
                <Tag size={'md'} key={'md'} variant='subtle' colorScheme='cyan' mb={2}>
                  <TagLeftIcon boxSize='15px' as={BiBed} />
                  <TagLabel>Patient's Bed Only</TagLabel>
                </Tag><Box as="br" />
              </CardBody>
            </Card>
          </Box>
        </Grid>
        </Box>
      )
    }
}

export default Room01Page