import { Box, Text } from '@chakra-ui/react';
import React from 'react'
import { useParams } from 'react-router-dom';
import Header from './Header';

function RoomPage() {

    const { roomNumber } = useParams();
  return (
    <Box>

<Header/>
        <Box
        id="parallaxBackground"
        // backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="100px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Our Facilities
        </Text>
      </Box>


    </Box>
  )
}

export default RoomPage