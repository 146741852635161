import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  Button,
  Image,
  Flex,
  Card,
  Grid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import cardImg from "../../img/card-img.jpg";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { isEmpty, isSet } from "lodash";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  // Assuming you have a cartItems array containing the items in the cart  const [cartItems, setCartItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0.0);
  const navigate = useNavigate();

  useEffect(() => {
    loadCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCart = () => {
    console.log(sessionStorage.getItem("sessionId"));
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            setCartItems(object.data.data);
            setSubTotal(object.data.sub_total);
            console.log(object.data);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const addCheckout = () => {
    navigate("/healthchecks/checkout");
  };

  const onRemoveCart = (id) => {
    try {
      const method = Methods.POST;
      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.removeCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          cart_id: id,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            window.location.reload();
            //console.log(object);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };
  const onQtyChange = (id, type) => {
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.changeQty;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          cart_id: id,
          type: type,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            window.location.reload();
            //console.log(object);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  // const cartItems = [
  //   {
  //     id: 1,
  //     name: "Under 40 years",
  //     price: 22500.0,
  //     image: surgeryIcon2,
  //     quantity: 1,
  //   },

  //   // Add more items as needed
  // ];

  // const totalPrice = cartItems.reduce(
  //   (total, item) => total + item.price * item.qty,
  //   0
  // );
  const totalPrice = subTotal;

  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/WebsiteStrip1.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Your Shopping Cart
        </Text>
      </Box>
      <Box p={4} bg="gray.100" borderRadius="md" shadow="md">
        {cartItems.length === 0 ? (
          <Text>Your cart is empty.</Text>
        ) : (
          <Grid gap={4} templateColumns={["1fr", "1fr", "3fr 1fr"]}>
            <Card>
              {cartItems.map((item) => (
                <Box
                  key={item.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  bg="white"
                  p={4}
                  borderRadius="md"
                >
                  <Box display="flex" alignItems="center">
                    <Image
                      src={item.image_url}
                      alt={item.name}
                      boxSize="100px"
                      objectFit="contain"
                      mr={4}
                    />

                    <VStack align="start" spacing={1}>
                      <Text fontSize="xl">{item.name}</Text>
                      <Flex alignItems="center">
                        <Text color="gray.800" fontSize="sm">
                          Quantity:
                        </Text>
                        <NumberInput
                          ml={2}
                          size="sm"
                          width="30%"
                          max={50}
                          min={1}
                          value={item.qty}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper
                              onClick={() => onQtyChange(item.cart_id, "MAX")}
                            />
                            <NumberDecrementStepper
                              onClick={() => onQtyChange(item.cart_id, "MIN")}
                            />
                          </NumberInputStepper>
                        </NumberInput>
                      </Flex>
                      <Text fontWeight="bold">
                        Rs.{item.price}{" "}
                        {item.qty > 1 ? " * "+item.qty+" : Rs "+ (item.price * item.qty) : null}{"/= "}
                      </Text>
                    </VStack>
                  </Box>

                  <Button
                    colorScheme="red"
                    variant="outline"
                    size="sm"
                    onClick={() => onRemoveCart(item.cart_id)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Card>

            <Card>
              <Box bg="white" p={4} borderRadius="md">
                <Flex flexDirection="column" justify="flex-end">
                  <Text fontWeight="bold" mb={2}>
                    Total: Rs. {totalPrice.toFixed(2)}
                  </Text>
                  <Box boxSize="xs" width="100%" height="100%">
                    <Image src={cardImg} alt="Card-Img" width="100%" />
                  </Box>
                  <Button colorScheme="teal" onClick={() => addCheckout()}>
                    Checkout
                  </Button>
                </Flex>
              </Box>
            </Card>
          </Grid>
        )}
      </Box>
      <FooterItem />
    </Box>
  );
};

export default Cart;
