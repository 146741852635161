import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Box, List, ListItem } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles

const ImageCarousel = ({ images, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Carousel</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Carousel width={'800px'}>
            {images.map((imageUrl, index) => (
              <Box key={index} w="800px" h="550px">
                <img src={imageUrl} alt={`Image ${index}`} />
              </Box>
            ))}
          </Carousel>
          <Box  flex="1" overflowY="auto" p="4" maxHeight="500px">
                <h3 style={{ marginBottom: '10px' }}>Room 1</h3>
                <List>
                  <ListItem>Item 1</ListItem>
                  <ListItem>Item 2</ListItem>
                  <ListItem>Item 3</ListItem>

                  {/* ... */}
                </List>
              </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageCarousel;