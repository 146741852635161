import { AspectRatio, Box, Grid, Text, Heading, GridItem } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import FooterItem from '../components/FooterItem'

const API_KEY = 'AIzaSyDRvW6nP225P-J7I-gohYRprtS8hX_Ehro';
const CHANNEL_ID = 'UCclAjanYdfAogIC0Bsx1Kew';

function Media() {

  // const [videos, setVideos] = useState([]);

  // useEffect(() => {
  //   fetchVideos();
  // }, []);

  // const fetchVideos = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${CHANNEL_ID}&key=${API_KEY}`
  //     );
  //     const data = await response.json();
  //     const videoData = data.items.map((item) => ({
  //       id: item.snippet.resourceId.videoId,
  //       title: item.snippet.title,
  //     }));
  //     console.log('Video data:', videoData);
  //     setVideos(videoData);
  //   } catch (error) {
  //     console.error('Error fetching videos:', error);
  //   }
  // };

  const videos = [
   
    {
      id: 1,
      title: 'ගර්භාෂයේ ඇතිවන ෆයිබ්‍රොයිඩ් ගෙඩි යනු මොනවාද? | What are uterine fibroids?',
      url: 'https://www.youtube.com/watch?v=gY_e5-BxQx0',
    },
    {
      id: 2,
      title: 'පියයුරු පිලිකාවක් විනාඩ් 2න් හදුනා ගමු | Detecting Breast Cancer in 2 Minutes',
      url: 'https://www.youtube.com/watch?v=_XSKnSxPBBc',
    },
    {
      id: 3,
      title: 'Cancer හොද කරගන්න පුලුවන් - Oncology Surgical Patient Testimonial',
      url: 'https://www.youtube.com/watch?v=SVaxId2tmeo',
    },
    {
      id: 4,
      title: 'What is Heart Attack?',
      url: 'https://www.youtube.com/watch?v=WgR1m3JqCAY',
    },
    {
      id: 5,
      title: 'මෙම රෝග ලක්‍ෂණ ඇත්නම් දැන්ම වෛද්‍යවරයෙකු හමුවන්න!',
      url: 'https://www.youtube.com/watch?v=R6zoVl1K9x0',
    },
    {
      id: 6,
      title: 'සමෙහි තිබෙන කැලැල් නිට්ටාවටම සුව කර ගත හැකිද?',
      url: 'https://www.youtube.com/watch?v=oKOeQYFb1iQ',
    },
    {
      id: 7,
      title: 'හිසරදය දරුණු රෝග ලක්ශණයක් විය හැකිය.',
      url: 'https://www.youtube.com/watch?v=evK3zyYx1Ws',
    },
    {
      id: 8,
      title: 'මේ දිනවල දරුවන් අතර පැතිරයන උණ වර්ග ගැන ඔබ දැනුවත්ද? ළමා රෝග විශේෂඥ වෛද්‍ය: දුමින්ද පතිරණ',
      url: 'https://www.youtube.com/watch?v=EHRD15rnR1I',
    },
    {
      id: 9,
      title: 'මුත්‍රා මාර්ග ආශ්‍රිත පිලිකා කල් තියා හදුනාගමු. Part - 2',
      url: 'https://www.youtube.com/watch?v=66AEHsu0Pgw',
    },
    {
      id: 10,
      title: 'මුත්‍රා මාර්ග ආශ්‍රිත පිලිකා කල් තියා හදුනාගමු. Part 1',
      url: 'https://www.youtube.com/watch?v=f25Cpl4hShU',
    },
    {
      id: 11,
      title: 'වකුගඩු වලට හානි කරන මුත්‍රා ගල් පිළිබද සරලවම දැන ගමු.',
      url: 'https://www.youtube.com/watch?v=K7tYocoV12Y',
    },

    // Add more video objects as needed
  ];

  return (
    <Box>
        <Header />
      {/* <Heading mt={10} ml={10}>International Patients</Heading> */}
      <Box
        backgroundImage={require('../img/international-patients-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
        // css={{
        //   '& > *': {
        //     height: '150px', // Set the desired fixed height for each row
        //   },
        // }}
      // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Media Information
        </Text>
      </Box>

      {/* <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={4}>
        <AspectRatio ratio={16 / 9} key={'WgR1m3JqCAY'}>
          <iframe
            title={'TEst Vid'}
            src={`https://www.youtube.com/embed/WgR1m3JqCAY`}
            allowFullScreen
          />
          <Text mt={2} fontWeight="bold">
            {'TEst Vid'}
          </Text>
        </AspectRatio>
    </Grid> */}
       <Box py={8}>
      <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6} pr={6} pl={6}>
        {videos.map((video) => (
          <GridItem key={video.id}>
            <Box
              as="iframe"
              width="100%"
              height="200px"
              src={`https://www.youtube.com/embed/${video.url.split('=')[1]}`}
              title={video.title}
              allowFullScreen
              borderRadius="md"
              boxShadow="md"
            />
            <Heading as="h3" size="sm" mt={4}>
              {video.title}
            </Heading>
          </GridItem>
        ))}
      </Grid>
    </Box>


      <FooterItem/>
    </Box>
  )
}

export default Media