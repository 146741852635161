import {
  Avatar,
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import FooterItem from "../components/FooterItem";
import BookingCard from "../components/BookingCard";
import { doctorSessionsSelector } from "./store/selectors";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import * as actions from "../store/WebStore/actions";
import { isEmpty } from "lodash";
import Preloader from '../components/Preloader';
import { loadingSelector } from '../store/WebStore/selectors';

function DoctorProfile() {
  //onst visible = useSelector(loadingSelector);

  const [isLoading, setIsLoading] = useState(true);

  const doctorSessions = useSelector(doctorSessionsSelector);
  const navigate = useNavigate();
  // const [docId, setDocId] = useState();

  const [doctorData, setDoctorData] = useState(
    !isEmpty(doctorSessions) ? doctorSessions : {}
  );
  //console.log(docId);
  useEffect(() => {
    // if (isEmpty(doctorSessions)) {
    //   navigate("/doctorsearch");
    // }
    setIsLoading(true);

    getDoctorSessions();
    //console.log(doctorData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const book = (data) => {
    const patient_no  = parseInt(data.CountActivePatient) +1 ;
    sessionStorage.setItem("docName", data.Docname);
    sessionStorage.setItem("amount", data.amount);
    sessionStorage.setItem("showDate", data.ShowDate);
    sessionStorage.setItem("displayDate", data.displayDate);
    sessionStorage.setItem("sday", data.Sday);
    sessionStorage.setItem("remark", data.Remark);
    sessionStorage.setItem("tDay", data.Tday);
    sessionStorage.setItem("tTime", data.Ttime);
    sessionStorage.setItem("sYesr", data.Syear);
    sessionStorage.setItem("spId", data.SpecID);
    sessionStorage.setItem("spName", data.SpecName);
    sessionStorage.setItem("sMonth", data.Smonth);
    sessionStorage.setItem("countActivePatient",patient_no.toString());
    sessionStorage.setItem("countMaxPatient", data.CountMaxPatient);

    navigate("/booksession");
  };

  const getDoctorSessions = () => {

    try {
      console.log(sessionStorage.getItem("doc_id"));

      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorSessions;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          doc_id: sessionStorage.getItem("doc_id"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {
            console.log(object.doctor);
            const payload = {
              data: object.doctor,
            };
            setDoctorData(object.doctor);
            //dispatch(actions.getDoctorSessions(payload));
            // navigate("/doctorprofile");
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }



  };

  // const [doctorName, setDoctorName] = useState("");

  // useEffect(() => {

  //     setDoctorName(doctor.doc_name);

  // }, []);

  return (
    <div>
         {isLoading ? (
        <Preloader />
      ) : (
      <Box>
        <Header />

        <Flex
          // w="100%"
          h={"205px"}
          mt={"20px"}
          backgroundImage={require("../img/WebsiteStrip1.png")}
          backgroundSize={"cover"}
          backgroundPosition={"center center"}
        ></Flex>
        <Grid
          flexDirection={["row", "column"]}
          width="100%"
          templateColumns={["1fr", "2fr", "1fr 1fr"]}
          gap={4}
          p={4}
          mt={2}
          mx="auto"
        >
          <Box>
            <Flex
              alignItems="start"
              mt="-80px"
              mx="auto"
              width="100%"
              flexDirection={"column"}
            >
              <Box
                borderWidth="1px"
                width="100%"
                borderRadius="lg"
                p={4}
                display="flex"
                alignItems="center"
                bg={"#266564"}
                mx="auto"
              >
                <Avatar
                  size="2xl"
                  src={
                    !isEmpty(doctorData)
                      ? doctorData.profile_image != null
                        ? doctorData.profile_image
                        : require("../img/default-doctor.jpeg")
                      : null
                  }
                  borderWidth="3px"
                  borderColor={"whiteAlpha.700"}
                />
                <VStack ml={4} spacing={0} alignItems="flex-start">
                  <Text fontSize="3xl" fontWeight="bold" color="white">
                    {!isEmpty(doctorData) ? doctorData.doc_name : null}
                  </Text>
                  <Text fontSize="xl" color="whiteAlpha.800">
                    ({!isEmpty(doctorData) ? doctorData.sp_name : null})
                  </Text>
                </VStack>
              </Box>
              <Box mx="auto" width="100%" pt={5} pr={5} pl={5}>
                <Heading mb="10px" size={"md"} color="#F8D107">
                  PROFESSIONAL QUALIFICATIONS & MEMBERSHIPS
                </Heading>
                <Text whiteSpace={"pre-line"}>{doctorData.qualification}</Text>
              </Box>
              <Box mx="auto" width="100%" pt={5} pr={5} pl={5}>
                <Heading mb="10px" size={"md"} color="#F8D107">
                  AREAS OF SPECIALISATIONS
                </Heading>
                <Text whiteSpace={"pre-line"}>
                  {!isEmpty(doctorData)
                    ? doctorData.specialisations_other
                    : null}
                </Text>
              </Box>
              <Flex mx="auto" width="100%" p={5} flexDirection="row">
                <Heading size={"md"} color="#F8D107">
                  LANGUAGES
                </Heading>
                <Text mx={2}>:</Text>
                <Text>
                  {!isEmpty(doctorData) ? doctorData.languages : null}
                </Text>
              </Flex>
              <Flex
                mx="auto"
                width="100%"
                pl={5}
                pb={5}
                pr={5}
                flexDirection="row"
              >
                <Heading size={"md"} color="#F8D107">
                  AVAILABILITY
                </Heading>
                <Text mx={2}>:</Text>
                <Text>
                  {!isEmpty(doctorData) ? doctorData.availability : null}
                </Text>
              </Flex>
              <Flex
                mx="auto"
                width="100%"
                pl={5}
                pb={5}
                pr={5}
                flexDirection="row"
              >
                <Heading size={"md"} color="#F8D107">
                  CONTACT
                </Heading>
                <Text mx={2}>:</Text>
                <Text>{!isEmpty(doctorData) ? doctorData.contact : null}</Text>
              </Flex>
              <Flex
                mx="auto"
                width="100%"
                pl={5}
                pb={5}
                pr={5}
                flexDirection="row"
              >
                <Heading size={"md"} color="#F8D107">
                  EMAIL
                </Heading>
                <Text mx={2}>:</Text>
                <Text>{!isEmpty(doctorData) ? doctorData.email : null}</Text>
              </Flex>
            </Flex>
          </Box>
          <Box mx="auto" width="100%" pr={5} pl={5}>
            <Heading mb="10px" size={"md"} color="#F8D107">
              BOOKING INFORMATIONS
            </Heading>
            <Grid
              flexDirection={["row", "column"]}
              width="100%"
              templateColumns={["1fr", "2fr", "1fr 1fr"]}
              gap={4}
              p={2}
              mt={2}
              mx="auto"
            >
              {!isEmpty(doctorData)
                ? doctorData.doctor_sessions.map((option) => (
                  <BookingCard
                    sessionDate={option.displayDate}
                    sessionTime={option.Ttime}
                    activePatientCount={option.CountActivePatient}
                    remark={option.Remark}
                    onPress={() => book(option)}
                  />
                ))
                : null}
            </Grid>
          </Box>
        </Grid>
        <FooterItem />
      </Box>
      )}
    </div>
  );
}

export default DoctorProfile;
