import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, Heading, Input, Link, List, ListItem, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import LatestItems2 from '../../components/LatestItems2';
import LatestItems4 from '../../components/LatestItems4';

function HeartCentre() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '1148');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '1148',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const viewDocProfile = (doc_id)=>{
    sessionStorage.setItem('doc_id',doc_id);
    navigate("/doctorprofile");
  }

  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Heart Centre</Heading> */}

      <Box
        backgroundImage={require('../../img/Heart-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <VStack align="flex-start">
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Nawaloka Heart Centre
        </Text>
        <Text
        fontSize="xl"
        fontWeight="semibold"
        ml="40px"
        color="white"
        textShadow="0 2px 4px rgba(0,0,0,0.3)"
        >
        Nurturing a Joyful Heart for a Lifetime of Wellness
        </Text>
        </VStack>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
      <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
      <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Cardiac</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>
      <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="5px">Resident Cardiac Surgeon</Heading>
      </Flex>
      <Flex borderWidth="1px" borderRadius="lg" p={4} onClick={()=>viewDocProfile('D11227')} cursor={'pointer'}
      display="flex" width="100%" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <Avatar size="2xl" src={require('../../img/doctorImages/dr-pai.png')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
        <VStack spacing={0} alignItems="center">
            <Text fontSize="xl" fontWeight="bold" color="white">Dr. Krishnanand Pai</Text>
            <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                MBBS, MS, MCh, FRCS [Eng], FRCS [Edin],{'\n'}
                FRCTh [Eng] - Resident Cardiac Thoracic Surgeon
            </Text>
        </VStack>
      </Flex>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777{'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            076 671 3344{'\n'}
            076 301 6393
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Box>
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box>
        <Text mb={10} whiteSpace={"pre-line"} textAlign={'justify'}>
        A lot of people may have already experienced chest pain or tightness for several times. 
        Are you aware that this is a warning sign of some types of lungs and cardiovascular diseases? 
        It’s also one of the dangerous signs of “Heart Attack.”{"\n"}{"\n"}

        A heart attack is a serious medical emergency caused by a blocked flow of blood, 
        making cardiac muscles function ineffectively. It will lead to acute heart failure or 
        death if the patient does not receive the treatment on time.{"\n"}{"\n"}

        If you have the following symptoms, you shall seek medical treatment immediately:{"\n"}
        <UnorderedList>
        <ListItem>Chest pain and/or tightness (The patient might also have pain around their shoulders, neck, and jaw.)</ListItem>
        <ListItem>Xiphoid process pain</ListItem>
        <ListItem>Exhaustion and breathing difficulty</ListItem>
        <ListItem>Perspiration and nausea</ListItem>
        <ListItem>Heart palpitations</ListItem>
        <ListItem>Dizziness and fainting</ListItem>
      </UnorderedList>
      {"\n"}
        Symptoms occur for around 20-30 minutes, but if you have these all the time, 
        which means there is a risk of a heart attack; you have to seek medical treatment immediately.{"\n"}{"\n"}

        Even though it is a dangerous disease, it can be prevented by looking after yourself. 
        People should consume good food, frequently do exercise, and undergo annual health checkups. 
        Check the risk factors that might lead to diseases such as diabetes, high blood pressure, 
        elevated cholesterol, etc.
        </Text>
        </Box>        

            <Box mx="auto" width="100%">
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                Cardiac Surgery
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            <Box overflowX="auto">
                <Table size='sm'>
                  <Thead>
                    <Tr>
                      <Th>Pediatric</Th>
                      <Th>Adult</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>ASD VSD</Td>
                      <Td>Off-pump CABG</Td>
                    </Tr>
                    <Tr>
                      <Td>PDA</Td>
                      <Td>On pump CABG</Td>
                    </Tr>
                    <Tr>
                      <Td>MV repair</Td>
                      <Td>MUR / AVE / DUR</Td>
                    </Tr>
                    <Tr>
                      <Td>TOF complications</Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>Aortip root replacement(Bertal six)</Td>
                      <Td></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                Interventional cardiology
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <UnorderedList>
                <ListItem>Coronary angiogram</ListItem>
                <ListItem>Coronary angioplasty</ListItem>
                <ListItem>Temp pacemaker</ListItem>
                <ListItem>Electro physiotherapy studies ablation procedure</ListItem>
                <ListItem>ASD device closure</ListItem>
                <ListItem>PDA device closure</ListItem>
                <ListItem>Cardiac catheterization procedure</ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                Cardiac imaging
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <UnorderedList>
                    <ListItem>ECG</ListItem>
                    <ListItem>ZD Echo</ListItem>
                    <ListItem>TEE</ListItem>
                    <ListItem>CT coronary angiogram</ListItem>
                    <ListItem>Cardiac MRI</ListItem>
                    <ListItem>Stress echo</ListItem>
                    <ListItem>Dobutamine stress echo</ListItem>
                </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
</Box>

<Box mt="40px">
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are some common heart-related conditions treated in cardiac care?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Cardiac care covers a wide range of heart-related conditions, including coronary artery disease, 
      heart attacks, heart failure, arrhythmias (abnormal heart rhythms), valve disorders, congenital 
      heart defects, and hypertension (high blood pressure).
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the symptoms of a heart attack?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Symptoms of a heart attack can vary, but they often include chest pain or discomfort, 
      shortness of breath, lightheadedness, nausea, sweating, and pain or discomfort in the arms, 
      jaw, neck, or back. It is important to note that symptoms may differ between men and women, 
      and some individuals may experience atypical symptoms.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        How is coronary artery disease diagnosed?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      The diagnosis of coronary artery disease (CAD) typically involves a combination of medical 
      history assessment, physical examination, blood tests, electrocardiogram (ECG), stress tests, 
      imaging tests (such as angiography or cardiac CT scan), and sometimes cardiac catheterization. 
      These tests help evaluate the condition of the coronary arteries and determine the presence and 
      severity of blockages.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What treatments are available for heart failure?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      The treatment of heart failure depends on the severity and underlying cause of the condition. 
      It may involve lifestyle modifications (such as dietary changes and exercise), medications to manage 
      symptoms and improve heart function, devices like pacemakers or implantable cardioverter-defibrillators 
      (ICDs), and in severe cases, heart transplantation.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Can heart disease be prevented?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Yes, many forms of heart disease can be prevented or delayed by adopting a heart-healthy lifestyle. 
      This includes regular physical activity, maintaining a healthy weight, eating a balanced diet low 
      in saturated and trans fats, quitting smoking, managing stress, and controlling conditions such as 
      diabetes and high blood pressure.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        When should someone seek emergency medical help for a potential heart problem?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      It is crucial to seek emergency medical help immediately if experiencing symptoms such as chest pain 
      or discomfort, shortness of breath, severe dizziness or fainting, rapid or irregular heartbeat, or 
      sudden weakness or numbness in the face, arm, or leg. These symptoms could indicate a heart attack 
      or other serious cardiac event.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        How long does the recovery process take after cardiac procedures?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      The recovery process after cardiac procedures can vary depending on the specific procedure performed, 
      the individual's overall health, and their adherence to post-procedure guidelines. In general, 
      recovery may involve a hospital stay of a few days to a week, followed by a period of rest and 
      gradually increasing physical activity. Full recovery may take several weeks to several months, 
      depending on the extent of the procedure and individual factors.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Is open-heart surgery required for all heart conditions?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      No, open-heart surgery is not required for all heart conditions. Many heart conditions can be treated 
      with less invasive procedures such as angioplasty, stenting, or medication management. Open-heart 
      surgery is typically reserved for more complex cases, such as coronary artery bypass grafting or 
      heart valve repair/replacement. The specific treatment approach depends on the individual's condition 
      and the recommendations of their healthcare provider.
      </Text>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>
</Flex>
      </Grid>
      </Box>
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems4/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default HeartCentre
