import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import HeaderPic from "../../components/HeaderPic";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import {
  AccordionButton,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import Accordion from "../../components/Accordion";
import FooterItem from "../../components/FooterItem";
import surgeryIcon1 from "../../img/healthcheck1.jpeg";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import { useNavigate } from "react-router-dom";
import { Methods } from "../../constants";
import { isEmpty, isSet } from "lodash";

function HealthChecks() {
  const navigate = useNavigate();
  const [item, setItem] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    loadPackages(Methods.POST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPackages = (method) => {
    const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getPackages;
    fetch(URL, {
      method,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          setItem(object.data);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const generateUniqueId = () => {
    const timestamp = Date.now().toString();
    const randomNum = Math.floor(Math.random() * 1000).toString();
    return timestamp + randomNum;
  };

  const addToCart = (item) => {
    //const sessionId = generateUniqueId();

    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.addCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          package_id: item.id,
          qty: 1,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            navigate("/healthchecks/cart");
            //window.location.reload();
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const viewPackage = (item) => {
    navigate(
      "/healthchecks/package-details?selected_package_id=" +
        item.id +
        "&type=" +
        item.type
    );
  };

  const mensitem = [
    {
      id: 1,
      name: "Classic Under 40",
      image: surgeryIcon2,
      price: "Rs. 22500.00",
      slug: "product-1",
    },
    {
      id: 2,
      name: "Premier Under 40 Men",
      image: surgeryIcon2,
      price: "Rs.34000.00",
      slug: "product-2",
    },

    {
      id: 3,
      name: "Standard Over 40",
      image: surgeryIcon2,
      price: "Rs.25000.00",
      slug: "product-3",
    },

    {
      id: 4,
      name: "Canser Screening Over 40",
      image: surgeryIcon2,
      price: "Rs.40000.00",
      slug: "product-4",
    },
    {
      id: 5,
      name: "Senior Citizens Over 50",
      image: surgeryIcon2,
      price: "Rs.55000.00",
      slug: "product-5",
    },
    // Add more items as needed
  ];

  const womensitem = [
    {
      id: 1,
      name: "Classic Under 40",
      image: surgeryIcon1,
      price: "Rs. 22500.00",
      slug: "product-1",
    },
    {
      id: 2,
      name: "Premier Under 40 Men",
      image: surgeryIcon1,
      price: "Rs.34000.00",
      slug: "product-2",
    },

    {
      id: 3,
      name: "Standard Over 40",
      image: surgeryIcon1,
      price: "Rs.25000.00",
      slug: "product-3",
    },

    {
      id: 4,
      name: "Canser Screening Over 40",
      image: surgeryIcon1,
      price: "Rs.40000.00",
      slug: "product-4",
    },
    {
      id: 5,
      name: "Senior Citizens Over 50",
      image: surgeryIcon1,
      price: "Rs.55000.00",
      slug: "product-5",
    },
    // Add more items as needed
  ];

  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/DoorstepStripNew.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Health Checks
        </Text>
      </Box>

      <Tabs variant="unstyled" p={5}>
        <TabList ml={5}>
          <Tab _selected={{ color: "white", bg: "blue.500", fontSize: "24px" }}>
            Men
          </Tab>
          <Tab _selected={{ color: "black", bg: "#FFC0CB", fontSize: "24px" }}>
            Women
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Heading size={"lg"} ml="10px">
              Men's Health Checks
            </Heading>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(5, 1fr)",
              }}
              gap={4}
              mt="10px"
              pl="4px"
              pr="4px"
            >
              {!isEmpty(item)
                ? item.map((item) =>
                    item.type == "MEN" ? (
                      <Box
                        key={item.id}
                        padding={4}
                        border="1px"
                        as="a"
                        onClick={() => viewPackage(item)}
                        //href="/healthchecks/package-details"
                        textDecoration="none"
                      >
                        <Image src={item.image_url} alt={item.name} mb={4} cursor={'pointer'} />

                        <Box fontWeight="bold" mb={2} textAlign={"center"}>
                          {item.name}
                        </Box>

                        <Box mb={2} textAlign={"center"}>
                          Price: {item.price}/=
                        </Box>

                        <Flex justifyContent="center">
                          <Button
                            backgroundColor="#1A908F"
                            onClick={() => addToCart(item)}
                            color="white"
                            size="sm"
                            _hover={{
                              bg: "#004242",
                            }}
                            _focus={{
                              bg: "#004242",
                            }}
                          >
                            Add to Cart
                          </Button>
                        </Flex>
                      </Box>
                    ) : null
                  )
                : null}
            </Grid>
          </TabPanel>
          <TabPanel>
            <Heading size={"lg"} ml="10px">
              Women's Health Checks
            </Heading>

            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(5, 1fr)",
              }}
              gap={4}
              mt="10px"
              pl="4px"
              pr="4px"
            >
              {!isEmpty(item)
                ? item.map((item) =>
                    item.type == "WOMEN" ? (
                      <Box
                        key={item.id}
                        padding={4}
                        border="1px"
                        as="a"
                        onClick={() => viewPackage(item)}
                        //href={`/products/${item.slug}`}
                        textDecoration="none"
                      >
                        <Image src={item.image_url}  alt={item.name} mb={4} cursor={'pointer'} />

                        <Box fontWeight="bold" mb={2} textAlign={"center"}>
                          {item.name}
                        </Box>

                        <Box mb={2} textAlign={"center"}>
                          Price: {item.price}/=
                        </Box>

                        <Flex justifyContent="center">
                          <Button
                            backgroundColor="#1A908F"
                            onClick={() => addToCart(item)}
                            color="white"
                            size="sm"
                            _hover={{
                              bg: "#004242",
                            }}
                            _focus={{
                              bg: "#004242",
                            }}
                          >
                            Add to Cart
                          </Button>
                        </Flex>
                      </Box>
                    ) : null
                  )
                : null}
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <FooterItem />
    </Box>
  );
}

export default HealthChecks;
