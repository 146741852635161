import {
  AspectRatio, Box, Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel,
  Grid, Heading, Input, Select, SimpleGrid, Stack, StackDivider,
  Text, Textarea, useToast
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import Header from '../components/Header'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { JsonToForm, JsonToParams } from "../utils";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import FooterItem from '../components/FooterItem';
import { motion } from 'framer-motion'

function ContactUs() {

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const recaptchaRef = useRef(null);
  const [name, setname] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [email, setEmail] = useState(null);
  const [msg, setmsg] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);

    try {

      sendmail();

      reset(); // Clear form fields
    } catch (error) {
      // Display error toast
      // toast({
      //   title: 'Error',
      //   description: 'An error occurred. Please try again later.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    }

    setLoading(false);
  };


  const sendmail = () => {
    // setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {

      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.sendmail;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          name: name,
          mobile: mobile,
          email: email,
          msg: msg,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {

            console.log(object);
            if (!object.error) {
              toast({
                title: 'Success',
                description: object.msg,
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
             // window.location.reload();
            } else {
              toast({
                title: 'Error',
                description: object.msg,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      //setIsLoading(false);
      setEmail('');
      setMobile('');
      setname('');
      setmsg('');
      

    }
  };
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    >
    <Box>
      <Header />
      {/* <Heading mt={10} ml={10}>Contact Us</Heading> */}
      <Box
        backgroundImage={require('../img/WebsiteStrip1.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
      // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Contact Us
        </Text>
      </Box>

      <Grid
        flexDirection={["column", "row"]}
        width="100%"
        templateColumns={["1fr", "1fr", "1fr 1fr"]}
        gap={4}
        p={4}
        mt={2}
        mx="auto"
      >
        <Box>
          <SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} width="100%">
            <Flex>
              <Box ml={{ base: 10, lg: 10 }}>
                <Text pt="2" fontSize="lg" fontWeight={'bold'}>
                  Nawaloka Hospitals PLC,
                  <br />
                  Colombo 2,
                  <br />
                  Sri Lanka.
                </Text>
              </Box>
            </Flex>
            <Flex>
              <Box ml={{ base: 10, lg: 0 }}>
                <Text pt="2" fontSize="md">
                  <strong>General Line :</strong> 0115 577 111
                  <br />
                  <strong>Channeling Hotline :</strong> 0115 777 888
                  <br />
                  <strong>Fax :</strong> 0112 430 393
                  <br />
                  <strong>Email :</strong> nawaloka@slt.lk
                </Text>
              </Box>
            </Flex>
          </SimpleGrid>

          <Box display="flex" justifyContent="center" mt="20px">
            <Card width="80%" pb="10px" boxShadow={'2xl'} mt="10px" mb="10px" backgroundColor="#e09f3e">
              <CardHeader><Heading size="md" color="white">Contact Us</Heading></CardHeader>
              <Box width="90%" mx="auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl id="name" mb={4} isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input required type="text" {...register('name')} bg="white" value={name} onChange={(event) => setname(event.target.value)}
                    />
                  </FormControl>
                  <FormControl id="mobile" mb={4} isRequired>
                    <FormLabel>Mobile No</FormLabel>
                    <Input required value={mobile} {...register('mobile')} bg="white" onChange={(event) => setMobile(event.target.value)} type="number" />
                  </FormControl>
                  <FormControl id="email" mb={4} isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input required value={email} {...register('email')} bg="white" onChange={(event) => setEmail(event.target.value)} type="email" />
                  </FormControl>

                  <FormControl id="message" mb={4} isRequired>
                    <FormLabel>Message</FormLabel>
                    <Textarea required {...register('message')} bg="white" defaultValue={msg} onChange={(event) => setmsg(event.target.value)} resize="vertical" />
                  </FormControl>

                  {/* <ReCAPTCHA
          sitekey="6LdQYIkmAAAAAKqMPKyIP10iPgxSQdHcUfWEM613"
          // size="invisible"
          ref={recaptchaRef}
        /> */}

                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="Submitting"
                    bg="#9e2a2b"
                    color="white"
                    mt={4}
                    mb={4}
                    _hover={{
                      bg: '#611415',
                      color: 'white',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}

                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Card>
          </Box>

        </Box>
        <Box>
        <Flex justifyContent="flex-end" w="100%" borderRadius="10px">
  {/* <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15842.979508475273!2d79.853334!3d6.9210722!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2591514038547%3A0xa12560c4d858fc3f!2sNawaloka%20Hospitals%20PLC!5e0!3m2!1sen!2slk!4v1684472988384!5m2!1sen!2slk"
    width="100%"
    height="450"
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
    title="Google Maps"
  ></iframe> */}

  <iframe 
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1340.965674826302!2d79.852513354522!3d6.9209175095431315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2591514038547%3A0xa12560c4d858fc3f!2sNawaloka%20Hospitals%20PLC!5e0!3m2!1sen!2slk!4v1692249433570!5m2!1sen!2slk" 
  width="100%"
  height="450"
  // style="border:0;" 
  // allowfullscreen="" 
  loading="lazy" 
  // referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
</Flex>
        </Box>
      </Grid>

        <FooterItem/>
    </Box>
    </motion.div>
  )
}

export default ContactUs
