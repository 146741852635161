import EnvConstants from "./EnvConstants";

export const { BASE_URL, ECHANNELLING, NAWALOKA_HIS, DEV_LOGS } =
  EnvConstants.PRE_PROD;

export const ApiEndpoints = {
  echannelling: {
    specializations: "get_echannelling_speciality_list",
    specialization: "v1/specializations/",
    hospitals: "v1/hospitals/",
    doctorAvailability: "get_doctor_list/",
    doctorSessions: "get_doctor_sessions",
    nextAppointmentNo: "get_next",
    payNow: "pay_now",
    redirectUrl: "send_redirect_url",
    paymentModes: "get_payment_modes",
    sendmail: "send_mail",
    sendmailInternational: "send_inter_mail",
    sendmailPhysio: "send_physio_mail",
    sendmailAdmResev: "send_admresv_mail",
    sendPromoMail: "kiss_promo_mail",
    doctorAppointmentList: "v1/doctor-appointment-list",
  },
  healthPackages: {
    getPackages: "get_health_packages",
    checkout: "checkout",
    checkout_bht: "checkout_bht",
    voucher_pay: "voucher_pay",
    success: "save_success",
    addCart: "add_to_cart",
    getCart: "get_cart",
    removeCart: "remove_cart",
    changeQty: "change_qty_cart",
    viewPackage: "view_health_package",
    cart_count: "get_cart_count",
  },
  getEncrptedKey: "get_encrypted_key",
};
