import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
    Flex, FormControl, Grid, Heading, Image, Input, Link, List, ListItem, Modal, ModalBody, ModalContent, ModalOverlay, OrderedList, Select, Stack, 
    Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack, 
    useDisclosure} from '@chakra-ui/react'
  import React, { useState }  from 'react'
  import Header from '../../components/Header'
  import FooterItem from '../../components/FooterItem'
  import { Link as RouterLink, useNavigate } from "react-router-dom";
  import { SearchIcon } from '@chakra-ui/icons';
  import { useSelector, useDispatch } from "react-redux";
  import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
  import * as actions from "../store/actions";
  import LatestItems from '../../components/LatestItems';
  import LatestItems1 from '../../components/LatestItems1';
  
  function EliteCentre() {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
      const [selectedDate, setSelectedDate] = useState("Any");
      const [doctorName, setDoctorName] = useState("*");
      const handleDateChange = (event) => {
          setSelectedDate(event.target.value);
        };
      const handleClearDate = () => {
          setSelectedDate(null);
        };
  
          //--------------------------------------------------------------------------------------------------------------
    const getDoctorList = () => {
      // console.log(selectedDate);
      // console.log(spId);
      // console.log(doctorName);
  
      sessionStorage.setItem('selectedDate', selectedDate);
      sessionStorage.setItem('spId', '45');
      sessionStorage.setItem('doctorName', doctorName);
  
      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          sp_id: '45',
          date: selectedDate,
          name: doctorName,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {
            const payload = {
              data: object.doctort_list,
            };
            // console.log(object.doctort_list);
            dispatch(actions.getDoctorList(payload));
            // Link
            navigate("/doctorsearch");
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    };


    const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image) => {
    setSelectedImage(image);
    onOpen();
  };
  
    return (
      <div>
          <Header/>
        {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}
  
        <Box
          backgroundImage={require('../../img/pagecovers/elite-cover.png')}
          backgroundSize="cover"
          backgroundPosition="center"
          height="200px"
          display="flex"
          mt="10px"
          alignItems="center"
        >
          <Text
            fontSize="4xl"
            fontWeight="bold"
            ml="40px"
            color="white"
            textShadow="0 2px 4px rgba(0,0,0,0.5)"
          >
            Elite Centre
          </Text>
        </Box>
  
        <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
          <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        
        {/* <Flex>
          <Heading mt="20px" ml="20px" size={'md'} mb="5px">Resident Rheumatologist</Heading>
        </Flex>
        <Flex borderWidth="1px" borderRadius="lg" p={4} 
        display="flex" width="100%" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
          <Avatar size="2xl" src={require('../../img/doctorImages/dr-harindu.jpg')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
          <VStack spacing={0} alignItems="center">
              <Text fontSize="xl" fontWeight="bold" color="white">Dr. Harindu Wijesinghe</Text>
              <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                  MBBS IS.L.I, MD [Coll, MCP (UK)]{'\n'}
                  Consultant in Rheumatology & Rehabilitation{'\n'}
                  Specialist in Sports Medicine
              </Text>
          </VStack>
        </Flex> */}
  
        <Flex>
          <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Contact</Heading>
        </Flex>
        <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
        display="flex" mt="5px" alignItems="center" bg={'#DAA520'} mx="auto" flexDirection="column">
          <VStack spacing={0} alignItems="center">
              <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" fontWeight={'bold'} whiteSpace={'pre-line'}>
              077 132 2366 {'\n'}
              </Text>
          </VStack>
        </Box>


        <Flex>
          <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
        </Flex>
        <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
        display="flex" mt="5px" alignItems="center" bg={'#DAA520'} mx="auto" flexDirection="column">
          <VStack spacing={0} alignItems="center">
              <Text fontSize="lg" textAlign="center" color="whiteAlpha.800"  fontWeight={'bold'} whiteSpace={'pre-line'}>
              0115 777 777 {'\n'}
              0115 777 888
              </Text>
          </VStack>
        </Box>
        </Flex>
        <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
          <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
            <Box>
              <Text fontWeight={'semibold'} fontSize={'18px'} mb={'10px'}>Advanced Healthcare Services at Nawaloka Elite Centre</Text>
            <Text textAlign={'justify'}>
            Welcome to Nawaloka Elite Centre, your destination for advanced healthcare services in Sri Lanka. 
            Our state-of-the-art medical facility is designed to provide cutting-edge healthcare solutions with a 
            personal touch. Equipped with the latest in medical technology, our team of experienced specialists, 
            dedicated nurses, and supportive staff are committed to delivering high-quality care tailored to your 
            individual needs. Whether you're looking for routine check-ups, specialized medical treatments, 
            or comprehensive wellness services, Nawaloka Elite Centre is here to ensure your health and well-being. 
            Experience unparalleled healthcare excellence at Nawaloka Elite Centre, where patient care and comfort 
            are our top priorities.
            </Text>

            <Text fontWeight={'bold'} fontSize={'24px'} mt={'30px'}>Nawaloka Elite Corporate Centre</Text>
            <Text fontWeight={'semibold'} fontSize={'18px'} mb={'10px'}>Comprehensive Corporate Healthcare at Nawaloka Elite Corporate Centre</Text>
            <Text textAlign={'justify'}>
            Nawaloka Elite Corporate Centre is your trusted partner for comprehensive corporate healthcare solutions. 
            We specialize in enhancing employee well-being through customized health services tailored to meet the needs 
            of businesses in Sri Lanka. Our offerings include occupational health assessments, executive health check-ups, 
            wellness programs, and preventive care, all designed to promote a healthier, more productive workforce. 
            At Nawaloka Elite Corporate Centre, we understand the importance of employee health for organizational success. 
            Our dedicated team of healthcare professionals provides efficient, top-tier medical care and advisory services, 
            ensuring your employees receive the best possible healthcare. Elevate your corporate health standards with 
            Nawaloka Elite Corporate Centre—where employee wellness is our mission.
            </Text>
            </Box>

            <Box mt={10}>
          <Grid templateColumns={['1fr', '1fr 1fr']} gap={4}>
            <Image 
              src={require('../../img/serviceImages/elite-corporate-img-1.jpg')}
              alt="Gallery Image 1" 
              cursor="pointer" 
              onClick={() => handleImageClick(require('../../img/serviceImages/elite-corporate-img-1.jpg'))} 
            />
            <Image 
              src={require('../../img/serviceImages/elite-corporate-img-2.jpg')} 
              alt="Gallery Image 2" 
              cursor="pointer" 
              onClick={() => handleImageClick(require('../../img/serviceImages/elite-corporate-img-2.jpg'))} 
            />
          </Grid>
        </Box>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Image src={selectedImage} alt="Selected Gallery Image" />
          </ModalBody>
        </ModalContent>
      </Modal>

        </Grid>
        <Flex flexDirection={'column'} alignItems="left" p={4}>
          <LatestItems1/>
        </Flex>
        </Grid>
        <FooterItem/>
      </div>
    )
  }
  
  export default EliteCentre
  