import { Box, Flex, Grid, GridItem, HStack, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import PhotoCollage from '../../components/PhotoCollage'
import { Link } from 'react-router-dom'
import ThumbnailGrid from '../../components/ThumbnailGrid'

const thumbnails = [
  { src: require('../../img/rooms/superPremium/IMG_7112.jpeg'),
    title: 'SUPER PREMIUM', 
    roomNo: '01',
    items: ['A/C', 'TV','Tele','HW','Side Bed','Sofa','Coffee Table','Free WIFI, Visitng Area/pantry/two washrooms/Dinning area'],
  },
  { src: require('../../img/rooms/vipPremium/IMG_7153.jpeg'),
    title: 'VIP PREMIUM' , 
    roomNo: '02',
    items: ['A/C', 'TV','Tele','HW','Side Bed','Sofa'],
  },
  { src: require('../../img/rooms/premium/img07.jpg'), 
    title: 'PREMIUM', 
    roomNo: '03',
    items: ['A/C', 'TV','Tele','HW','Side Bed'],
  },
  { src: require('../../img/rooms/deluxSuperior/IMG_7099.jpeg'), 
    title: 'DELUX SUPERIOR', 
    roomNo: '04',
    items: ['A/C', 'TV','Tele','Side Bed'],
  },
  { src: require('../../img/rooms/executive/IMG_7166.jpeg'),
    title: 'EXECUTIVE', 
    roomNo: '05',
    items: ['A/C', 'TV','Tele','HW',],
  },
  { src: require('../../img/rooms/delux/IMG_7172.jpeg'),
    title: 'DELUX', 
    roomNo: '06',
    items: ['A/C', 'TV','Tele','HW','Divan'],
  },
  { src: require('../../img/rooms/wardBeds/img1180_2.jpg'), 
    title: 'WARD BEDS', 
    roomNo: '07',
    items: ["Patient's bed and Divan"],
  },
  { src: require('../../img/rooms/wardBeds/img1180_3.jpg'), 
    title: 'DAY CARE/WARD BED', 
    roomNo: '08',
    items: ["Patient's bed only",]
  },
  // Add more thumbnail data
];

function Facilities() {
  return (
    <Box>
        <Header/>
        <Box
        id="parallaxBackground"
        backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="100px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Our Facilities
        </Text>
      </Box>

        <PhotoCollage/>

    <Box display="flex" justifyContent="center" alignItems="center">
        <HStack as="nav" spacing={4} p={4} 
        border='1px' 
        borderColor={'gray'} 
        width="88%"  
        style={{ scrollBehavior: 'smooth' }}
        fontSize={'lg'}
        fontWeight={'semibold'}
        >
        <a href="#home">Overview</a>
        <a href="#rooms">Rooms</a>
        {/* <a href="#facilities">Facilities</a> */}
        </HStack>
    </Box> 

    <section id="home">
  <Box ml="90px" mt="20px" border="1px solid gray" p="20px" w="70%">
    <Heading size='md' mb='10px'>Overview</Heading>
    <Box w="100%">
      <Text textAlign='justify'>
      Nawaloka Hospital in Colombo is renowned for its exceptional healthcare services and state-of-the-art facilities. The hospital rooms at Nawaloka are designed to provide patients with utmost comfort and care during their stay. Each room is equipped with modern amenities, ensuring a conducive environment for recovery.

      The hospital offers a range of room options to cater to diverse patient needs. Private rooms provide a serene and personalized space for patients to recuperate, complete with en-suite bathrooms, comfortable furnishings, and entertainment facilities. These rooms are ideal for those seeking privacy and a peaceful healing atmosphere.

      In addition to private rooms, Nawaloka Hospital also provides semi-private rooms for those who prefer a shared space with a fellow patient. These rooms are thoughtfully arranged to maintain patient privacy while fostering a sense of companionship.

      Regardless of the room type, Nawaloka Hospital prioritizes hygiene, safety, and patient well-being. The hospital staff is dedicated to delivering compassionate care and ensuring that patients have a comfortable and reassuring stay as they work towards their recovery.
      </Text>
    </Box>
  </Box>
</section>

<section id="rooms">
  <Box ml="90px" mt="20px" border="1px solid gray" p="20px" w="70%">
    <Heading size='md' mb='20px'>Rooms available at Nawaloka Hospital Colombo</Heading>
    {/* <Grid flexDirection={['row','column']} 
        width="100%" 
        templateColumns={["1fr", "2fr", "1fr 1fr"]}
        maxW="1200px"
          gap="50px"
          p={4}
          mt={2}
          mx="auto">
      <GridItem>ITem 1</GridItem>
      <GridItem>ITem 2</GridItem>
    </Grid> */}

    <ThumbnailGrid thumbnails={thumbnails} />
  </Box>
</section>

{/* <section id="facilities">
  <Box ml="90px" mt="20px" border="1px solid gray" p="20px" w="70%">
    <Heading size='md' mb='10px'>Facilities</Heading> */}
    {/* <Grid flexDirection={['row','column']} 
        width="100%" 
        templateColumns={["1fr", "2fr", "1fr 1fr"]}
        maxW="1200px"
          gap="50px"
          p={4}
          mt={2}
          mx="auto">
      <GridItem>ITem 1</GridItem>
      <GridItem>ITem 2</GridItem>
    </Grid> */}
{/* 
  </Box>
</section> */}

      <FooterItem/>
    </Box>
  )
}

export default Facilities