import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, GridItem, Heading, Image, Input, Link, List, ListItem, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import LatestItems1 from '../../components/LatestItems1';

function Radiology() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '33');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const videos = [
    

    // Add more video objects as needed
  ];

  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/radiology-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="5xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          AI Radiolog
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "2fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center">
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Radiology and Imaging</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>
      {/* <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="5px">Resident Rheumatologist</Heading>
      </Flex>
      <Flex borderWidth="1px" borderRadius="lg" p={4} 
      display="flex" width="100%" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <Avatar size="2xl" src={require('../../img/doctorImages/dr-harindu.jpg')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
        <VStack spacing={0} alignItems="center">
            <Text fontSize="xl" fontWeight="bold" color="white">Dr. Harindu Wijesinghe</Text>
            <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                MBBS IS.L.I, MD [Coll, MCP (UK)]{'\n'}
                Consultant in Rheumatology & Rehabilitation{'\n'}
                Specialist in Sports Medicine
            </Text>
        </VStack>
      </Flex> */}

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Radiology Coordinator</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            076 304 9156{'\n'}
            077 359 9223
            </Text>
        </VStack>
      </Box>
      <Box mt="15px">
        <Image
          src={require('../../img/radiology/aict.jpeg')}
          alt="Radiology & Imaging"
          // objectFit="cover"
          width="100%"
          // height="200px"
        />
        <Text
          fontSize="sm" /* Small text size */
          fontWeight="bold"
          color="gray.600"
          mt="5px" /* Adds space between image and text */
          textAlign="center" /* Centers the text below the image */
        >
          South Asia's Fastest 640-Slice Aquilion One (Prism Edition) CT Scaner, POWERED BY AI
        </Text>
      </Box>
      </Flex>
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box>
        <Text mb={10} whiteSpace={"pre-line"} textAlign={'justify'}>
        Welcome to Nawaloka Hospitals PLC: Sri Lanka's Premier AI-Powered Radiology Centre
        At Nawaloka Hospitals PLC, we are proud to lead the way as Sri Lanka's first AI-powered radiology centre. 
        Our commitment to integrating advanced technology into healthcare enhances patient outcomes and transforms 
        the diagnostic process. Our Radiology Department is staffed with a team of highly skilled radiologists, 
        technologists, and support staff dedicated to providing exceptional care and precise diagnoses.{'\n'}{'\n'}

        <Text fontWeight={700} fontSize={'xl'} mb={2}>Harnessing AI for Superior Diagnostic Imaging</Text>

        <Text>Our AI-powered radiology services utilize cutting-edge imaging technology that revolutionizes traditional 
          diagnostic methods. Key features include :</Text>

          <Box mt={2}>
        {/* <Heading size="sm">AREAS OF CONSULTATION</Heading> */}
        <UnorderedList>
            <ListItem><strong>AI-Enhanced Imaging Quality: </strong> Our AI algorithms improve the clarity and detail of imaging results. 
              By analyzing large datasets, AI can identify patterns and anomalies that may be missed by the human eye, 
              resulting in more accurate diagnoses.</ListItem>

              <ListItem><strong>Streamlined Workflow with AI Integration: </strong> Our radiology processes are optimized 
              through AI, which automates routine tasks such as image analysis and report generation. This allows our 
              radiologists to focus on interpreting complex cases and enhancing patient care.</ListItem>

              <ListItem><strong>Predictive Analytics for Early Detection: </strong> By leveraging historical data and 
              advanced machine learning techniques, our AI systems can predict potential health issues before they 
              become evident. This proactive approach enables early intervention and better patient management.</ListItem>

        </UnorderedList>
        </Box> 

        <Text fontWeight={700} fontSize={'xl'} mb={2} mt={5}>Comprehensive Imaging Technology</Text>

        <Text>Our state-of-the-art imaging technology includes:</Text>

        <Box mt={2}>
        {/* <Heading size="sm">AREAS OF CONSULTATION</Heading> */}
        <UnorderedList>
            <ListItem><Text fontWeight={600}>Digital X-rays </Text> Provide rapid and accurate imaging for diagnosing 
            various conditions, with reduced radiation exposure.</ListItem>

            <ListItem><Text fontWeight={600}>AI-Powered CT Scanners </Text>  Generate detailed cross-sectional images of 
            the body, enhanced by AI for improved detection and evaluation of diseases.</ListItem>

            <ListItem><Text fontWeight={600}>AI-Enhanced MRI Scanners </Text> Utilize powerful magnetic fields and radio 
            waves to produce comprehensive images of internal structures, with AI improving image analysis for 
            conditions such as tumors and neurological disorders.</ListItem>

            <ListItem><Text fontWeight={600}>Ultrasound Technology </Text> Create real-time images using sound waves, 
            helping to  diagnose conditions affecting the abdomen, pelvis, and vascular system.</ListItem>

            <ListItem><Text fontWeight={600}>Interventional Radiology </Text> Offers targeted therapies through procedures 
            such as angiography and biopsies, minimizing the need for invasive surgery.</ListItem>

            <ListItem><Text fontWeight={600}>3D Mammography </Text> Employs advanced imaging to provide a three-dimensional 
            view of breast tissue, improving detection accuracy and visualization of abnormalities.</ListItem>

            <ListItem><Text fontWeight={600}>DEXA Scans </Text> Measures bone density to assess osteoporosis risk, 
            providing critical information for monitoring bone health.</ListItem>

            <ListItem><Text fontWeight={600}>CT Angiography </Text> Non-invasive imaging technique that visualizes blood 
            vessels, aiding in the diagnosis of conditions such as arterial blockages or aneurysms.</ListItem>


        </UnorderedList>
        </Box> 

        <Text fontWeight={700} fontSize={'xl'} mb={2} mt={5}>The Advantages of an AI-Powered Radiology Centre</Text>

          <Box mt={2}>
        {/* <Heading size="sm">AREAS OF CONSULTATION</Heading> */}
        <UnorderedList>
            <ListItem> <Text as="span" fontWeight="500">Enhanced Diagnostic Accuracy: </Text> AI improves the precision 
            of image interpretation, reducing the likelihood of missed diagnoses and false positives.</ListItem>

            <ListItem> <Text as="span" fontWeight="500">Faster Turnaround Times: </Text> Automated processes expedite the 
            analysis and reporting of imaging results, leading to quicker clinical decisions and treatment plans.</ListItem>

            <ListItem> <Text as="span" fontWeight="500">Patient-Centric Care: </Text> Our focus on AI technology allows us 
            to offer personalized imaging approaches tailored to individual patient needs, ensuring a comprehensive 
            understanding of their health.</ListItem>

            <ListItem> <Text as="span" fontWeight="500">Data-Driven Insights: </Text> Our AI systems utilize vast amounts 
            of data to continuously learn and adapt, ensuring that our diagnostic practices remain cutting-edge and 
            evidence-based.</ListItem>

            <ListItem> <Text as="span" fontWeight="500">Holistic Patient Management: </Text> By integrating AI into our 
            radiology services, we enhance collaboration between various medical departments, leading to a cohesive 
            approach to patient care.</ListItem>

            <ListItem> <Text as="span" fontWeight="500">Continuous Quality Improvement: </Text> Our AI technologies 
            facilitate ongoing evaluation of imaging protocols and outcomes, driving improvements in service quality 
            and patient satisfaction.</ListItem>


        </UnorderedList>
        </Box> 

        <Text mt={'5'}>
        At Nawaloka Hospitals PLC, we are transforming the landscape of radiology in Sri Lanka through our AI-powered 
        centre. By combining advanced imaging technologies with AI-driven insights, we ensure the highest standards of 
        care, accuracy, and efficiency. Our commitment to innovation positions us as a leader in healthcare, dedicated 
        to improving patient outcomes and setting new benchmarks in diagnostic excellence.
        </Text>

        </Text>
        </Box>    

        {/* <Box mt={'10'}>
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What factors can affect fertility and reproductive health?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Several factors can influence fertility and reproductive health, including age, underlying 
      medical conditions, lifestyle choices, hormonal imbalances, genetic factors, and 
      environmental factors. It's essential to understand these factors and seek appropriate 
      care to optimize fertility potential.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        When should couples seek fertility evaluation and treatment?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Couples should consider seeking fertility evaluation and treatment if they 
      have been actively trying to conceive for one year without success (or six months 
      if the woman is over 35 years old). However, individuals with known fertility issues 
      or medical conditions should consult a fertility specialist before attempting to conceive.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the common fertility tests and procedures available?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Fertility tests and procedures may include semen analysis, hormonal evaluations, ultrasound 
      imaging, hysterosalpingography (HSG) to assess the fallopian tubes, ovarian reserve testing, 
      and genetic screening. Additionally, assisted reproductive technologies (ART) like in vitro 
      fertilization (IVF) and intrauterine insemination (IUI) may be recommended based on 
      individual circumstances.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Are there any lifestyle changes that can enhance fertility?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Yes, certain lifestyle changes can positively impact fertility. Maintaining healthy body 
      weight, adopting a balanced diet, reducing stress levels, avoiding tobacco and excessive 
      alcohol consumption, exercising regularly, and managing chronic conditions can contribute 
      to improved reproductive health.
      </Text>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>     */}
            
      </Flex>
      {/* <Box p={2} mt={'-10'}>
  <Heading size={'md'} mb={5}>Related Media</Heading>
  <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6} pr={6} pl={6}>
        {videos.map((video) => (
          <GridItem key={video.id}>
            <Box
              as="iframe"
              width="100%"
              height="250px"
              src={`https://www.youtube.com/embed/${video.url.split('=')[1]}`}
              title={video.title}
              allowFullScreen
              borderRadius="md"
              boxShadow="md"
            />
            <Heading as="h3" size="xs" mt={4}>
              {video.title}
            </Heading>
          </GridItem>
        ))}
      </Grid>
  </Box> */}
      </Grid>
      <Flex flexDirection={'column'} alignItems="left" p={4}>

      <Box mt="15px" mb={10}>
        <Image
          src={require('../../img/radiology/radio1.jpeg')}
          alt="Radiology & Imaging"
          // objectFit="cover"
          width="100%"
          // height="200px"
        />
      </Box>

        <LatestItems1/>
      </Flex>
      </Grid>
      <FooterItem/>
    </div>
  )
}

export default Radiology
