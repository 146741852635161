import {
  AbsoluteCenter,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  ListItem,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import cardImg from "../../img/card-img.jpg";
import { useNavigate } from "react-router-dom";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { useLocation } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";

function WebxLink() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [total, setTotal] = useState(0.0);
  const [items, setItems] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    loadItem();
  }, []);

  const loadItem = () => {
    console.log(sessionStorage.getItem("sessionId"));
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            setItems(object.data.data);
            setTotal(object.data.sub_total);
            //console.log(object.data);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const proceedPayment = () => {
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.checkout;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          mobile: mobile,
          email: email,
          name: name,
          address: address,
          total: total,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            // window.location.reload();
            console.log(object);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
    navigate("/healthchecks/success");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submission logic here
    // You can send the form data to a backend API or perform any other required action
    console.log("Form submitted:", {
      //   name,
      //   nic,
      //   phoneNumber,
      //   date,
      //   email,
      //   time,
    });
  };

  const [selectedOption, setSelectedOption] = useState('');

  // Function to handle change in select option
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

   // Function to render different forms based on selected option
   const renderForm = () => {
    switch (selectedOption) {
      case 'In-patient payment':
        return <Box mt="20px">
           <FormControl mb="10px">
                <FormLabel>Patient BHT</FormLabel>
                <Input
                  type="text"
                 value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Enter Patient BHT"
                  onChange={(e) => setMobile(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </FormControl>

              
              <FormControl mb="10px" mt="20px">
                <Button colorScheme="teal" type="submit">
                      Continue
                    </Button>
              </FormControl>


        </Box>;
      case 'Other payment':
        return <Box mt="20px">Coming Soon...</Box>;
      default:
        return null;
    }
  };

  const addMedDelivery = () => {
    const url = 'https://tinyurl.com/yyw3mukz'; 
    window.open(url, '_blank');
  };



  return (
    <div>
    <Header/>
        {/* <Heading mt={10} ml={10}>Doorstep Services</Heading>
        <HeaderPic/> */}

        <Box
            backgroundImage={require('../../img/DoorstepStripNew.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
    // justifyContent="center"
        >
            <Text
            fontSize="4xl"
            fontWeight="bold"
            ml="40px"
            color="white"
            textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
            Payment Portal
            </Text>
        </Box>

        <Grid templateColumns={['1fr', '1fr', '2fr 1fr', '2fr 1fr']} flexDirection={['row', 'column']} pl={['5','5']} pr={['5','5']}>
    <Box width={'100%'} p={['5','10']}>
    <Box fontSize="18px" textAlign={'justify'}>
    Simplify your payment process, where you can effortlessly settle any outstanding bills from the 
    comfort. Enjoy peace of mind knowing your transactions are encrypted and protected. 
    Take control of your finances and streamline your healthcare experience with just a few clicks. Experience the 
    ease and efficiency of managing your payments online with Nawaloka Hospitals PLC.
    </Box>


    
    </Box>

    <Box width={'100%'} padding={['2','2']} mt={['2','0','5']} order={[1, 2]}>
        
          <Card width="100%" p="10px" boxShadow={'2xl'} mt="10px" mb="10px" backgroundColor="">
          {/* <CardHeader><Heading size="lg" color="black">Medicine Delivery</Heading></CardHeader> */}
          <Box width="100%">
            
          <Box position='relative' padding='5'>
            <Divider borderColor="blackAlpha.700" />
            <AbsoluteCenter bg='white' px='4'>
            <Text fontSize="24px" fontWeight="bold" textAlign="center" color="black">
            Scan the QR
            </Text>
            </AbsoluteCenter>
            </Box>
            <Flex
                w={'250px'}
                h={'250px'}
                m="30px"
                // ml="50px"
                // mt="10px"
                position="relative"
                // overflow="hidden"
                backgroundImage={require('../../img/WebXQR.png')}
                backgroundSize="cover"
                // borderRadius="10px"
                mx="auto"
                >
            </Flex>
            <Box position='relative' padding='5'>
            <Divider borderColor="blackAlpha.700" />
            <AbsoluteCenter bg='white' px='4'>
            <Text fontSize="24px" fontWeight="bold" textAlign="center" color="black">
            Or
            </Text>
            </AbsoluteCenter>
            </Box>
            <Box display='flex' alignItems='center' justifyContent='center' p="25px">
                <Button leftIcon={<EditIcon />} colorScheme='teal' variant='solid' onClick={() => addMedDelivery()}>
                    Click Here
                </Button>
            </Box>
          </Box>
        </Card>

        {/* <Card width="100%" p="10px" boxShadow={'2xl'} mt="20px" mb="10px" backgroundColor="">
          <CardHeader><Heading size="md" color="black">Contact Details</Heading></CardHeader>
            <VStack spacing={0} mb="10px" alignItems="center">
                <Text fontSize="lg" fontWeight={'semibold'} color="black" whiteSpace={'pre-line'}>
                Direct Contact: {'\n'}
                </Text>
                <Text fontSize="lg" textAlign="center" fontWeight={'semibold'} color="black" whiteSpace={'pre-line'}>
                Email: 
                </Text>
            </VStack>   
        </Card> */}
    </Box>
  </Grid>

    <FooterItem/>

</div>
  );
}

export default WebxLink;
