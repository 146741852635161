import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import cardImg from "../../img/card-img.jpg";
import { useNavigate } from "react-router-dom";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { isEmpty, isSet } from "lodash";
// import { useLocation, withRouter } from "react-router-dom";

function Checkout() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [total, setTotal] = useState(0.0);
  const [items, setItems] = useState([]);

  useEffect(() => {
    loadItem();
  }, []);

  const loadItem = () => {
    console.log(sessionStorage.getItem("sessionId"));
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            setItems(object.data.data);
            setTotal(object.data.sub_total);
            //console.log(object.data);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const proceedPayment = () => {
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.checkout;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          mobile: mobile,
          email: email,
          fname: fname,
          lname: lname,
          address: address,
          total: total,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {

            window.location.href = `https://test.nawalokacare.com/cart_payment.php?order_id=${object.data.invoice_ref_no}&phone=${object.data.mobile}&email=${object.data.email}&fname=${object.data.fname}&lname=${object.data.lname}&address=${object.data.address}&amount=${object.data.charge_total}&packages_names=${object.data.packages_names}`;


          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submission logic here
    // You can send the form data to a backend API or perform any other required action
    console.log("Form submitted:", {
      //   name,
      //   nic,
      //   phoneNumber,
      //   date,
      //   email,
      //   time,
    });
  };

  const paymentCanceled = () => {
    navigate("/home");
  };

  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/WebsiteStrip1.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Checkout
        </Text>
      </Box>

      <Box p={4} bg="gray.100" borderRadius="md" shadow="md">
        <Grid gap={4} templateColumns={["1fr", "1fr", "2fr 2fr"]}>
          <Card>
            <Heading
              mb="10px"
              pl="20px"
              pt="10px"
              fontSize="28px"
              color="#F8D107"
            >
              Billing Details
            </Heading>
            <Box mx="auto" width="100%" pr={6} pl={6}>
              {/* <form onSubmit={handleSubmit}> */}

              <FormControl mb="10px">
                <FormLabel>First name</FormLabel>
                <Flex>
                  <Input
                    mt="5px"
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Flex>
              </FormControl>
              <FormControl mb="10px">
                <FormLabel>Last name</FormLabel>
                <Flex>
                  <Input
                    mt="5px"
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Flex>
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>Address</FormLabel>
                <Textarea
                  // value={value}
                  // onChange={handleInputChange}
                  placeholder="Address"
                  size="sm"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>Mobile Number</FormLabel>
                <Input
                  type="tel"
                  //   value={phoneNumber}
                  placeholder="Phone Number(0XXXXXXXXX)"
                  onChange={(e) => setMobile(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Please enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  //   value={email}
                  //   onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormControl>
              {/* </form> */}
            </Box>
          </Card>

          <Card>
            {items.map((item) => (
              <Box
                key={item.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                bg="white"
                p={4}
                borderRadius="md"
              >
                <Box display="flex" alignItems="center">
                  <Image
                    src={surgeryIcon2}
                    alt="Health Package"
                    boxSize="100px"
                    objectFit="contain"
                    mr={4}
                  />

                  <VStack align="start" spacing={1}>
                    <Text fontSize="xl">{item.name}</Text>
                    <Text color="gray.500" fontSize="sm">
                      Quantity: {item.qty}
                    </Text>
                  </VStack>
                </Box>
                <Text fontWeight="bold">Rs. {item.price}</Text>
              </Box>
            ))}
            <Divider orientation="horizontal" />
            <Box bg="white" p={4} borderRadius="md">
              <Flex flexDirection="column" justify="flex-end">
                <Text fontWeight="bold" mb={2} ml="auto">
                  Total: Rs. {total.toFixed(2)}
                </Text>
                {/* <Box boxSize="xs" width="100%" height="100%">
                  <Image src={cardImg} alt="Card-Img" width="100%" />
                </Box> */}
                <Button colorScheme="teal" onClick={() => proceedPayment()}>
                  Proceed Payment
                </Button>
              </Flex>
            </Box>
          </Card>
        </Grid>
      </Box>
      <FooterItem />
    </Box>
  );
}

export default Checkout;
