/* eslint-disable import/no-anonymous-default-export */
import moment from "moment";

// import { DEV_LOGS } from "../constants/EnvConstants";
import { Methods } from "../constants";
import { JsonToForm, JsonToParams } from "../utils";
import {
  ECHANNELLING,
  ApiEndpoints,
  DEV_LOGS,
} from "../constants/ApiCunstants";

let access_token,
  expiry_time = moment();

/**
 * @param {String} URL
 * @param {String} method
 * @param {Object} headers
 * @param {Object} body
 *
 * @returns {Promise}
 */
export default function* (URL, method, params = null, body) {
  return yield new Promise(async (resolve, reject) => {
   

    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    };

    // customBody = JsonToForm(customBody);

    fetchService(URL, params, method, headers, body)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
}

function fetchService(endPoint, params, method, headers, body) {
  let URL = ECHANNELLING.BASE_URL + endPoint;

  const customParams = params ? JsonToParams(params) : null;
  if (customParams) {
    URL = `${URL}?${customParams}`;
  }

  return new Promise((resolve, reject) => {
    // DEV_LOGS &&
    //   console.log(
    //     "\u001b[1;35m\n****** DOC990 REQUEST ******\n",
    //     "\nURL    :",
    //     URL,
    //     "\nMETHOD :",
    //     method,
    //     "\nHEADERS:",
    //     JSON.stringify(headers, null, "  "),
    //     "\nBODY   :",
    //     body ? JSON.stringify(body, null, "  ") : undefined
    //   );

    if (body) {
      body = JsonToForm(body);
    }

    fetch(URL, {
      method,
      headers,
      body,
    })
      .then((response) => response.json())
      .then((object) => {
        if (object.response) {
          // DEV_LOGS &&
          //   console.log(
          //     `\n\u001b[1;32m****** DOC990 RESPONSE ******\n\n${JSON.stringify(
          //       object,
          //       null,
          //       "  "
          //     )}`
          //   );

          resolve(object);
        } else {
          // DEV_LOGS &&
          //   console.log(
          //     `\n\n\x1b[31m****** ERROR ******\n\n${JSON.stringify(
          //       object,
          //       null,
          //       "  "
          //     )}`
          //   );

          reject(object);
        }
      })
      .catch((error) => reject(error));
  });
}
