import { Box, Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Image, Input, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, StackDivider, Text, Textarea, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { useEffect, useRef } from "react";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { useForm } from 'react-hook-form';
import { MdCheckCircle, MdStar } from 'react-icons/md';

function BloodBank() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [name, setname] = useState(null);
  const [email, setEmail] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [requirement, setRequirement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const gridItems = [
    // {
    //   imageSrc: 'https://media.istockphoto.com/id/1400492047/photo/therapist-putting-physio-tape-on-young-woman.webp?b=1&s=170667a&w=0&k=20&c=uf6UJtuQmlCBMWfA7YKPmAZtYTiCroLC2h_3QPndLrM=',
    //   title: 'Pain management',
    //   description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    // },
    // {
    //   imageSrc: 'https://media.istockphoto.com/id/1199908661/photo/physiotherapist-treatment-patient-she-holding-patients-hand-shoulder-joint-treatment.jpg?s=612x612&w=0&k=20&c=yghgsRCfhifTxzIS8UqlHIxpyyHDUNXkfqwQHABDRuY=',
    //   title: 'Physiotherapy Rehabilitation',
    //   description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    // },
    // {
    //   imageSrc: 'https://img.freepik.com/free-photo/medical-assistant-helping-patient-with-physiotherapy-exercises_23-2149071459.jpg',
    //   title: 'Therapeutic Treatments',
    //   description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    // },
    // {
    //   imageSrc: 'https://media.istockphoto.com/id/1384499192/photo/young-physiotherapist-exercising-with-senior-patient-in-a-physic-room.webp?b=1&s=170667a&w=0&k=20&c=_ITAfaHEN2lfWe3rvUKFTUDyBT-in4NkoJhVw606uro=',
    //   title: 'Antenatal and Postnatal Physiotherapy care',
    //   description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    // },
  ];

  const serviceItems = [
    {},
  ];
  const onSubmit = async (data) => {
    setLoading(true);

    try {

      sendmail();

      reset(); // Clear form fields
    } catch (error) {
      // Display error toast
      // toast({
      //   title: 'Error',
      //   description: 'An error occurred. Please try again later.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    }

    setLoading(false);
  };

  const sendmail = () => {
    // setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {

      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.sendmailPhysio;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          telephone: telephone,
          requirement: requirement,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {

            console.log(object);
            if (!object.error) {
              toast({
                title: 'Success',
                description: object.msg,
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
             // window.location.reload();
            } else {
              toast({
                title: 'Error',
                description: object.msg,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      //setIsLoading(false);
      setTelephone('');
      setEmail('');
      setname('');
      setRequirement('');
      

    }
  };

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  return (
    <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Blood Bank
        </Text>
      </Box>

      {/* <Box mt={5} pl={['10','20']} pr={['10','20']} textAlign={'justify'}>
        <Text fontSize={'lg'}>
        Nawaloka Blood Bank is the heartbeat of Nawaloka Hospitals, pulsating with a commitment to saving lives. 
        As a vital arm of the hospital, the blood bank plays a crucial role in ensuring a steady and safe supply 
        of blood for patients in need. Manned by dedicated professionals, it operates with precision and care, 
        adhering to the highest standards of safety and hygiene. At Nawaloka Blood Bank, we understand the significance 
        of each drop of blood, recognizing it as a lifeline that connects donors to recipients in moments of critical need. 
        Our mission is to foster a culture of voluntary blood donation, promoting community involvement and solidarity. 
        With state-of-the-art facilities and a compassionate team, Nawaloka Blood Bank stands as a beacon of hope, 
        embodying the hospital's unwavering commitment to healthcare excellence.
        </Text>
      </Box> */}

      <Grid templateColumns={['1fr', '1fr', '3fr 1fr', '3fr 1fr']} flexDirection={['row', 'column']} pl={['5','5']} pr={['5','5']}>
        <Box width={'100%'} p={['5','8']} order={[2, 1]}>
        <Text fontSize={'lg'} textAlign={'justify'}>
        Nawaloka Blood Bank is the heartbeat of Nawaloka Hospitals, pulsating with a commitment to saving lives. 
        As a vital arm of the hospital, the blood bank plays a crucial role in ensuring a steady and safe supply 
        of blood for patients in need. Manned by dedicated professionals, it operates with precision and care, 
        adhering to the highest standards of safety and hygiene. At Nawaloka Blood Bank, we understand the significance 
        of each drop of blood, recognizing it as a lifeline that connects donors to recipients in moments of critical need. 
        Our mission is to foster a culture of voluntary blood donation, promoting community involvement and solidarity. 
        With state-of-the-art facilities and a compassionate team, Nawaloka Blood Bank stands as a beacon of hope, 
        embodying the hospital's unwavering commitment to healthcare excellence.
        </Text>
        </Box>
        <Box width={'100%'} padding={['2','2']} mt={['2','0','10']} order={[1, 2]}>
            
            {/* <Card width="100%" p="10px" boxShadow={'2xl'} mt="5px" mb="10px" border={'1px'}>
              
                <Heading size="md" mb={2}>Location</Heading>
                <VStack spacing={0} alignItems="center">
                    <Text fontSize="lg" fontWeight={'semibold'} whiteSpace={'pre-line'}  mb={2}>
                    Floor and Location Details{'\n'}
                    </Text>
                </VStack> 

                <Heading size={'md'} mb={2}>Contacts</Heading>
                <VStack spacing={0} alignItems="center">
                    <Text fontSize="lg" fontWeight={'semibold'} whiteSpace={'pre-line'}>
                    Direct Contact: Blood Bank Number{'\n'}
                    </Text>
                    <Text fontSize="lg" textAlign="center" fontWeight={'semibold'} whiteSpace={'pre-line'}>
                    Email: Blood bank Email
                    </Text>
                </VStack>   
            </Card> */}

            <img src={require('../../img/blood.jpg')}/>


{/* <Card>
  <CardBody>
    <Stack divider={<StackDivider />} spacing='4'>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Location
        </Heading>
        <Text pt='2' fontSize='sm'>
          View a summary of all your clients over the last month.
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
        Service Hours
        </Heading>
        <Text pt='2' fontSize='sm'>
          Check out the overview of your clients.
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Contacts
        </Heading>
        <Text pt='2' fontSize='sm'>
          See a detailed analysis of all your business clients.
        </Text>
      </Box>
    </Stack>
  </CardBody>
</Card> */}

        </Box>
      </Grid>
      
      <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
      
      <Grid
      templateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
      gap={6}
      mt={6}
      mb={5}
      pl={5}
      pr={5}
      width="80%"
      textAlign="center"
    >
      {gridItems.map((item, index) => (
        <GridItem key={index} w="100%" h="280" position="relative">
          <img src={item.imageSrc} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%' }} />
          <Box
            // onClick={() => onOpen(index)}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            background="rgba(249, 105, 14, 1.0)"
            opacity="0.5"
            _hover={{
              background: 'rgba(244, 105, 14, 0.3)',
              cursor: 'pointer',
            }}
          ></Box>
          <Box
            // onClick={() => onOpen(index)}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="#ffffff"
            fontWeight="bold"
            fontSize="20px"
            textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)"
            _hover={{
              cursor: 'pointer',
            }}
          >
            {item.title}
          </Box>
        </GridItem>
      ))}

      {selectedModalIndex !== null && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{gridItems[selectedModalIndex].title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{gridItems[selectedModalIndex].description}</ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              {/* <Button variant="ghost">Secondary Action</Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Grid>

      </Box>

      <Grid templateColumns={['1fr', '1fr', '2fr 1fr', '2fr 1fr']} flexDirection={['row', 'column']} pl={['5','10']} pr={['5','10']}>
        <Box width={'100%'} p={['5','8']} order={[2, 1]}>
           
        </Box>
        <Box width={'100%'} padding={['2','2']} mt={['2','0','10']} order={[1, 2]}>
            
        </Box>
      </Grid>

        <FooterItem/>
        </Box>
    </div>
  )
}

export default BloodBank
