import { Box, Button, Card, CardHeader, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Image, Input, Link, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, Textarea, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../components/Header'
import FooterItem from '../components/FooterItem'
import { useEffect, useRef } from "react";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import { useForm } from 'react-hook-form';
import { MdCheckCircle, MdStar } from 'react-icons/md';
import { Link as RouterLink } from "react-router-dom";

function AdmResev() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [name, setname] = useState(null);
  const [dob, setDob] = useState(null);
  const [email, setEmail] = useState(null);
  const [docname, setDocName] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [note, setNote] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {

      sendmail();

      reset(); // Clear form fields
    } catch (error) {
      // Display error toast
      // toast({
      //   title: 'Error',
      //   description: 'An error occurred. Please try again later.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    }

    setLoading(false);
  };

  const sendmail = () => {
    // setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {

      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.sendmailAdmResev;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          name: name,
          dob: dob,
          email: email,
          telephone: telephone,
          docname: docname,
          note: note,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {

            console.log(object);
            if (!object.error) {
              toast({
                title: 'Success',
                description: object.msg,
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
             // window.location.reload();
            } else {
              toast({
                title: 'Error',
                description: object.msg,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      //setIsLoading(false);
      setTelephone('');
      setEmail('');
      setname('');
      setDob('');
      setDocName('');
      setNote('');
      

    }
  };

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  return (
    <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Admission Reservation
        </Text>
      </Box>

      <Grid templateColumns={['1fr', '1fr', '2fr 1fr', '2fr 1fr']} flexDirection={['row', 'column']} pl={['5','5']} pr={['5','5']}>
        <Box width={'100%'} p={['5','8']}>
        Welcome to Nawaloka Hospital Colombo, where your health is our priority. We understand that seeking medical care 
        can be a daunting experience, which is why we are committed to providing a seamless and hassle-free admission 
        process for our patients.
        <Box as="br" /><Box as="br" />
        Our admission reservation system is designed to ensure that you receive the best care possible, with minimal 
        waiting times and a personalized approach to your healthcare needs. Whether you require a routine procedure, 
        elective surgery, or emergency treatment, our dedicated team is here to assist you every step of the way.
        <Box as="br" /><Box as="br" />
        To secure your admission at Nawaloka Hospital, you can easily make a reservation through our website. Simply 
        fill out the online form, providing essential details such as your personal information, medical history, and 
        preferred date of admission. Our admissions team will promptly review your request and get in touch with you 
        to confirm the reservation and discuss any specific requirements you may have.
        <Box as="br" /><Box as="br" />
        At Nawaloka Hospital, we take pride in our state-of-the-art facilities, cutting-edge technology, and a team 
        of highly skilled medical professionals who are committed to delivering exceptional care. By making a 
        reservation, you can ensure that your healthcare journey with us begins smoothly, allowing us to focus on your 
        well-being and comfort from the moment you step through our doors.
        <Box as="br" /><Box as="br" />
        Experience compassionate and world-class healthcare with ease by reserving your admission at Nawaloka Hospital 
        Colombo. Your health is in safe hands with us.
        <Box as="br" /><Box as="br" /><Box as="br" />

        <Link as={RouterLink} to="/aboutus/facilities">
            <Button backgroundColor="#A1C349" _hover={{
                      bg: '#586e1f',
                      color: 'white',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }} 
                    fontSize="xl" px={8} borderRadius={20}>
              Explore
            </Button>
          </Link>
            
        </Box>

        <Box width={'100%'} padding={['2','2']} mt={['2','0','5']} order={[1, 2]}>
            
              <Card width="100%" p="10px" boxShadow={'2xl'} mt="10px" mb="10px" backgroundColor="#A1C349">
              <CardHeader><Heading size="lg" color="white">Admission Reservation</Heading></CardHeader>
              <Box width="90%" mx="auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl id="name" mb={4} isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input required type="text" {...register('name')} bg="white" value={name} onChange={(event) => setname(event.target.value)}
                    />
                  </FormControl>

                  <FormControl id="dob" mb={4} isRequired>
                    <FormLabel>Date of Birth</FormLabel>
                    <Input required value={dob} {...register('dob')} bg="white" onChange={(event) => setDob(event.target.value)} type="date" />
                  </FormControl>

                  <FormControl id="telephone" mb={4} isRequired>
                    <FormLabel>Telephone</FormLabel>
                    <Input required value={telephone} {...register('telephone')} bg="white" onChange={(event) => setTelephone(event.target.value)} type="number" />
                  </FormControl>

                  <FormControl id="email" mb={4} isRequired>
                    <FormLabel>E-mail</FormLabel>
                    <Input required value={email} {...register('email')} bg="white" onChange={(event) => setEmail(event.target.value)} type="email" />
                  </FormControl>

                  <FormControl id="docname" mb={4} isRequired>
                    <FormLabel>Consultant's Name</FormLabel>
                    <Input required value={docname} {...register('docname')} bg="white" onChange={(event) => setDocName(event.target.value)} type="text" />
                  </FormControl>

                  <FormControl id="note" mb={4}>
                    <FormLabel>Note</FormLabel>
                    <Textarea required {...register('note')} bg="white" defaultValue={note} onChange={(event) => setNote(event.target.value)} resize="vertical" />
                  </FormControl>

                  {/* <ReCAPTCHA
          sitekey="6LdQYIkmAAAAAKqMPKyIP10iPgxSQdHcUfWEM613"
          // size="invisible"
          ref={recaptchaRef}
        /> */}

                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="Submitting"
                    bg="#2A3C24"
                    color="white"
                    mt={4}
                    mb={4}
                    _hover={{
                      bg: '#243010',
                      color: 'white',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}

                  >
                    Reserve
                  </Button>
                </form>
              </Box>
            </Card>

            <Card width="100%" p="10px" boxShadow={'2xl'} mt="20px" mb="10px" backgroundColor="#A1C349">
              <CardHeader><Heading size="md" color="white">Contact Details</Heading></CardHeader>
                <VStack spacing={0} mb="10px" alignItems="center">
                    <Text fontSize="lg" fontWeight={'semibold'} color="whiteAlpha.800" whiteSpace={'pre-line'}>
                    Direct Contact: 0773 250 663{'\n'}
                    </Text>
                    <Text fontSize="lg" textAlign="center" fontWeight={'semibold'} color="whiteAlpha.800" whiteSpace={'pre-line'}>
                    Email: admission1@nawaloka.com
                    </Text>
                </VStack>   
            </Card>
        </Box>
      </Grid>

        <FooterItem/>
        </Box>
    </div>
  )
}

export default AdmResev
