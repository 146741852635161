import { AspectRatio, Box, Button, ButtonGroup, Card, Center, Flex, Grid, Heading, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { useEffect, useRef } from "react";
import { motion } from 'framer-motion'
import { FaPlay, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { LazyLoadComponent } from 'react-lazyload';

function Consultants() {

  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [isOpen, setIsOpen] = useState(false);
  // const { isOpen, onOpen, onClose } = useDisclosure()


  const onOpen = (doctor) => {
    setSelectedDoctor(doctor);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };


  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const viewDocProfile = (doc_id)=>{
    sessionStorage.setItem('doc_id',doc_id);
    navigate("/doctorprofile");
  }

  // useEffect(() => {
  //   const handleLoad = () => {
  //     setIsLoading(false);
  //   };

  //   window.addEventListener('load', handleLoad);

  //   return () => {
  //     window.removeEventListener('load', handleLoad);
  //   };
  // }, []);

  // const videoRef = useRef(null);
  // const thumbnailRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // const handleClick = () => {
  //   setIsPlaying(true);
  // };

  // useEffect(() => {
  //   if (isPlaying) {
  //     videoRef.current.play();
  //     thumbnailRef.current.style.display = "none";
  //   }
  // }, [isPlaying]);

  const doctors = [
    {
      name: 'Dr. Maiya Gunasekara',
      specialty: 'General Surgeon / Gastroenterology Laparoscopic Surgeon-Endoscopy, Gastroscopy and Colonoscopy',
      imageSrc: require('../../img/doctorImages/dr-maiya.png'),
      docId: 'D1301'
    },
    {
      name: 'Dr. Punsith Gunawardena',
      specialty: 'Consultant Neuro Surgeon',
      imageSrc: require('../../img/doctorImages/dr-punsith.jpg'),
      docId: 'D1866'
    },
    {
      name: 'Dr. M.T.D Lakshan',
      specialty: 'Consultant ENT Surgeon',
      imageSrc: require('../../img/doctorImages/dr-lakshan.jpg'),
      docId: 'D1198'
    },
    
    {
      name: 'Dr. Chandima De Mel',
      specialty: 'Consultant Physician',
      imageSrc: require('../../img/doctorImages/dr-chandima.jpeg'),
      docId: 'D0307'
    },
    {
      name: 'Dr. Riaz Moujood',
      specialty: 'Consultant Chest Specialist',
      imageSrc: require('../../img/doctorImages/dr-riyaz.jpg'),
      docId: 'D6826'
    },
    {
        name: 'Dr. R.K.Pai',
        specialty: 'Consultant Cardiothoracic Surgeon',
        imageSrc: require('../../img/doctorImages/dr-pai.png'),
        docId: 'D11227'
    },
    {
        name: 'Dr. Duminda Pathirana',
        specialty: 'Consultant Paediatrician',
        imageSrc: require('../../img/doctorImages/dr-duminda.PNG'),
        docId: 'D0586'
    },
    {
      name: 'Dr. Prakash Priyadarshan',
      specialty: 'Consultant Cardiologist',
      imageSrc: require('../../img/doctorImages/dr-prakash.jpg'),
      docId: 'D2014'
    },
    {
      name: 'Dr. Usha Samarasinghe',
      specialty: 'Consultant Radiologist',
      imageSrc: require('../../img/doctorImages/dr-usha.jpg'),
      docId: 'D12250'
    },
   
      {
        name: 'Dr. Sandeep K. Sharma',
        specialty: 'Consultant Cardiac Anaesthetist and Intensivist',
        imageSrc: require('../../img/doctorImages/dr-sharma.png'),
        docId: ''
      },
      {
        name: 'Prof. Arjuna De Silva',
        specialty: 'Consultant Physician',
        imageSrc: require('../../img/doctorImages/dr-arjuna.jpeg'),
        docId: 'D0510'
      },
      {
        name: 'Dr. Hemant Digambar Waikar',
        specialty: 'Consultant Cardiac Anaesthetist',
        imageSrc: require('../../img/doctorImages/dr-wykar.jpeg'),
        docId: ''
      },
      {
        name: 'Dr. Harindu Wijesinghe',
        specialty: 'Consultant in Rheumatology, Rehabilitation and Sports Medicine',
        imageSrc: require('../../img/doctorImages/dr-harindu.png'),
        docId: 'D1378'
      },
    // Add more doctor objects as needed
  ];

  return (

    <Box>
        <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../../img/pagecovers/consultants-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Resident Consultants
        </Text>
      </Box>
      {/* <Grid flexDirection={['row','column']} 
        width="100%" 
        templateColumns={["1fr", "2fr", "1fr 1fr 1fr"]}
        maxW="1200px"
          gap="50px"
          p={4}
          mt={2}
          mx="auto">
            
            
        </Grid> */}

        <Grid 
        templateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(5, 1fr)']}
        gap={6} 
        mt={5} 
        maxW="1300px"
        p={4}
        mx="auto">
            
            {doctors.map((doctor, index) => (
                <Box key={index} p={4} borderWidth="1px" borderRadius="lg" boxShadow="0 4px 16px rgba(0, 0, 0, 0.6)"
                // onClick={()=>viewDocProfile(doctor.docId)} cursor={'pointer'}
                >
                    <Flex direction="column" align="center" textAlign={'center'} justify="center" height="100%">
                    <Image src={doctor.imageSrc} alt={doctor.name} mb={4} width="150px" height="150px" borderRadius="50%" objectFit="cover" />
                    <Text fontWeight="bold">{doctor.name}</Text>
                    <Text fontSize={'xs'}>{doctor.specialty}</Text>
                    <Box mx={'auto'} alignItems={'flex-end'}>
                    <ButtonGroup size={'sm'} mt="10px">
                      {/* <Button 
                      bgColor="purple.500" 
                      color="white" 
                      borderRadius="2xl"
                      onClick={() => onOpen(doctor)}
                      _hover={{
                        background: 'purple.700',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                      }}
                      >
                        <IconButton
                          icon={<FaPlay />} // Replace FaPlay with the desired icon component
                          color={'white'}
                          aria-label="Watch"
                          background={'none'}
                          _hover={{
                            background: 'none',
                          }}
                        />
                        Watch</Button> */}

                          
                    <>
                                            
                        <Modal isOpen={isOpen} onClose={onClose}>
                          <ModalOverlay />
                          <ModalContent width="865px" maxWidth={{ base: '100%', lg: '80%' }}>
                            <ModalHeader lineHeight={'1'}>
                              {selectedDoctor?.name} <br/>
                              <small>{selectedDoctor?.specialty}</small> 
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                            <AspectRatio maxW='860px' ratio={16/9}>
                            <iframe
                              title='naruto'
                              src='https://www.youtube.com/embed/WgR1m3JqCAY'
                              allowFullScreen
                              loading='lazy'
                            />
                            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/WgR1m3JqCAY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                            </AspectRatio>
                            </ModalBody>

                            <ModalFooter>
                              {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                              </Button> */}
                              {/* <Button variant='ghost'>Secondary Action</Button> */}
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                        </>
                        
                      <Button 
                      bgColor="yellow.500" 
                      color="white" 
                      borderRadius="2xl"
                      onClick={()=>viewDocProfile(doctor.docId)}
                      _hover={{
                        background: 'yellow.700',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                      }}
                      >
                        <IconButton
                          icon={<FaUser />} // Replace FaPlay with the desired icon component
                          color={'white'}
                          aria-label="Watch"
                          background={'none'}
                          _hover={{
                            background: 'none',
                          }}
                        />
                        Profile
                        </Button>
                    </ButtonGroup>
                    </Box>
                    </Flex>
                </Box>
            ))}
            </Grid>
        <FooterItem/>
        </Box>
    </div>
  </Box>






    
  )
}

export default Consultants
