/**
 * @param {String} jsonString
 * @returns {Boolean}
 */
export function isJSONString(jsonString) {
  try {
    const object = JSON.parse(jsonString);
    if (object && typeof object === "object") {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * @param {JSON} jsonObject
 * @returns {String}
 */
export const JsonToParams = (jsonObject) => {
  try {
    return Object.entries(jsonObject)
      .map((e) => e.join("="))
      .join("&");
  } catch (error) {}
};

/**
 * @param {Object} jsonObject
 * @returns {FormData}
 */
export const JsonToForm = (jsonObject) => {
  const form = new FormData();

  Object.keys(jsonObject).forEach((key) => {
    form.append(key, jsonObject[key]);
  });

  return form;
};

/**
 *  @param {Object} jsonObject
 */
export const JsonToArray = (jsonObject) => {
  const array = [];

  Object.keys(jsonObject).map((key) => array.push({ [key]: jsonObject[key] }));

  return array;
};

/**
 * @param {Object} selectedImageObject
 * @returns {Object}
 */
export const getFormImage = (selectedImageObject) => {
  const image = {
    type: selectedImageObject.mime,
    uri: selectedImageObject.path,
    name: `${new Date().getTime()}.${selectedImageObject.path
      .split(".")
      .pop()}`,
  };

  return image;
};

/**
 * @param {Number} numeric
 * @returns {String}
 */
export const convertNumericToCurrencyString = (numeric) => {
  return numeric.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
