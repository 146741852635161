import React from 'react'
import Header from '../components/Header'
import { AbsoluteCenter, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Card, CardHeader, Divider, Flex, FormControl, FormLabel, Grid, Heading, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react'
import FooterItem from '../components/FooterItem'
import { EditIcon } from '@chakra-ui/icons'

function MedDelivery() {

    const addMedDelivery = () => {
        const url = 'https://docs.google.com/forms/d/e/1FAIpQLSc9V4n_MDfi5Hk2_fhS3z31Y6B4QNj5RJZr3HLCG8OigcZoxA/viewform?usp=send_form'; 
        window.open(url, '_blank');
      };

  return (
    <div>
        <Header/>
            {/* <Heading mt={10} ml={10}>Doorstep Services</Heading>
            <HeaderPic/> */}

            <Box
                backgroundImage={require('../img/DoorstepStripNew.png')}
                backgroundSize="cover"
                backgroundPosition="center"
                height="200px"
                display="flex"
                mt="10px"
                alignItems="center"
        // justifyContent="center"
            >
                <Text
                fontSize="4xl"
                fontWeight="bold"
                ml="40px"
                color="white"
                textShadow="0 2px 4px rgba(0,0,0,0.5)"
                >
                Medicine Delivery
                </Text>
            </Box>

            <Grid templateColumns={['1fr', '1fr', '2fr 1fr', '2fr 1fr']} flexDirection={['row', 'column']} pl={['5','5']} pr={['5','5']}>
        <Box width={'100%'} p={['5','10']}>
        <Box fontSize="16px" textAlign={'justify'}>
        Medicine delivery at Nawaloka Hospital, Colombo, offers a convenient solution for patients, ensuring prescribed 
        medications reach their doorstep. This service enhances accessibility, saves time, and promotes patient adherence, 
        particularly benefiting the elderly and vulnerable populations. With the aim of improving health outcomes and 
        supporting chronic disease management, Nawaloka Hospital's medicine delivery plays a crucial role in providing 
        efficient and timely healthcare services to patients.
        </Box>
        
        <Box as="br" />

        <Accordion allowToggle>
        <AccordionItem>
          <h1>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
              Home Delivery
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h1>
          <AccordionPanel pb={4}>
              Nawaloka Hospitals understands the importance of convenience and accessibility for patients, especially those 
              who may have difficulty visiting the hospital for medication refills. With their Home Delivery service, patients 
              can now have their prescribed medications conveniently delivered to their doorstep.    
          </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
          <h1>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
              Self Pickups
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h1>
          <AccordionPanel pb={4}>
              For patients who prefer a more hands-on approach or live nearby, Nawaloka Hospitals may also offer a Self Pickup 
            option. This allows patients or their representatives to collect the prescribed medications directly from the 
            hospital pharmacy.    
          </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
          <h1>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
              Online Orders
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h1>
          <AccordionPanel pb={4}>
          Our commitment to patient well-being extends to our Home Delivery service for medications. Now, getting your prescribed medicines 
          is as easy as a click. Experience the ease of online ordering, and have your medications delivered right to your doorstep with 
          Nawaloka Medicine Delivery."   
          </AccordionPanel>
          </AccordionItem>
          </Accordion>
          <Box as="br" />

        
        </Box>

        <Box width={'100%'} padding={['2','2']} mt={['2','0','5']} order={[1, 2]}>
            
              <Card width="100%" p="10px" boxShadow={'2xl'} mt="10px" mb="10px" backgroundColor="">
              {/* <CardHeader><Heading size="lg" color="black">Medicine Delivery</Heading></CardHeader> */}
              <Box width="100%">
                <Box p={5}>
                <strong>Here how it works :</strong>
        <Box as="br" />
        {/* To access services quickly and efficiently, Nawaloka Hospital offers a seamless process. Simply scan the 
        Nawaloka QR code or click the button, and you can easily fill out the required form. This user-friendly 
        approach ensures a smooth experience, allowing patients to access the necessary services with ease and 
        convenience. */}

        <UnorderedList mt={2} ml={4}>
        <ListItem fontWeight={'semibold'}>Step 1</ListItem>
        <Text ml={4}>Scan the Nawaloka QR code or click the button</Text>
        <ListItem fontWeight={'semibold'}>Step 2</ListItem>
        <Text ml={4}>Fill out the required form</Text>
      </UnorderedList>
                </Box>
              <Box position='relative' padding='5'>
                <Divider borderColor="blackAlpha.700" />
                <AbsoluteCenter bg='white' px='4'>
                <Text fontSize="24px" fontWeight="bold" textAlign="center" color="black">
                Scan the QR
                </Text>
                </AbsoluteCenter>
                </Box>
                <Flex
                    w={'250px'}
                    h={'250px'}
                    m="30px"
                    // ml="50px"
                    // mt="10px"
                    position="relative"
                    // overflow="hidden"
                    backgroundImage={require('../img/PHQR.png')}
                    backgroundSize="cover"
                    // borderRadius="10px"
                    mx="auto"
                    >
                </Flex>
                <Box position='relative' padding='5'>
                <Divider borderColor="blackAlpha.700" />
                <AbsoluteCenter bg='white' px='4'>
                <Text fontSize="24px" fontWeight="bold" textAlign="center" color="black">
                Or
                </Text>
                </AbsoluteCenter>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='center' p="25px">
                    <Button leftIcon={<EditIcon />} colorScheme='teal' variant='solid' onClick={() => addMedDelivery()}>
                        Click Here
                    </Button>
                </Box>
              </Box>
            </Card>

            {/* <Card width="100%" p="10px" boxShadow={'2xl'} mt="20px" mb="10px" backgroundColor="">
              <CardHeader><Heading size="md" color="black">Contact Details</Heading></CardHeader>
                <VStack spacing={0} mb="10px" alignItems="center">
                    <Text fontSize="lg" fontWeight={'semibold'} color="black" whiteSpace={'pre-line'}>
                    Direct Contact: {'\n'}
                    </Text>
                    <Text fontSize="lg" textAlign="center" fontWeight={'semibold'} color="black" whiteSpace={'pre-line'}>
                    Email: 
                    </Text>
                </VStack>   
            </Card> */}
        </Box>
      </Grid>

        <FooterItem/>

    </div>
  )
}

export default MedDelivery