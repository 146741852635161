import { Box, Divider, Flex, Grid, Heading, Image, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import Header from '../components/Header'
import FooterItem from '../components/FooterItem'
import { motion } from 'framer-motion'

function Surgery() {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
        <Box>
      <Header/>  
      {/* <Heading mt={10} ml={10}>Surgery</Heading> */}

      <Box
        backgroundImage={require('../img/pagecovers/surgery-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Surgery Information
        </Text>
      </Box>

      <Grid flexDirection={['row','column']} width="100%" templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          gap={4}
          p={4}
          mt={2}
          mx="auto">

      <Flex flex={'1'} flexDirection={['column', 'column', 'row']} justify={'center'}>
            <Box  width={['100%', '100%', '80%']} mt="50px">
                      <Flex
                      // mt="20px"
                      w="100%"
                      h={'600px'}
                      // left={'698px'}
                      overflow="hidden"
                      backgroundImage={require('../img/surgery-pic.jpg')}
                      backgroundSize={"100% 100%"}
                      borderRadius="8px"
                      boxShadow="0 4px 16px rgba(0, 0, 0, 0.8)"
                      >
                      </Flex>
                  </Box>
            </Flex>

      <Box>
        <Grid flexDirection={['row','column']} width="100%" templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          gap={6}
          maxW="600px"
          p={4}
          mt={2}
          mx="auto">
                      {/* 1st Box */}
      <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="2xl"
      p={4}
      mb={2}
      mt={5}
    //   width={{ base: '100%', md: 'calc(50% - 8px)', lg: 'calc(25% - 25px)' }}
    >
    <Image src={require('../img/surgery-icon-1.png')} alt="test" mb={4} />
      <Flex direction="column" alignItems="center">
       <UnorderedList>
        <ListItem>General Surgery</ListItem>
        <ListItem>Breast Surgery</ListItem>
        <ListItem>Electromyogram (EMG)</ListItem>
        <ListItem>Hernia Surgery</ListItem>
        <ListItem>Digital X-ray</ListItem>
        <ListItem>Minimally Invasive Surgery</ListItem>
        <ListItem>Orthopedic Surgery</ListItem>
      </UnorderedList>

    <Divider mt={5}/>
      <Text fontSize="lg" fontWeight="bold" mb={4} mt="10px" textAlign="center">
      Surgery Information and Estimates<Box as="br" />
        0774991107
      </Text>
      </Flex>
    </Box>
    

    {/* 2nd Box  */}
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="2xl"
      p={4}
      mb={2}
      mt={5}
    //   width={{ base: '100%', md: 'calc(50% - 8px)', lg: 'calc(25% - 12px)' }}
    >
        
      <Image src={require('../img/surgery-icon-2.png')} alt="test" mb={4}/>
      {/* <Text fontSize="xl" fontWeight="bold" mb={4}>
        Title
      </Text>
      <Text fontSize="3xl" fontWeight="bold" mb={4}>
        $25
      </Text> */}
      <Flex direction="column"  alignItems="center">
          {/* <Text mb={2}>
            Test
          </Text> */}
       <UnorderedList>
        <ListItem>Endocrine Surgery</ListItem>
        <ListItem>Gynecological Surgery</ListItem>
        <ListItem>Thoracic Surgery</ListItem>
        <ListItem>Hernia Surgery</ListItem>
        <ListItem>Trauma Surgery</ListItem>
      </UnorderedList>

      <Divider mt="67px"/>
      <Text fontSize="xl" fontWeight="bold" mb={4} mt="15px" textAlign="center">
      Surgery Information and Estimates<Box as="br" />
        0770700077
      </Text>
      </Flex>
    </Box>

    {/* 3rd Box  */}
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="2xl"
      p={4}
      mb={2}
      mt={5}
    //   width={{ base: '100%', md: 'calc(50% - 8px)', lg: 'calc(25% - 12px)' }}
    >
    <Image src={require('../img/icon-3.png')} alt="test" mb={4} />
      <Flex direction="column" alignItems="center">
       <UnorderedList>
        <ListItem>Colon & Rectal Surgery</ListItem>
        <ListItem>Head & Neck Surgery</ListItem>
        <ListItem>Ophthalmological / Eye Surgery</ListItem>
        <ListItem>Plastic & Reconstructive Surgery</ListItem>
        <ListItem>Digital X-ray</ListItem>
        <ListItem>Urologic Surgery</ListItem>
        <ListItem>Vascular</ListItem>
      </UnorderedList>
      <Divider mt="20px"/>
      <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center" mt="15px">
      Surgery Information and Estimates<Box as="br" />
        0774991107
      </Text>
      </Flex>
    </Box>

          {/* 4th Box  */}
     <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="2xl"
      p={4}
      mb={4}
      mt={5}
    //   width={{ base: '100%', md: 'calc(50% - 8px)', lg: 'calc(25% - 12px)' }}
    >
    <Image src={require('../img/icon-4.png')} alt="test" mb={4} />
      <Flex direction="column" alignItems="center">
       <UnorderedList>
        <ListItem>Neuro surgery</ListItem>
        <ListItem>Pediatric Surgery</ListItem>
      </UnorderedList>
      <Divider mt="190px"/>
      <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center" mt="15px">
      Surgery Information and Estimates<Box as="br" />
        0765777111
      </Text>
      </Flex>
    </Box>
        </Grid>
      </Box>
    </Grid>
      <FooterItem/>
      </Box>
    </motion.div>
  )
}

export default Surgery
