import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, Heading, Input, Link, List, ListItem, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import LatestItems3 from '../../components/LatestItems3';

function RnSPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '21');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  // ------------------------------------------------------------------------------

  const viewDocProfile = (doc_id)=>{
    sessionStorage.setItem('doc_id',doc_id);
    navigate("/doctorprofile");
  }

  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/Rheumatology-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Rheumatology and Sports Medicine
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
      <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Rheumatology</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>
      <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="5px">Resident Rheumatologist</Heading>
      </Flex>
      <Flex borderWidth="1px" borderRadius="lg" p={4} 
      display="flex" width="100%" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column"
      onClick={()=>viewDocProfile('D1378')} cursor={'pointer'}
      >
        <Avatar size="2xl" src={require('../../img/doctorImages/dr-harindu.png')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
        <VStack spacing={0} alignItems="center">
            <Text fontSize="xl" fontWeight="bold" color="white">Dr. Harindu Wijesinghe</Text>
            <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                MBBS IS.L.I, MD [Coll, MCP (UK)]{'\n'}
                Consultant in Rheumatology & Rehabilitation{'\n'}
                Specialist in Sports Medicine
            </Text>
        </VStack>
      </Flex>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0765 577 111{'\n'}
            0770 079 079
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Flex>
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box>
        <Text whiteSpace={"pre-line"} textAlign={'justify'}>
        Uncover a range of rheumatic diseases encompassing conditions that result in joint pain, 
        stiffness, and swelling, affecting vital supportive structures like muscles, tendons, ligaments, 
        and bones. Notably, the family of arthritis diseases is classified within the realm of rheumatic 
        diseases. Explore comprehensive information on these conditions and their impact on the 
        musculoskeletal system.
        </Text>
        </Box>        
        <Box mt={4}>
        <Heading size="sm" mb={2}>AREAS OF CONSULTATION</Heading>
        <UnorderedList>
            <ListItem>Rheumatology - Arthritis/ Arthralgias</ListItem>
            <ListItem>Sports injuries</ListItem>
            <ListItem>Autoimmune Diseases</ListItem>
            <ListItem>Knee/ Hip/ Wrist/ Ankle Pain</ListItem>
            <ListItem>Back/ Shoulder Joint Issues</ListItem>
        </UnorderedList>
        </Box>  

        <Box mt={'10'}>
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the common symptoms of rheumatic diseases?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Common symptoms of rheumatic diseases include joint pain, swelling, stiffness, limited range of motion, 
      fatigue, muscle weakness, and inflammation. Other symptoms may vary depending on the specific condition
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the different types of arthritis?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      There are various types of arthritis, including rheumatoid arthritis, osteoarthritis, 
      psoriatic arthritis, gout, and reactive arthritis. Each type has its own unique characteristics 
      and treatment approaches
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        How are rheumatic diseases diagnosed?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Diagnosis of rheumatic diseases involves a combination of medical history evaluation, physical 
      examination, laboratory tests (such as blood tests and imaging studies), and sometimes joint 
      aspiration or biopsy to analyze joint fluid or tissue.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the available treatment options for rheumatic conditions?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Treatment options for rheumatic conditions may include medications (such as nonsteroidal anti-inflammatory 
      drugs, disease-modifying antirheumatic drugs, and biologic agents), physical therapy, occupational 
      therapy, lifestyle modifications, assistive devices, and in severe cases, surgery.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What lifestyle modifications can help manage rheumatic diseases?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Adopting a healthy lifestyle can aid in managing rheumatic diseases. This includes regular exercise, 
      maintaining a balanced diet, managing stress levels, getting sufficient rest and sleep, avoiding 
      smoking, and minimizing alcohol consumption.
      </Text>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>    
            
      </Flex>
      </Grid>
      </Flex>

      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems3/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default RnSPage
