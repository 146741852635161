import { EchannellingApi } from "../";
import { ApiEndpoints, Methods } from "../../constants";

export const getSpecializationListApi = body => {
  return EchannellingApi(
    ApiEndpoints.echannelling.specializations,
    Methods.POST,
    null,
    body,
  );
};
