/* eslint-disable require-yield */
import { call, put, select } from "redux-saga/effects";
import * as types from "../store/types";
import { getSpecializationListApi } from "../../services/API/echannelling";
import { JsonToForm, JsonToParams } from "../../utils";

export function* getSpecializationListSaga(action) {
  try {
  } catch (error) {
    console.log(error);
    console.log("error");
  } finally {
  }
}
export function* getDoctorListSaga(action) {
  try {
    // console.log(action.payload.data);
    yield put({
      type: types.SET_DOCTOR_LIST,
      payload: action.payload.data,
    });
  } catch (error) {
    console.log(error);
    console.log("error");
  } finally {
  }
}
export function* getDoctorSessionsSaga(action) {
  try {
    // console.log(action.payload.data);
    yield put({
      type: types.SET_DOCTOR_SESSIONS,
      payload: action.payload.data,
    });
  } catch (error) {
    console.log(error);
    console.log("error");
  } finally {
  }
}
