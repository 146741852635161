import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, Flex, FormControl, Grid, Heading, Input, Link, ListItem, Select, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../components/Header'
import FooterItem from '../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../constants';
import * as actions from "./store/actions";
import LatestItems5 from '../components/LatestItems5';

function ServicePage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // // console.log(spId);
    // console.log(doctorName);
    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '07');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '07',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const viewDocProfile = (doc_id)=>{
    sessionStorage.setItem('doc_id',doc_id);
    navigate("/doctorprofile");
  }

  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Ear Nose & Throat Care</Heading> */}

      <Box
        backgroundImage={require('../img/EntStrip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Ear Nose & Throat Care
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" textAlign="center" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Box background="#1A908F" p={5} borderRadius="8px" width="100%">
        <Heading size="xs" color="white" mb="5px">ENT</Heading>
        <FormControl width="100%">
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Box>
      <Flex>
        <Heading mt="20px" textAlign="center" ml="20px" size={'md'} mb="5px">Resident ENT Surgeon</Heading>
      </Flex>
      <Box borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" alignItems="center" bg={'#266564'} 
      mx="auto"
      flexDirection="column"
      onClick={()=>viewDocProfile('D1198')} cursor={'pointer'}
      >
        <Avatar size="2xl" src={require('../img/doctorImages/dr-lakshan.jpg')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
        <VStack spacing={0} alignItems="center">
            <Text fontSize="xl" fontWeight="bold" color="white">Dr. MTD Lakshan</Text>
            <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                MBBS, MS, DOHNS, FEB ORL-HNS{'\n'}
                FRCSEd ORL-HNS{'\n'}
                Consultant ENT and Head and Neck Surgeon{'\n'}
                Honorary Senior Lecturer{'\n'}
                Sri Lanka Medical Council Registration Number : 17843{'\n'}
                General Medical Council UK Registration Number : 7024057
            </Text>
        </VStack>
      </Box>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777{'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0765 577 111{'\n'}
            0770 079 079
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Flex>
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box>
        <Text mb={10} textAlign={'justify'}>
        At our clinic, we have a dedicated team of medical professionals specializing in Ear, Nose, and Throat (ENT) care. This team includes experienced ENT specialists, skilled nurses, speech therapists, and audiologists, all committed to providing exceptional care to our patients.
To ensure the best possible treatment outcomes, we employ cutting-edge technology for a wide range of ENT disorders. These include conditions such as rhinitis, sinusitis, allergies, snoring, sleep apnea, ear infections, hearing loss, tinnitus, balance disorders, voice disorders, as well as head and neck cancers, including thyroid and salivary tumors. Additionally, we offer hearing rehabilitation using advanced techniques like cochlear implants and other implantable devices.
Our team is dedicated to delivering excellent care and ensuring the well-being of our patients by utilizing the latest advancements in the field of ENT and collaborating with other professionals in related disciplines
        </Text>
        </Box>        

            <Box>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        Common Ear related symptoms
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
        <ListItem>Hearing Loss</ListItem>
        <ListItem>Vertigo / Imbalance</ListItem>
        <ListItem>Tinnitus / "Noise" in the ear</ListItem>
        <ListItem>Ear discharge</ListItem>
        <ListItem>Foreign bodies and insects in the ear</ListItem>
        <ListItem>Wax impaction</ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        Nose related symptoms
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
        <ListItem>Nose block</ListItem>
        <ListItem>Septal deviation</ListItem>
        <ListItem>Nasal polyps</ListItem>
        <ListItem>Sinusitis</ListItem>
        <ListItem>Rhinitis</ListItem>
        <ListItem>Vertigo / Imbalance</ListItem>
        <ListItem>Nose Bleeds</ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        Throat related symptoms
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
        <UnorderedList>
            <ListItem>Neck lumps</ListItem>
            <ListItem>Thyroid nodules</ListItem>
            <ListItem>Salivary tumors</ListItem>
            <ListItem>Snoring and sleep apndea</ListItem>
            <ListItem>Voice changes</ListItem>
            <ListItem>Tonsil and adenoids</ListItem>
        </UnorderedList>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>

<Box mt={10}>
<Heading size="sm">Surgeries</Heading>
        <UnorderedList>
            <ListItem>Endoscopic Myringoplasty and mastoidectomy</ListItem>
            <ListItem>Endoscopic stapedectomy</ListItem>
            <ListItem>Septoplasty</ListItem>
            <ListItem>Functional Endoscopic Sinus Surgery FESS</ListItem>
            <ListItem>Nasal Polypectomy Endoscopic microdebrider assisted</ListItem>
            <ListItem>Endoscopic DCR</ListItem>
            <ListItem>Adenoidectomy</ListItem>
            <ListItem>Tonsillectomy</ListItem>
            <ListItem>Thyroidectomy</ListItem>
            <ListItem>Submandibular and parotid gland surgery</ListItem>
            <ListItem>Myringotomy and Grommets Insertion</ListItem>
        </UnorderedList>
</Box>

<Box mt={'10'}>
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are some common conditions treated by ENT specialists?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      ENT specialists commonly treat conditions such as ear infections, sinusitis, allergies, 
      nasal congestion, tonsillitis, hearing loss, tinnitus (ringing in the ears), vertigo (dizziness), 
      vocal cord disorders, and sleep apnea, among others.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        How is hearing loss treated by ENT specialists?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Treatment for hearing loss depends on the cause and severity of the condition. 
      ENT specialists may recommend hearing aids, assistive listening devices, or cochlear implants 
      for individuals with significant hearing loss. They can also address underlying conditions that 
      contribute to hearing loss, such as ear infections or impacted earwax.
      </Text>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>
</Flex>
      </Grid>
      </Flex>

      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems5/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default ServicePage
