import { Box, Card, Flex, Grid, Heading, Image, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { useEffect, useRef } from "react";
import { motion } from 'framer-motion';

const cardHeight = '370px';

function Technology() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const cardVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const gridVariants = {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.1, // Adds a slight delay between animating each child (card)
      },
    },
  };

  const handleScroll = () => {
    // Logic to handle scroll position and parallax effect goes here
    // You can use 'window.scrollY' or other methods to get the scroll position

    // Example: Adjust background image position based on scrollY
    const backgroundOffset = window.scrollY * 0.5; // Adjust the parallax speed here
    const backgroundElement = document.getElementById('parallaxBackground');
    backgroundElement.style.transform = `translateY(${backgroundOffset}px)`;
  };

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up the scroll event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  return (
    <div>

      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        id="parallaxBackground"
        backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Cutting Edge Technology at Our Facilities
        </Text>
      </Box>
      <motion.div
        // Apply motion animations to the entire Grid
        variants={gridVariants}
        initial="initial"
        animate="animate"
        exit="initial" // Optional, if you have exit animations
      >

      <Grid flexDirection={['row','column']} 
        width="100%" 
        templateColumns={["1fr", "2fr", "1fr 1fr 1fr"]}
        maxW="1200px"
          gap="50px"
          p={4}
          mt={2}
          mx="auto">

        <motion.div
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="initial" // Optional, if you have exit animations
        >
            <Card position="relative" width="100%" height={cardHeight} p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.5)" 
            style={{ transform: 'translateY(10px)' }} // Adjust the parallax effect offset here
            >
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/techPage/tech-4.png')} alt="test" mb={4}/>
            </Box>
            <Text textAlign={'justify'}>
            The world’s fastest and most accurate 640 Slice CT scanner - Toshiba Aquilion ONE. 
            Nawaloka Hospitals PLC was the first to introduce the technology to Sri Lanka.
            </Text>
            </Card>
          </motion.div>  

          <motion.div
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="initial" // Optional, if you have exit animations
        >
            <Card position="relative" width="100%" height={cardHeight} p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.5)"
            style={{ transform: 'translateY(10px)' }} // Adjust the parallax effect offset here
            >
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/techPage/tech-6.png')} alt="test" mb={4}/>
            </Box>
            <Text textAlign={'justify'}>
            Latest technology in bone density scanning - DEXA (Dual Energy X-ray Absorptiometry).
            </Text>
            </Card>
          </motion.div>  

        <motion.div
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="initial" // Optional, if you have exit animations
        >
            <Card position="relative" width="100%" height={cardHeight} p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.5)"
            style={{ transform: 'translateY(10px)' }} // Adjust the parallax effect offset here
            >
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/techPage/tech-2.png')} alt="test" mb={4}/>
            </Box>
            <Text textAlign={'justify'}>
            Fibro Scan - Non-invasive, pain-free liver assessment technology. Nawaloka Hospitals PLC was the 
            pioneer in introducing this technology in Sri Lanka.
            </Text>
            </Card>
          </motion.div>

        <motion.div
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="initial" // Optional, if you have exit animations
        >
            <Card position="relative" width="100%" height={cardHeight} p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.5)">
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/techPage/tech-5.png')} alt="test" mb={4}/>
            </Box>
            <Text textAlign={'justify'}>
            World class Hemodialysis Unit.
            </Text>
            </Card>
          </motion.div>

        <motion.div
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="initial" // Optional, if you have exit animations
        >
            <Card position="relative" width="100%" height={cardHeight} p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.5)">
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/techPage/tech-3.png')} alt="test" mb={4}/>
            </Box>
            <Text textAlign={'justify'}>
            3 TESLA and 1.5 TESLA MRI scanners - Only hospital in Sri Lanka to have two MRI machines in operation. 
            First hospital in Sri Lanka to commission this technology.
            </Text>
            </Card>
          </motion.div>  

          <motion.div
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="initial" // Optional, if you have exit animations
        >
            <Card position="relative" width="100%" height={cardHeight} p={4} boxShadow="0 4px 16px rgba(0, 0, 0, 0.5)">
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={require('../../img/aboutUsImg/techPage/tech-1.png')} alt="test" mb={4}/>
            </Box>
            <Text textAlign={'justify'}>
            World’s best 3D HOLOGIC Digital Mammogram available at the Nawaloka Breast Care Centre.
            </Text>
            </Card>
          </motion.div> 

        </Grid>
        </motion.div> 
        <FooterItem/>
        </Box>
    </div>
  )
}

export default Technology
