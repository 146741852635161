import React, { useEffect, useRef, useState } from 'react';
import { Box, Image, Modal, ModalOverlay, ModalContent, ModalBody, Flex, ModalCloseButton, Grid, GridItem } from '@chakra-ui/react';

const PhotoCollage = () => {
    
      return (
        <Grid
          h='300px'
          p={{base: '20px 20px 20px 20px', md: '20px 20px 20px 20px', lg: '20px 90px 20px 90px'}}
          templateRows='repeat(2, 1fr)'
          templateColumns='repeat(5, 1fr)'
          gap={2}
        >
          <GridItem rowSpan={2} colSpan={2} backgroundImage={require('../img/rooms/superPremium/IMG_7112.jpeg')} w='100%' h='100%' backgroundSize='cover'  backgroundPosition='center' />
          <GridItem colSpan={1} backgroundImage={require('../img/rooms/vipPremium/IMG_7155.jpeg')} w='100%' h='100%' backgroundSize='cover'  backgroundPosition='center'  />
          <GridItem colSpan={1} backgroundImage={require('../img/rooms/premium/img07.jpg')} w='100%' h='100%' backgroundSize='cover'  backgroundPosition='center'  />
          <GridItem colSpan={1} backgroundImage={require('../img/rooms/deluxSuperior/IMG_7099.jpeg')} w='100%' h='100%' backgroundSize='cover'  backgroundPosition='center'  />
          <GridItem colSpan={1} backgroundImage={require('../img/rooms/executive/IMG_7166.jpeg')} w='100%' h='100%' backgroundSize='cover'  backgroundPosition='center'  />
          <GridItem colSpan={1} backgroundImage={require('../img/rooms/delux/IMG_7172.jpeg')} w='100%' h='100%' backgroundSize='cover'  backgroundPosition='center'  />
          <GridItem colSpan={1} backgroundImage={require('../img/rooms/wardBeds/img1180_1.jpg')} w='100%' h='100%' backgroundSize='cover'  backgroundPosition='center'  />
        </Grid>
      );
    };

export default PhotoCollage;
