import logo from "./logo.svg";
import "./App.css";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import sagas from "./store/sagas";
import reducers from "./store/reducers";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import LandingPage from "./pages/LandingPage.tsx";
import Emergency from "./pages/Emergency";
import Virtual from "./pages/Virtual";
import Channeling from "./pages/Channeling";
import Doorstep from "./pages/Doorstep";
import AboutUs from "./pages/aboutuspages/AboutUs";
import International from "./pages/International";
import ContactUs from "./pages/ContactUs";
import DoctorSearch from "./pages/DoctorSearch";
import DoctorProfile from "./pages/DoctorProfile";
import ServicePage from "./pages/ServicePage";
import HeartCentrePage from "./pages/servicepages/HeartCentre"
import Surgery from "./pages/Surgery";
import BookSession from "./pages/BookSession";
import BookingSummery from "./pages/BookingSummery";
import NeuroCarePage from "./pages/servicepages/NeuroCarePage"
// This is the way to import an SVG file and then pass it as a props
// import { ReactComponent as CompanyIcon } from './assets/crown.svg';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import Preloader from './components/Preloader';
import { useEffect, useState } from "react";
import RnSPage from "./pages/servicepages/RnSPage";
import { useSelector } from 'react-redux';
import { loadingSelector } from './store/WebStore/selectors';


function Entry() {
  const visible = useSelector(loadingSelector);
  console.log(visible);
  const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const delay = 2000; // Adjust this value as needed

//  if(isLoading == undefined){
//   setIsLoading(true);
//   const timer = setTimeout(() => {
//     setIsLoading(false);
//   }, delay);

//   return () => clearTimeout(timer);
//  }
//   }, []);

  return (

     
        <Router>
        {isLoading ? (
        <Preloader />
      ) : (
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/emergency" element={<Emergency />} />
            <Route path="/virtual" element={<Virtual />} />
            <Route path="/channeling" element={<Channeling />} />
            <Route path="/doorstep" element={<Doorstep />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/international" element={<International />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/doctorsearch" element={<DoctorSearch />} />
            <Route path="/doctorprofile" element={<DoctorProfile />} />

            <Route path="/servicepage" element={<ServicePage />} />
            <Route path="/servicepages/heartcentre" element={<HeartCentrePage />} />
            <Route path="/servicepages/neurocare" element={<NeuroCarePage />} />
            <Route path="/servicepages/rns" element={<RnSPage />} />
            {/* <Route path="/about" element={<About />} />

        <Route path="/contact" element={<Contact />} /> */}

            {/* <Route path="/header" element={<Header />} />
        <Route path="/navbar" element={<Navbar />} /> */}

            {/* Treatments */}
            <Route path="/treatments/surgery" element={<Surgery />} />
            {/* <Route path="/doctorprofile" element={<DoctorProfile />} /> */}

            <Route path="/booksession" element={<BookSession />} />
            <Route path="/bookingsummery" element={<BookingSummery />} />

          </Routes>
          )}
          <WhatsAppWidget phoneNumber="+94779779673" companyName="Nawaloka Hospitals PLC" />
        </Router>
     

  );
}


export default Entry;
