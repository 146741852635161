import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, GridItem, Heading, Input, Link, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import { MdArrowForward, MdCheckCircle, MdSettings } from 'react-icons/md';
import LatestItems from '../../components/LatestItems';
import LatestItems1 from '../../components/LatestItems1';

function Cosmetic() {

  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '262');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };



  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/fertlity-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Skin Care & Cosmetic Centre
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Skin Care & Cosmetic</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            {/* <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0770 700 077{'\n'}
            </Text> */}
        </VStack>
      </Box>
      </Flex>
      <Box position="relative">
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box textAlign={'justify'}>
        <Text mb={2} whiteSpace={"pre-line"}>
        Welcome to Nawaloka Skin Care & Cosmetic Centre, where beauty meets precision. Step into a world of indulgence 
        and transformation, where cutting-edge technology harmonizes with personalized care. Our expert team of 
        skincare professionals is dedicated to enhancing your natural radiance, offering a bespoke range of treatments 
        tailored to your unique beauty journey. Unveil a more confident you as we blend artistry and science, setting 
        new standards in skincare and cosmetics. Discover the epitome of elegance and rejuvenation at Nawaloka—where 
        timeless beauty begins
        {/* {'\n'} */}

        </Text>

        <Box mt="40px">
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        How 3D Lipo Fat & Cellulite reduction works?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text whiteSpace={"pre-line"}>
      The 3D-Lipo system gives clients several options and combinations to target and treat stubborn fat areas and 
      cellulite. The options are Cavitation and Cryolipolysis for losing inches, Radio Frequency for skin tightening, 
      and 3D Dermology for cellulite reduction. {'\n\n'}

      Crylipolysis involves controlled freezing of fat cells, the body naturally disposes of the dead fat cells over 
      the ensuing weeks and months. {'\n\n'}

      Ultrasound Cavitation uses ultrasound to rupture fat cells, this turns the cells into liquid which is carried 
      away by the body as a waste product. This is used for inch loss and fat pocket reduction.{'\n\n'}

      Radio Frequency generates energy that penetrates the skin layers to selectively heat the skin tissue. The heating 
      process results in the immediate contraction of collagen fibers, at the same time, new collagen is produced. 
      This gives a tighter and firmer appearance to the skin. {'\n\n'}

      3D Dermology & Radio Frequency users vacuum suction and rollers to drain the depleted fat cells through the 
      lymphatic system.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Is the 3D Lipo Fat & Cellulite reduction treatment painful?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      The treatment can cause minimal discomfort.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Will the 3D Lipo Fat & Cellulite treatment work for me?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      The 3D-Lipo system gives several options and combinations to target and treat stubborn fat and cellulite we can successfully treat patients who all have
      different aims.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        When will I see results?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      As each treatment and client is different, treatment courses will depend on the
      client and the results they wish to get but we suggest;
      </Text>
      <UnorderedList>
        <ListItem>
        With Cryolipolysis you have one single treatment and results can take 2-4 months to
        show, but it can be anything up to 6 months.
        </ListItem>
        <ListItem>
        With Ultrasound Cavitation we recommend that you have 6 to 8 treatments, at
weekly intervals. You will see results after each session.
        </ListItem>
        <ListItem>
        With Radio Frequency we suggest that clients have 6 to 8 treatments with noticeable
results after each session. You can see results after each treatment but they will not
be overly noticeable at the beginning of the course.
        </ListItem>
        <ListItem>
        For 3D Dermology & Radio Frequency, we recommend 6 to 8 treatments with visible
results showing after each session.
        </ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        How long does one treatment take?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      A single treatment takes between 30 and 60 minutes.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Why choose Nawaloka Skincare and Cosmetic Centre?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text whiteSpace={"pre-line"}>
      At Nawaloka Skincare and Cosmetic Centre, we have a simple mission; we want to
      make advanced aesthetic care affordable, we provide all customers with the best
      possible prices we can.{'\n\n'}

      All of our clinics use world-renowned equipment which are FDA-approved, so you know
      that you are getting the best possible treatment.{'\n\n'}

      We are a skincare and cosmetic centre with a team of board-certified professionals,
      that include 6 Consultant Dermatologists, 3 Plastic Surgeons, and an Ocuplastic
      Surgeon (Eye Plastic Surgeon){'\n\n'}

      We specialize in a range of professional clinic treatments including Skin Rejuvenation,
      Acne Treatments, Scar Removal, Pigmentation Treatments, Phototherapy, Wrinkle
      Reduction, and Stretch Mark Reduction. Also, we specialize in Hair Loss Treatments,
      Permanent Hair Reduction and Fat and Cellulite Reduction.{'\n\n'}
      </Text>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>
        </Box>  

      </Flex>
      </Grid>
      </Box>
      
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems1/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default Cosmetic
