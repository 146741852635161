import * as types from "./types";

const initialState = {
  specialityList: [],
  doctorList: [],
  doctorSessions: [],
};

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SPECIALITY_LIST: {
      return {
        ...state,
        specialityList: action.payload,
      };
    }
    case types.SET_DOCTOR_LIST: {
      return {
        ...state,
        doctorList: action.payload,
      };
    }
    case types.SET_DOCTOR_SESSIONS: {
      return {
        ...state,
        doctorSessions: action.payload,
      };
    }

    default:
      return state;
  }
};
