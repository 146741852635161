import { Box, Card, Flex, Grid, Heading, Image, Spacer, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { useEffect, useRef } from "react";
import Testimonials from '../../components/Testimonials';

function ChairmansMessage() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  return (
    <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../../img/pagecovers/message-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Chairman's Message
        </Text>
      </Box>

      <Testimonials/>

        {/* <Card p={4} m={5} boxShadow="0 4px 16px rgba(0, 0, 0, 0.3)" borderRadius="20px"
        backgroundImage={require('../../img/back-img-2.png')}>
        <Grid flexDirection={['row','column']} width="100%" templateColumns={["1fr", "2fr", "1fr 1fr"]}
          gap={4}
          p={4}
          mt={2}
          mx="auto">
        <Flex flex={'1'} flexDirection={'column'}>
        <Box border={'2px'} borderRadius={8} borderColor={'gold'} m={5} p={5}>
          <Box display={'flex'} flexDirection={['row', 'column']}>
          <Flex
            w={'318px'}
            h={'392px'}
            // ml="50px"
            mt="10px"
            position="relative"
            overflow="hidden"
            backgroundImage={require('../../img/nawaloka-chairman.jpg')}
            backgroundSize="100%"
            borderRadius="10px"
            mx="auto"
            >
          </Flex>
          </Box>
        </Box>
        </Flex>
        <Box>
        <Text p={4} width="100%" textAlign={'justify'}>
          Providing access to the State-of-Art diagnostic and treatment technologies is a hallmark of 
          Nawaloka Hospital. Driven by our Vision to be the Hospital of Tomorrow we give precedence to 
          superior care, accuracy, and excellence in all we do.<Box as="br" /><Box as="br" />

          Among the many healthy choices one can make in life, I sincerely believe Nawaloka Hospital to 
          be a supremely healthy choice - from many perspectives. To the customer, we are a provider of 
          the finest health care available in Sri Lanka today.<Box as="br" /><Box as="br" />

          Our State-of-the Art technology, medical systems and procedures, skilled professionals and 
          years of experience in the field equate to a top tier health care provider. Within the health 
          sector too Nawaloka's stature makes it a healthy choice amongst consultants and other 
          professionals, who have no qualms in selecting Nawaloka as a fitting entity through which 
          they serve the public.<Box as="br" /><Box as="br" />

          Our consultants enjoy national repute as being among the most experienced practitioners in 
          their fields. Together, we take great pride in the quality of the care we provide and in the 
          integrity of our institution.
          </Text>
        </Box>
        </Grid>
        </Card> */}
        <FooterItem/>
        </Box>
    </div>
  )
}

export default ChairmansMessage
