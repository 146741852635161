import React, { ReactElement } from "react";
import { Box, Card, CardBody, CardHeader, Flex, Heading, Icon, SimpleGrid, Stack, StackDivider, Text } from "@chakra-ui/react";
import Header from '../components/Header'
import HeaderPic from "../components/HeaderPic";
import { FcAssistant, FcBriefcase, FcBusinesswoman, FcCloth, FcCustomerSupport, FcDepartment, FcDonate, FcFeedIn, FcInTransit, FcPhone, FcServiceMark, FcSmartphoneTablet } from "react-icons/fc";
import { FaBriefcaseMedical } from "react-icons/fa";
import FooterItem from "../components/FooterItem";

export default function Emergency() {

  interface FeatureProps {
    title: string;
    text: string;
    phonenumber: string;
    icon: ReactElement;
  }
  
  const Feature = ({ title, text, icon, phonenumber }: FeatureProps) => {
    return (
      <Stack>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.100'}
          mb={1}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{title}</Text>
        <Text color={'gray.600'}>{text}</Text>
        {phonenumber && (
        <Stack direction="row" align="center" spacing={2} mt="auto">
          <FcPhone size={24} />
          <Text fontSize="lg" fontWeight={600}>
            {phonenumber}
          </Text>
        </Stack>
        )}
      </Stack>
    );
  };

    return(
        <div>
            <Box>
            <Header/>
            {/* <Heading mt={10} ml={10}>Doorstep Services</Heading>
            <HeaderPic/> */}

            <Box
                backgroundImage={require('../img/DoorstepStripNew.png')}
                backgroundSize="cover"
                backgroundPosition="center"
                height="200px"
                display="flex"
                mt="10px"
                alignItems="center"
        // justifyContent="center"
            >
                <Text
                fontSize="4xl"
                fontWeight="bold"
                ml="40px"
                color="white"
                textShadow="0 2px 4px rgba(0,0,0,0.5)"
                >
                Doorstep Services
                </Text>
            </Box>

            {/* <Card>

  <CardBody>
    <Stack divider={<StackDivider />} spacing='4'>
      <Box>
        <Heading size='md' textTransform='uppercase'>
        Medicine Delivery
        </Heading>
        <Text pt='2' fontSize='md'>
        The most trustworthy & reliable online pharmacy in Sri Lanka. 
        Simply upload your prescription to get your medication delivered to 
        your doorstep.
        </Text>
        <Text as={'b'}>Tele : +94 0770 229 988</Text>
      </Box>
      <Box>
        <Heading size='md' textTransform='uppercase'>
        Lab Mobile
        </Heading>
        <Text pt='2' fontSize='md'>
        A mobile laboratory is a laboratory that is either fully housed within or 
        transported by a vehicle such as a converted bus or RV, consisting of the micro 
        lab with different mobile tests. 
        </Text>
        <Text as={'b'}>Tele : +94 0763 594 632</Text>
      </Box>
      <Box>
        <Heading size='md' textTransform='uppercase'>
        Corporate Mobile
        </Heading>
        <Text pt='2' fontSize='md'>
        Visit corporates to do medical tests
        </Text>
      </Box>
      <Box>
        <Heading size='md' textTransform='uppercase'>
        Physiotherapy
        </Heading>
        <Text pt='2' fontSize='md'>
        Exercises and physiotherapy sessions get done at your home
        </Text>
      </Box>
    </Stack>
  </CardBody>
</Card> */}


    <Box p={4}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="55px" ml="20px" mr="20px">
            <Feature
              icon={<Icon as={FcDonate} w={10} h={10} />}
              title={'Medicine Delivery'}
              text={
                'The most trustworthy & reliable online pharmacy in Sri Lanka. Simply upload your prescription to get your medication delivered to your doorstep.'
              }
              phonenumber={'0763 594 632'}
            />
            <Feature
              icon={<Icon as={FcSmartphoneTablet} w={10} h={10} />}
              title={'Lab Mobile'}
              text={
                'Getting a medical test done has never been easier. With the NH mobile Lab service you can get all the tests done in the comfort of your own home or office.'
              }
              phonenumber={'0761 634 118'}
            />
            <Feature
              icon={<Icon as={FcBriefcase} w={10} h={10} />}
              title={'Corporate Mobile'}
              text={
                'Visit corporates to do medical tests.'
              }

              // Other NUmbers- /0777576199/0777506448/0763070136
              phonenumber={'0760 542 797'}
            />
            <Feature
              icon={<Icon as={FcBusinesswoman} w={10} h={10} />}
              title={'Physiotherapy'}
              text={
                'Home visit physiotherapy treatment are highly convenient. Physiotherapy treatment is offered at times that are flexible toward your own schedule. call us to fix your appointment'
              }
              phonenumber={'0115 577 102'}
            />
          </SimpleGrid>
        </Box>
            <FooterItem/>
            </Box>
        </div>
    );
}