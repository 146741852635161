import React, { useRef, useState } from 'react'
import Header from '../components/Header'
import {
  Box, Button, Card, CardHeader, Flex, FormControl, FormLabel, Grid, Heading,
  Input, SelectField, SimpleGrid, Text, Textarea, useToast, Select, Stat, StatLabel,
  StatNumber, StatHelpText, StatArrow, Tabs, TabList, Tab, TabPanels, TabPanel, ListItem, 
  ListIcon, List, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, GridItem, Img
} from '@chakra-ui/react'
import FooterItem from '../components/FooterItem'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { StatGroup } from 'chakra-ui'
import { MdCheckCircle, MdSettings } from 'react-icons/md'
import { JsonToForm, JsonToParams } from "../utils";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import { motion } from 'framer-motion'
import InternationalPic1 from "https://c1.wallpaperflare.com/preview/238/36/958/multi-storey-car-park-parking-park-level-park.jpg";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";

// const countryList = [
//   { value: 'US', label: 'United States' },
//   { value: 'CA', label: 'Canada' },
//   // Add more countries as needed
// ];


const Widget = ({ number, label, bgimage }) => (
  <GridItem colSpan={2} pl={["2px","10px"]} pr={["2px","10px"]} mb="5px">
    {/* <Img src={bgimage} alt={`Image1`}/> */}
    <Box display="flex" 
      flexDirection="column" 
      height="300px" 
      width="210px" 
      bgImage={`url(${bgimage})`}
      bgSize="cover"
      bgPosition="center"
      alignItems="center"
      borderRadius="8px"
      boxShadow={'lg'}
      >
    <Box
      top={0}
      left={0}
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.2)"
      borderRadius="8px"
    >
    <Stat textAlign="center" top="15vh" flexGrow={1} display="flex" flexDirection="column">
      <StatNumber fontSize="4xl" fontWeight="bold" color="white" textShadow="2px 2px 4px rgba(0, 0, 0, 1)">
        {number}
      </StatNumber>
      <StatLabel fontSize="lg" fontWeight="bold" color="white" textShadow="2px 2px 4px rgba(0, 0, 0, 1)"
      textTransform="uppercase">
        {label}
      </StatLabel>
    </Stat>
    </Box>
    </Box>
  </GridItem>
);

function International() {

  const [loading, setLoading] = useState(false);
  const [name, setname] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [msg, setmsg] = useState(null);
  const [country, setCountry] = useState(null);
  const toast = useToast();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const recaptchaRef = useRef(null);
  const [countryList, setCountryList] = useState([]);

  fetch('https://restcountries.com/v3.1/all')
  .then(response => response.json())
  .then(data => {
    // Process the data and extract the country names and codes
    const countryList = data.map(country => ({
      label: country.name.common,
      value: country.alpha2Code
    }));

    countryList.sort((a, b) => a.label.localeCompare(b.label));

    // Set the country list to your component's state or use it as needed
    setCountryList(countryList);
  })
  .catch(error => {
    // Handle any errors that occur during the API request
    console.error('Error fetching country list:', error);
  });


  const onSubmit = async (data) => {
    setLoading(true);

    try {

      // // Verify the reCAPTCHA response
      // const recaptchaResponse = await recaptchaRef.current.executeAsync(); // Execute the reCAPTCHA verification
      // // The response will be available in recaptchaResponse

      // // Send form data and reCAPTCHA response to the server or external API
      // const response = await axios.post('/api/send-email', { data, recaptchaResponse });

      // Send form data to the server or external API

      // Display success toast
      // toast({
      //   title: 'Message sent',
      //   description: 'Your message has been sent successfully.',
      //   status: 'success',
      //   duration: 5000,
      //   isClosable: true,
      // });
      sendmail();

      reset(); // Clear form fields
    } catch (error) {
      // Display error toast
      // toast({
      //   title: 'Error',
      //   description: 'An error occurred. Please try again later.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    }

    setLoading(false);
  };

  const sendmail = () => {
    // setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {

      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.sendmailInternational;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          mobile: mobile,
          msg: msg,
          country: country,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {

            console.log(object);
            if(!object.error){
              toast({
                title: 'Success',
                description: object.msg,
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
             // window.location.reload();
            }else{
              toast({
                title: 'Error',
                description: object.msg,
                status: 'error',
                duration:2000,
                isClosable: true,
              });
            }

          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      setEmail('');
      setMobile('');
      setname('');
      setmsg('');
      //setIsLoading(false);

    }
  };
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    >
    <Box>
      <Header />
      {/* <Heading mt={10} ml={10}>International Patients</Heading> */}
      <Box
        backgroundImage={require('../img/international-patients-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="250px"
        display="flex"
        mt="0px"
        alignItems="center"
        // css={{
        //   '& > *': {
        //     height: '150px', // Set the desired fixed height for each row
        //   },
        // }}
      // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          International Patients
        </Text>
      </Box>

      {/* <Flex
        w={'full'}
        h={'205px'}
        mt={'20px'}
        backgroundImage={require('../img/international-patients-banner.jpg')}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}>

    </Flex> */}

    <Box>
    <Box mt="-55px">
    <Grid
      templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
      gap={2}
      justifyContent="center"
      justifyItems="center"
      alignItems="center"
      pl={1} pr={1}
    >
      <Widget number="1500+" label="Specialists" bgimage="https://wallpapercave.com/wp/wp2789220.jpg" />
      <Widget number="400+" label="Bed Capacity" bgimage="https://thumbs.dreamstime.com/b/hospital-ward-beds-medical-equipment-interior-new-empty-room-75224097.jpg" />
      <Widget number="3000+" label="Laboratory Diagnostics" bgimage="https://e0.pxfuel.com/wallpapers/157/811/desktop-wallpaper-tzar-labs-medical-lab-thumbnail.jpg" />
      <Widget number="500+" label="Vehicle Parking" bgimage="https://c1.wallpaperflare.com/preview/238/36/958/multi-storey-car-park-parking-park-level-park.jpg" />
      <Widget number="7+" label="Ambulances" bgimage="https://3.bp.blogspot.com/-_grY-TJD_Rg/Vbxb-DZ0sRI/AAAAAAAAAig/FsvkVkEeDwk/s1600/Ambulance-Services.jpg" />
      <Widget number="12" label="Theatres" bgimage="https://wallpapercave.com/wp/wp11548729.jpg" />
    </Grid>
  </Box>
    </Box>

      <SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} spacing={{ base: 0, md: 0, lg: 50 }} width="100%">
        <Box width="100%">
          <Box textAlign="center" mt={'10'} mb={'5'} ml="30px" width="100%^">
            <Heading size={'lg'}>Discover Exceptional Care at Nawaloka Hospitals:</Heading>
            <Heading size={'md'}>Your Home Away from Home</Heading>
          </Box>
          <Box textAlign={'justify'} pl="30px" pr="30px" mb={'10'}>
            <Text as="p" mb={'5'}>
            In the midst of paradise, where medical emergencies can disrupt your peace of mind, Nawaloka 
            Hospital stands as your beacon of hope. With our unwavering commitment to providing exceptional 
            healthcare, we extend our warm embrace to patients from across the globe.
            </Text>
            <Text as="p">
            Experience our dedicated International Care Service, meticulously tailored to cater to your 
            every need, ensuring a seamless and compassionate medical journey. From the moment you step 
            through our doors, we prioritize your comfort, safety, and well-being, offering personalized 
            care that exceeds expectations.
            </Text>
          </Box>

          <Text textAlign={'justify'} pl="30px" pr="30px" mb={'10'}>
          At Nawaloka, distance is no barrier to receiving world-class medical attention. Trust in our 
          expertise and let us become your home away from home, delivering the care you deserve.
          </Text>

          <Box mt={'10'} pl="30px" pr="30px" mb={'10'}>
            <Heading size={'md'} mb={'5'}>FACILITIES FOR INTERNATIONAL PATIENTS</Heading>
            <Text>
            At our facility, our International Patients Team is dedicated to delivering world-class service, ensuring 
            a hassle-free medical journey for both you and your family. Here are the services we provide:

            </Text>
            <Accordion allowToggle mt={4}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Pre-Arrival Medical Opinion: 
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                  Our team of doctors will provide you with a detailed medical opinion before your arrival. 
                  Please send us your medical details and history, and we will arrange an appointment with the 
                  appropriate specialist. Note that for accuracy, we may seek additional information based on the 
                  nature of your inquiry.

                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Visa Services: 
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    We offer assistance with visa services to streamline your travel preparations.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Airport Pickup: 
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                  We provide airport pickup services for your convenience, ensuring a smooth admission process.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Foreign Currency Exchange:  
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                  We offer foreign currency exchange facilities to assist you during your stay.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Dedicated Coordinators:
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                  Each patient is assigned a dedicated coordinator who will take care of your needs within the 
                  hospital and assist you with administrative processes.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Payment Options:
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                  Payments can be made using cash or credit cards, providing flexibility for your financial preferences.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Accommodation Assistance: 
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                  If you prefer to stay at a nearby hotel during your treatment, our International Marketing Team can 
                  help you find accommodation that aligns with your budget. Please contact our International 
                  Representative before your travel period for more details.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
                    Interpreters:
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                  We have interpreters available at the hospital to ensure effective communication during your stay.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>

        </Box>
        <Box>
          <List spacing={3} ml="30px" mb={'10'} mt={"50px"}>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='green.500' />
              <Box flexGrow={1}>
              A dedicated team of professionals with multilingual Support
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='green.500' />
              <Box flexGrow={1}>
              Experienced coordinators as advocates to guide you through the process end-to-end
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='green.500' />
              <Box flexGrow={1}>
              Expert Medical Specialists across various disciplines updated with the latest advancements in
              medical technology and treatment protocols.
              </Box>
            </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='green.500' />
              <Box flexGrow={1}>
              State-of-the-Art Facilities to ensure infrastructure supports the delivery of exceptional care.
              </Box>
              </Flex>
            </ListItem>
            {/* <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='green.500' />
              <Box flexGrow={1}>
              Customized Treatment Plans where medical professionals collaborate closely to develop
              personalized treatment plans tailored to your condition
              </Box>
              </Flex>
            </ListItem> */}
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='green.500' />
              <Box flexGrow={1}>
              Comfortable Accommodations including private rooms and suites, designed to make your stay as
              pleasant as possible where our international patients receive personalized care, to ensure a
              comfortable and supportive environment throughout their recovery.
              </Box>
            </Flex>
            </ListItem>
          </List>

          <Box ml="30px" mb="20px" >
            <Text mb="10px">Contact our International Care Service team today, and let us help you on your path to wellness.</Text>
            <Text as="b" fontSize="lg" color={'yellow.600'}>Dedicated Contact Number for International Patients: +94 768 577 111</Text>
            {/* <Text as="b" mt="30px">Please call for More Information +94 768577111</Text> */}
          </Box>

          <Box display="flex" justifyContent="center" mt="20px">
            <Card width="80%" pb="10px" boxShadow={'2xl'} mt="10px" mb="10px" backgroundColor="#e09f3e">
              <CardHeader><Heading size="md" color="white">Send an Enquiry</Heading></CardHeader>
              <Box width="90%" mx="auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl id="name" mb={4} isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input required {...register('name')} bg="white" value={name} type="text" onChange={(event) => setname(event.target.value)}/>
                  </FormControl>

                  <FormControl id="email" mb={4} isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input required {...register('email')} bg="white" value={email} type="email" onChange={(event) => setEmail(event.target.value)}/>
                  </FormControl>
                  <FormControl id="mobile" mb={4} isRequired>
                    <FormLabel>WhatsApp/Mobile No</FormLabel>
                    <Input required value={mobile} {...register('mobile')} bg="white" onChange={(event) => setMobile(event.target.value)} type="number" />
                  </FormControl>
                  <FormControl id="country" mb={4} isRequired>
                    <FormLabel>Country</FormLabel>
                    <Select {...register('country')} placeholder="Select country" bg="white" onChange={(event) => setCountry(event.target.value)}>
                      {countryList.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl id="message" mb={4} isRequired>
                    <FormLabel>Message</FormLabel>
                    <Textarea required {...register('message')} bg="white" defaultValue={msg} rows={4} resize="vertical" onChange={(event) => setmsg(event.target.value)} />
                  </FormControl>

                  {/* <ReCAPTCHA
          sitekey="6LdQYIkmAAAAAKqMPKyIP10iPgxSQdHcUfWEM613"
          // size="invisible"
          ref={recaptchaRef}
        /> */}

                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="Submitting"
                    bg="#9e2a2b"
                    color="white"
                    mt={4}
                    mb={4}
                    _hover={{
                      bg: '#611415',
                      color: 'white',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Card>
          </Box>
        </Box>
      </SimpleGrid>



      <FooterItem />
    </Box>
    </motion.div>
  )
}

export default International
