import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  VStack,
  Link ,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import cardImg from "../../img/card-img.jpg";
import { useNavigate } from "react-router-dom";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { useLocation } from "react-router-dom";

function PaymentSummery() {
  const navigate = useNavigate();
  const [payment, setpayment] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [total, setTotal] = useState(0.0);
  const [items, setItems] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    loadItem();
    getbase64Payment();
  }, []);

  const loadItem = () => {
    console.log(sessionStorage.getItem("sessionId"));
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            setItems(object.data.data);
            setTotal(object.data.sub_total);
            //console.log(object.data);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const [base64Payments, setBase64Payments] = useState("");

  // useEffect(() => {
  //   const getBase64Payments = () => {
  //     const searchParams = new URLSearchParams(window.location.search);
  //     const planText =
  //       searchParams.get("invoiceNumber") +
  //       "|" +
  //       searchParams.get("chargeTotal");
  //     const publicKey = `-----BEGIN PUBLIC KEY-----
  //     MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTeY7rHnh1tRmnKR5b+H+HI81t
  //     eqpG8Ap8kamndxpt7ZP0xlT6EDvRCx5YTXIvj9iAL3GRZgmDbPTQZW04MNke+T2j
  //     fmGyxXROcwE+nZY+rbBOosBqd43S8rNBXqwc+Qhyx+d4lnFvnkosw/0E7ZwvlCRA
  //     L3f02ZO1ygsNmRD8ewIDAQAB
  //     -----END PUBLIC KEY-----`;
  //     // Encrypting the planText
  //     const encrypted = CryptoJS.AES.encrypt(planText, publicKey).toString();
  //     return btoa(encrypted);
  //   };

  //   setBase64Payments(getBase64Payments());
  // }, []);

  //onsole.log(base64Payments);
  const getbase64Payment = () => {
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.getEncrptedKey;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          // session_id: sessionStorage.getItem("sessionId"),
          ref_no: searchParams.get("invoiceNumber"),
          payment: searchParams.get("chargeTotal"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            // window.location.reload();
            setpayment(object.data.key);
            console.log(object);
          } else {
            console.log(object);
          }
        }).catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
    // navigate("/healthchecks/success");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submission logic here
    // You can send the form data to a backend API or perform any other required action
    console.log("Form submitted:", {
      //   name,
      //   nic,
      //   phoneNumber,
      //   date,
      //   email,
      //   time,
    });
  };

  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/WebsiteStrip1.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Paymeny Summery
        </Text>
      </Box>

      <Box p={4} bg="gray.100" borderRadius="md" shadow="md">
        <Grid gap={4} templateColumns={["1fr", "1fr", "2fr 2fr"]}>
          <Card>
            <Heading
              mb="10px"
              pl="20px"
              pt="10px"
              fontSize="24px"
              color="#F8D107"
            >
              Billing Details
            </Heading>
            <Box mx="auto" width="100%" pr={6} pl={6}>
              {/* <form onSubmit={handleSubmit}> */}

              {/* <FormControl mb="10px">
                <FormLabel>Name</FormLabel>
                <Flex>
                  <Input
                    mt="5px"
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                    required
                    disabled
                  />
                </Flex>
              </FormControl> */}

              {/* <FormControl mb="10px">
                <FormLabel>Address</FormLabel>
                <Textarea
                  // value={value}
                  // onChange={handleInputChange}
                  placeholder="Address"
                  size="sm"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormControl> */}

              <FormControl mb="10px">
                <FormLabel>Mobile Number</FormLabel>
                <Input
                  type="tel"
                  value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Phone Number(0XXXXXXXXX)"
                  onChange={(e) => setMobile(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  disabled
                />
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Please enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={searchParams.get("merchantCustomerEmail")}
                  //   onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled
                />
              </FormControl>
              {/* </form> */}
            </Box>
          </Card>

          <Card>
            <Heading
              mb="10px"
              pl="20px"
              pt="10px"
              fontSize="24px"
              color="#F8D107"
            >
              Order Summery
            </Heading>

            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Order No</Td>
                    <Td isNumeric>#{searchParams.get("invoiceNumber")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Payment Date</Td>
                    <Td isNumeric>{searchParams.get("transactionDateTime")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Total Payable(Rs)</Td>
                    <Td isNumeric>
                      {parseFloat(searchParams.get("chargeTotal")).toFixed(2)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <Box bg="white" p={4} borderRadius="md">
              <Flex flexDirection="column" justify="flex-end">
                {/* <Text fontWeight="bold" mb={2} ml="auto">
                  Total Payable(Rs): {total.toFixed(2)}
                </Text> */}
                <form
                  action="https://webxpay.com/index.php?route=checkout/billing"
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="first_name"
                    value={searchParams.get("fname")}
                  />
                  <input
                    type="hidden"
                    name="last_name"
                    value={searchParams.get("lname")}
                  />
                  <input
                    type="hidden"
                    name="email"
                    value={searchParams.get("merchantCustomerEmail")}
                  />
                  <input
                    type="hidden"
                    name="contact_number"
                    value={searchParams.get("merchantCustomerPhone")}
                  />
                  <input
                    type="hidden"
                    name="address_line_1"
                    value={searchParams.get("address")}
                  />
                  {/* <input
                    type="hidden"
                    name="address_line_2"
                    value={"Battaramulla"}
                  /> */}
                  {/* <input type="hidden" name="city" value={"colombo"} />
                  <input type="hidden" name="state" value={"western"} />
                  <input type="hidden" name="postal_code" value={"10000"} />
                  <input type="hidden" name="country" value={"Sri Lanka"} /> */}
                  <input type="hidden" name="process_currency" value={"LKR"} />
                  <input type="hidden" name="cms" value={"PHP"} />
                  <input
                    type="hidden"
                    name="secret_key"
                    value={"025ecb4e-7984-406f-9405-806229842c04"}
                  />
                  <input type="hidden" name="payment" value={payment} />
                  <input type="hidden" name="payment_gateway_id" value={"35"} />

                  <Button colorScheme="teal" value="Pay Now" type="submit">
                    Continue to Secure Payment
                  </Button>
                </form>
                {/* <link as="https://nawalokacarelabs.com/">Test</link> */}
              </Flex>
            </Box>
          </Card>
        </Grid>
      </Box>
      <FooterItem />
    </Box>
  );
}

export default PaymentSummery;
