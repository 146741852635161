import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, GridItem, Heading, Input, Link, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import { MdArrowForward, MdCheckCircle, MdSettings } from 'react-icons/md';
import LatestItems from '../../components/LatestItems';
import LatestItems1 from '../../components/LatestItems1';

function Dental() {

  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '21');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };



  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/fertlity-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Dental Unit
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Dental Unit</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0770 700 077{'\n'}
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Box position="relative">
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box textAlign={'justify'}>
        <Text mb={2} whiteSpace={"pre-line"}>
        The Dental unit of Nawaloka Hospital is committed to delivering high-quality care to all
        patients. International benchmarks and standards are adhered to, providing the highest
        quality of service. A full range of oral care services for patients of all age groups are
        offered by a team of experienced medical personnel.
        {'\n'}{'\n'}
        In line with the patient-centric policy adopted by the hospital, the best and most modern
        equipment has been invested in to ensure that you will have the best service at all times.
        Furthermore, the hospital brings together a team of dental specialists with decades
        of collective clinical expertise to provide quality specialized dental care at affordable
        prices.

        </Text>

        <Box mt="40px">
  <Heading size={'sm'} mb={'5'}>The Dental Clinic of Nawaloka Hospital provides the following services.</Heading>
        
        {/* <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
              Is the 3D Lipo Fat & Cellulite reduction treatment painful?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
            The treatment can cause minimal discomfort.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion> */}

      <UnorderedList>
        <ListItem>Accident and emergency care for teeth, mouth, jaws & face</ListItem>
        <ListItem>Dental care for children and Adults</ListItem>
        <ListItem>Dietary advice and oral hygiene instructions</ListItem>
        <ListItem>Preventive care for early childhood caries and gum disease</ListItem>
        <ListItem>Special care for pregnant mothers</ListItem>
        <ListItem>Screening and identification of orofacial lesions</ListItem>
        <ListItem>Permanent restorations with the latest technology</ListItem>
        <ListItem>Endodontic treatment with the latest technology(nerve fillings)</ListItem>
        <ListItem>Crown and bridge work with the latest development</ListItem>
        <ListItem>Smile designing and aesthetic restorations</ListItem>
        <ListItem>Teeth whitening with many cosmetic options</ListItem>
        <ListItem>Dental implants and facial implants</ListItem>
        <ListItem>Removable dentures and fixed prostheses with advanced treatment modalities
Correction of malocclusions with fixed/removable appliances</ListItem>
        <ListItem>Surgical removal of impacted/wisdom teeth under local/general anesthesia
Surgical removal of cystic lesions of jaws</ListItem>
        <ListItem>Surgical reduction of facial bone fractures</ListItem>
        <ListItem>Surgical correction of jaw deformities</ListItem>
        <ListItem>Surgical correction of congenital abnormalities of mouth jaws and face (e.g. cleft
lip/palate)</ListItem>
        <ListItem>Treatment for cancers/benign tumors of the orofacial region</ListItem>
        <ListItem>Biopsies</ListItem>
        <ListItem>Scar reduction and cosmetic treatments</ListItem>
      </UnorderedList>

</Box>
        </Box>  

      </Flex>
      </Grid>
      </Box>
      
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems1/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default Dental
