import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, GridItem, Heading, Input, Link, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import { MdArrowForward, MdCheckCircle, MdSettings } from 'react-icons/md';
import LatestItems from '../../components/LatestItems';
import LatestItems1 from '../../components/LatestItems1';
import LatestItems3 from '../../components/LatestItems3';

function CancerCare() {

  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '03');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };



  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/fertlity-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Cancer and Blood Disease Centre
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Cancer and Blood Disease Centre</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0742 947 257{'\n'}
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Box position="relative">
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box textAlign={'justify'}>

        <Text mb={2} whiteSpace={"pre-line"}>
        Nawaloka Hospital has an excellent Heamoto Oncology Unit with a state-of-the-art Centre Chemotherapy Unit, 
        dedicated clinical Hematologists and Oncologists, and well-trained nursing staff.
        {'\n'}
        </Text>
        <Text mb={2} whiteSpace={"pre-line"}>
        The Nawaloka Centre for Cancer and Blood Disease aims to provide patients with Blood Cancer high-quality 
        diagnostics and treatment based on the latest international standards at an affordable price.
        {/* {'\n'} */}
        </Text>
        <Text mb={2} whiteSpace={"pre-line"}>
        The Nawaloka Centre for Cancer and Blood Disease has been helping patients win the
        fight against cancer using advanced technology and a personalized approach, providing
        our patients with high-quality diagnostics and treatment based on the latest international
        standards, all at an affordable price.
        {/* {'\n'} */}
        </Text>

        <Box mt="40px">
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What is Multiple Myeloma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text whiteSpace={"pre-line"}>
      Multiple Myeloma(MM) is a type of blood cancer that affects plasma cells, a type of
      white blood cell in the bone marrow that specializes in producing antibodies. In MM, the
      malignant plasma cells overtake the bone marrow and prevent the production of normal
      blood cells and antibodies. MM plasma cells produce an abnormal antibody which can
      damage the kidneys and bones.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the Symptoms of Multiple Myeloma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
        <ListItem>Bone fractures or pain</ListItem>
        <ListItem>Fatigue due to low blood counts</ListItem>
        <ListItem>Symptoms due to abnormal kidney function Recurrent infections</ListItem>
        <ListItem>Easy bruising or bleeding</ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What tests are required to make a diagnosis of Multiple Myeloma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
          <ListItem>Blood tests</ListItem>
          <ListItem>X Rays</ListItem>
          <ListItem>
            Bone marrow aspiration and trephine biopsy. (BMAT) BMAT involves inserting a needle
            into the bone and extracting the marrow as well as obtaining a 2cm piece of bone for
            examination in the laboratory. These tests are required to make a diagnosis of MM and
            to determine the prognosis.
          </ListItem>
        </UnorderedList>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What is the prognosis for Patients with Multiple Myeloma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
        <UnorderedList>
            <ListItem>The prognosis of MM depends on the patient's age and specific abnormalities in the
            genes of the MM plasma cells. These can be detected on the bone marrow tests.
            </ListItem>
            <ListItem>
              Many patients with MM can expect several years of disease control with a good quality
              of life
            </ListItem>
          </UnorderedList>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What is the Treatment for Multiple Myeloma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <UnorderedList>
            <ListItem>
              The treatment options for MM have improved significantly over the last 10 years.
            </ListItem>
            <ListItem>
            The latest treatment for MM includes new drugs which are very well tolerated by
            patients and can be delivered in the outpatient setting.
            </ListItem>
            <ListItem>
            Patients with MM who are under 65 years of age will benefit from a stem cell transplant
            after they have responded to the initial treatment.
            </ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What is Lymphoma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text whiteSpace={"pre-line"}>
      Lymphoma occurs when abnormal cells within the lymphatic system of the body grow in
      an uncontrolled way. The lymphatic system is part of the body's immune system. It
      includes tissues such as the bone marrow, tonsils, and spleen, as well as lymphatic
      vessels and lymph nodes. About 70-80% of lymphomas develop in the lymph nodes. The
      rest develop outside the lymph nodes and can occur in other parts of the body.
      {/* {'\n\n'} */}
      </Text>
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the common symptoms of Lymphoma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
              <ListItem>
              Swollen or enlarged lymph nodes in the neck, underarm, groin, or stomach
              </ListItem>
              <ListItem>
              Persistent, unexplained fever
              </ListItem>
              <ListItem>
              Excessive sweating at night
              </ListItem>
              <ListItem>
              Unexplained weight loss
              </ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What tests are required to make a diagnosis of Lymphoma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
              <ListItem>
              Blood tests
              </ListItem>
              <ListItem>
              Lymph node biopsy: This involves taking a sample of tissue from a swollen lymph node
              for examination under a microscope
              </ListItem>
              <ListItem>
              Xrays, CT scans, and/or PET scans
              </ListItem>
              <ListItem>
              Taking a sample of bone marrow for examination under a microscope-this is called a
              bone marrow aspiration/biopsy.
              </ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What is the prognosis for Patients with Lymphoma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
              <ListItem>
              The prognosis depends on the type of lymphoma, the stage, the patient's age, and the
              results of other blood tests and scans.
              </ListItem>
              <ListItem>
              Many patients with lymphoma can expect to be cured while in those types of
              lymphoma where cure is not possible, a high percentage of patients achieve long
              periods of disease control.
              </ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What is the Treatment for Lymphoma?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <UnorderedList>
              <ListItem>
              Treatment for lymphoma depends on the type of lymphoma, the stage of the
              disease, and the patient's general health.
              </ListItem>
              <ListItem>
              Treatment options can include chemotherapy, radiotherapy, and antibody drugs which
              specifically, destroy lymphoma cells.
              </ListItem>
      </UnorderedList>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>
        </Box>  

      </Flex>
      </Grid>
      </Box>
      
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems3/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default CancerCare
