import * as types from './types';

export const getspecializationList = payload => {
    return {
      type: types.GET_SPECIALIZATIONS,
      payload,
    };
  };
export const getDoctorList = payload => {
    return {
      type: types.GET_DOCTOR_LIST,
      payload,
    };
  };
export const getDoctorSessions = payload => {
    return {
      type: types.GET_DOCTOR_SESSIONS,
      payload,
    };
  };