import { Box, Button, Card, CardHeader, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Image, Input, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, Textarea, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { useEffect, useRef } from "react";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { useForm } from 'react-hook-form';
import { MdCheckCircle, MdStar } from 'react-icons/md';

function Physiotherapy() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [name, setname] = useState(null);
  const [email, setEmail] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [requirement, setRequirement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const gridItems = [
    {
      imageSrc: 'https://media.istockphoto.com/id/1400492047/photo/therapist-putting-physio-tape-on-young-woman.webp?b=1&s=170667a&w=0&k=20&c=uf6UJtuQmlCBMWfA7YKPmAZtYTiCroLC2h_3QPndLrM=',
      title: 'Pain management',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    },
    {
      imageSrc: 'https://media.istockphoto.com/id/1199908661/photo/physiotherapist-treatment-patient-she-holding-patients-hand-shoulder-joint-treatment.jpg?s=612x612&w=0&k=20&c=yghgsRCfhifTxzIS8UqlHIxpyyHDUNXkfqwQHABDRuY=',
      title: 'Physiotherapy Rehabilitation',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    },
    {
      imageSrc: 'https://img.freepik.com/free-photo/medical-assistant-helping-patient-with-physiotherapy-exercises_23-2149071459.jpg',
      title: 'Therapeutic Treatments',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    },
    {
      imageSrc: 'https://media.istockphoto.com/id/1384499192/photo/young-physiotherapist-exercising-with-senior-patient-in-a-physic-room.webp?b=1&s=170667a&w=0&k=20&c=_ITAfaHEN2lfWe3rvUKFTUDyBT-in4NkoJhVw606uro=',
      title: 'Antenatal and Postnatal Physiotherapy care',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, laboriosam odio esse labore atque eum. Officiis esse omnis delectus asperiores numquam dignissimos, excepturi perferendis ut quis vel illo, consequatur et.',
    },
  ];

  const serviceItems = [
    {},
  ];
  const onSubmit = async (data) => {
    setLoading(true);

    try {

      sendmail();

      reset(); // Clear form fields
    } catch (error) {
      // Display error toast
      // toast({
      //   title: 'Error',
      //   description: 'An error occurred. Please try again later.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    }

    setLoading(false);
  };

  const sendmail = () => {
    // setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {

      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.sendmailPhysio;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          telephone: telephone,
          requirement: requirement,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {

            console.log(object);
            if (!object.error) {
              toast({
                title: 'Success',
                description: object.msg,
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
             // window.location.reload();
            } else {
              toast({
                title: 'Error',
                description: object.msg,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      //setIsLoading(false);
      setTelephone('');
      setEmail('');
      setname('');
      setRequirement('');
      

    }
  };

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  return (
    <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Physiotherapy
        </Text>
      </Box>

      <Box mt={5} pl={['10','20']} pr={['10','20']} textAlign={'justify'}>
        <Text fontSize={'lg'}>
        Discover the transformative power of Physiotherapy at Nawaloka Hospitals Colombo. Our skilled and caring 
        therapists are dedicated to helping you recover, heal, and thrive. With personalized treatments tailored 
        to your needs, we utilize advanced techniques and state-of-the-art equipment to optimize your physical 
        well-being. Take control of your health today and experience the difference of our exceptional physiotherapy care.
        </Text>
      </Box>
      
      <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
      
      <Grid
      templateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
      gap={6}
      mt={6}
      mb={5}
      pl={5}
      pr={5}
      width="80%"
      textAlign="center"
    >
      {gridItems.map((item, index) => (
        <GridItem key={index} w="100%" h="280" position="relative">
          <img src={item.imageSrc} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%' }} />
          <Box
            // onClick={() => onOpen(index)}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            background="rgba(249, 105, 14, 1.0)"
            opacity="0.5"
            _hover={{
              background: 'rgba(244, 105, 14, 0.3)',
              cursor: 'pointer',
            }}
          ></Box>
          <Box
            // onClick={() => onOpen(index)}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="#ffffff"
            fontWeight="bold"
            fontSize="20px"
            textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)"
            _hover={{
              cursor: 'pointer',
            }}
          >
            {item.title}
          </Box>
        </GridItem>
      ))}

      {selectedModalIndex !== null && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{gridItems[selectedModalIndex].title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{gridItems[selectedModalIndex].description}</ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              {/* <Button variant="ghost">Secondary Action</Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Grid>

      </Box>

      <Grid templateColumns={['1fr', '1fr', '2fr 1fr', '2fr 1fr']} flexDirection={['row', 'column']} pl={['5','10']} pr={['5','10']}>
        <Box width={'100%'} p={['5','8']} order={[2, 1]}>
            <Heading size={'lg'}>Services Provided By Nawaloka Physiotherapy Department</Heading>
            <List spacing={3} mt={"50px"} textAlign={'justify'}>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Pain management(Joint and muscles):</strong> Experience pain-free living with our expert joint and 
              muscle pain management at Nawaloka Hospital Colombo. Our specialized treatments and personalized care 
              will alleviate discomfort, improve mobility, and restore your quality of life. Trust our compassionate 
              team to help you regain control and find lasting relief.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Physiotherapy Rehabilitation for Neurological Conditions: </strong>Rediscover independence with our 
              neuro rehab at Nawaloka Hospital. Our dedicated physiotherapists specialize in stroke, Parkinson's, 
              and paralysis. Regain mobility, enhance function, and improve your quality of life.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Physiotherapy Rehabilitation for such as Osteoarthritis, Rheumatoid Arthritis: </strong> 
              Experience targeted relief with our arthritis rehab at Nawaloka Hospital. Our expert physiotherapists 
              specialize in osteoarthritis and rheumatoid arthritis, reducing pain and improving joint mobility. 
              Regain your freedom and quality of life with our compassionate care.
              </Box>
            </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Physiotherapy Rehabilitation for Orthopaedic Conditions Pre and Post-Operative: </strong> 
              Enhance your orthopaedic recovery with our tailored physiotherapy rehab at Nawaloka Hospital. Our expert 
              therapists support pre and post-operative care, optimizing healing and restoring function.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Therapeutic Treatments for Musculoskeletol Conditions: </strong>Experience effective 
              musculoskeletal treatments at Nawaloka Hospital. Our dedicated therapists alleviate pain and 
              improve mobility. Trust us for compassionate care and renewed physical well-being.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Physiotherapy Rehabilitation for Sports Injuries: </strong> Revive from sports injuries with our 
              targeted physiotherapy rehab at Nawaloka Hospital. We specialize in ligament injuries, sprains, and strains, 
              restoring your performance and preventing future setbacks. Trust us for a successful sports injury recovery.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Physiotherapy Rehabilitation for Paediatric Conditions: </strong> Empower your child's development 
              with our pediatric physiotherapy rehab at Nawaloka Hospital. We specialize in conditions like cerebral 
              palsy, Down's syndrome, and Erb's palsy, fostering mobility and overall growth.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Physiotherapy Rehabilitation for Geriatrics: </strong> Experience specialized geriatric physiotherapy 
              for enhanced well-being. Improve balance, coordination, and gait for greater independence and a higher 
              quality of life. Our compassionate care supports senior rehabilitation.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Antenatal and Post Natal Physiotherapy Care: </strong> Specialized antenatal and postnatal 
              physiotherapy care for a healthy motherhood journey. Personalized treatments address discomfort and 
              promote postpartum recovery.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Chest Physiotherapy for Inward and OPD Patients: </strong> Experience specialized chest physiotherapy 
              for inpatients and outpatients (OPD) at our facility. Our tailored treatments effectively address respiratory 
              issues, promote lung health, and improve overall well-being.
              </Box>
              </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Inward Physiotherapy Treatment and Physiotherapy for Covid Patients: </strong>Comprehensive physiotherapy treatment 
              for inpatients, including specialized care for COVID-19 patients. Our therapies focus on promoting 
              recovery, restoring function, and enhancing overall well-being.
              </Box>
            </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdCheckCircle} color='#FF7F50' />
              <Box flexGrow={1}>
              <strong>Inhalation Therapy for OPD and Inward Patients: </strong>Experience specialized 
              inhalation therapy for both OPD and inpatient patients. Our treatments are designed to effectively address 
              respiratory conditions, promote optimal lung function, and enhance overall well-being. 
              </Box>
            </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdStar} color='#FF7F50' />
              <Box flexGrow={1}>
              Pulmonary fuction test for the patients having with chest and orthopaedic conditions and the people are to undergone various medical purposes.
              </Box>
            </Flex>
            </ListItem>
            <ListItem>
            <Flex>
              <ListIcon as={MdStar} color='#FF7F50' />
              <Box flexGrow={1}>
              Special treatment we are having - <strong>Shock Wave Therapy</strong>
              </Box>
            </Flex>
            </ListItem>
          </List>
        </Box>
        <Box width={'100%'} padding={['2','2']} mt={['2','0','10']} order={[1, 2]}>
            
              <Card width="100%" p="10px" boxShadow={'2xl'} mt="10px" mb="10px" backgroundColor="#FF7F50">
              <CardHeader><Heading size="lg" color="white">Book a Consultation</Heading></CardHeader>
              <Box width="90%" mx="auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl id="name" mb={4} isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input required type="text" {...register('name')} bg="white" value={name} onChange={(event) => setname(event.target.value)}
                    />
                  </FormControl>

                  <FormControl id="telephone" mb={4} isRequired>
                    <FormLabel>Telephone</FormLabel>
                    <Input required value={telephone} {...register('telephone')} bg="white" onChange={(event) => setTelephone(event.target.value)} type="number" />
                  </FormControl>

                  <FormControl id="email" mb={4} isRequired>
                    <FormLabel>E-mail</FormLabel>
                    <Input required value={email} {...register('email')} bg="white" onChange={(event) => setEmail(event.target.value)} type="email" />
                  </FormControl>

                  <FormControl id="requirement" mb={4} isRequired>
                    <FormLabel>Requirement</FormLabel>
                    <Textarea required {...register('requirement')} bg="white" defaultValue={requirement} onChange={(event) => setRequirement(event.target.value)} resize="vertical" />
                  </FormControl>

                  {/* <ReCAPTCHA
          sitekey="6LdQYIkmAAAAAKqMPKyIP10iPgxSQdHcUfWEM613"
          // size="invisible"
          ref={recaptchaRef}
        /> */}

                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="Submitting"
                    bg="#9e2a2b"
                    color="white"
                    mt={4}
                    mb={4}
                    _hover={{
                      bg: '#611415',
                      color: 'white',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}

                  >
                    Request Appoinment
                  </Button>
                </form>
              </Box>
            </Card>

            <Card width="100%" p="10px" boxShadow={'2xl'} mt="20px" mb="10px" backgroundColor="#FF7F50">
              <CardHeader><Heading size="md" color="white">Contact Details</Heading></CardHeader>
                <VStack spacing={0} alignItems="center">
                    <Text fontSize="lg" fontWeight={'semibold'} color="whiteAlpha.800" whiteSpace={'pre-line'}>
                    Direct Contact: 0115 577 102{'\n'}
                    </Text>
                    <Text fontSize="lg" textAlign="center" fontWeight={'semibold'} color="whiteAlpha.800" whiteSpace={'pre-line'}>
                    Email: physiotherapy@nawaloka.com
                    </Text>
                </VStack>   
            </Card>
        </Box>
      </Grid>

        <FooterItem/>
        </Box>
    </div>
  )
}

export default Physiotherapy
