import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
    <Helmet>
        <title>Nawaloka Hospitals PLC</title>
        <link rel="icon" href="../img/favicon.ico" />

        <meta property="og:title" content="Nawaloka Hospitals PLC" />
        <meta property="og:description" content="Leading healthcare institution since 1985, offering high-quality services and advanced technology." />
        <meta property="og:image" content="../img/favicon.ico" />
        
      </Helmet>
    <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
