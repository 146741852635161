import {
  Box,
  Flex,
  Grid,
  HStack,
  Heading,
  IconButton,
  Img,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SliderItem from "../components/SliderItem";
import PromoVideo from "../components/PromoVideo";
import FooterItem from "../components/FooterItem";
import Navbar from "../components/Navbar";
import Menubar from "../components/Menubar";
import { FaApple, FaGooglePlay, FaWhatsapp } from "react-icons/fa";
import { MdStar, MdCheck } from "react-icons/md";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import Carousel from "../components/Carousel";
import { isEmpty, isSet } from "lodash";
import AnniversaryStip from "../components/AnniversaryStip";
import OngoingPromo from "../components/OngoingPromo";

//  import { Box, Flex, Grid, HStack, Heading, IconButton, Image, Text, VStack } from '@chakra-ui/react'
// import React from 'react'
// import Header from '../components/Header'
// import SliderItem from '../components/SliderItem'
// import PromoVideo from '../components/PromoVideo'
// import FooterItem from '../components/FooterItem'
// import Navbar from '../components/Navbar'
// import Menubar from '../components/Menubar'
// import { FaApple, FaGooglePlay, FaWhatsapp } from 'react-icons/fa'
// import { MdStar,  MdCheck } from "react-icons/md";
// import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
// import Carousel from '../components/Carousel'

function LandingPage() {
  const generateUniqueId = () => {
    const timestamp = Date.now().toString();
    const randomNum = Math.floor(Math.random() * 1000).toString();
    return timestamp + randomNum;
  };
  useEffect(() => {
    if (sessionStorage.getItem("sessionId") == null) {
      sessionStorage.setItem("sessionId", generateUniqueId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RoundBorderedItem = ({ icon, label }) => {
    return (
      <Flex
        align="center"
        p={4}
        border="1px"
        borderColor="gray.200"
        borderRadius="full"
      >
        {icon}
        <Box ml={2}>{label}</Box>
      </Flex>
    );
  };

  return (
    <Box>
      <Header />
      <Carousel />
      {/* <OngoingPromo/> */}
      {/* <AnniversaryStip/> */}
      <PromoVideo />
      <Box bg="#1A908F">
        <Box py={10}>
          <Heading mt="-10px" size="lg" color="white" textAlign="center">
            Download your Mobile Application Now
          </Heading>
          <Flex justifyContent="center" mt={8} mb={15}>
            <IconButton
              as="a"
              href="https://play.google.com/store/apps/details?id=com.health_assist"
              target="_blank"
              rel="noopener noreferrer"
              // colorScheme="blue"
              bg="black"
              color="white"
              aria-label="Get it on Google Play"
              icon={
                <>
                  <FaGooglePlay />
                  <span style={{ marginLeft: "5px" }}>Google Play</span>
                </>
              }
              size="md"
              mr={2}
              px={4}
              title="Google Play"
            />

            <IconButton
              as="a"
              href="https://apps.apple.com/lk/app/nawalokacare/id1528934228"
              target="_blank"
              rel="noopener noreferrer"
              // colorScheme="gray"
              bg="black"
              color="white"
              aria-label="Download on the App Store"
              icon={
                <>
                  <FaApple />
                  <span style={{ marginLeft: "5px" }}>App Store</span>
                </>
              }
              size="md"
              ml={2}
              px={4}
            />
          </Flex>
        </Box>
      </Box>
      <Box>
        <Flex justifyContent="flex-end" w="100%" h="250px">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15842.979508475273!2d79.853334!3d6.9210722!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2591514038547%3A0xa12560c4d858fc3f!2sNawaloka%20Hospitals%20PLC!5e0!3m2!1sen!2slk!4v1684472988384!5m2!1sen!2slk&z=20"
            width="100%"
            height="100%"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Flex>
      </Box>
      <Grid
        flexDirection={["column", "row"]}
        mt="50px"
        mb="50px"
        p={2}
        // ml="30px"
        justifyItems="center" // Center the items horizontally
        alignItems="center"
        templateColumns={["1fr", "1fr", "1fr 1fr 1fr"]}
      >
        <HStack align="center" spacing={2}>
          <IconButton
            // colorScheme="yellow"
            bg="#FFC113"
            aria-label="0115 577 111"
            borderRadius="full"
            icon={<PhoneIcon />}
            size="lg"
            color="white"
          />
          <Flex flexDirection={"column"}>
            <Text ml={2} color="#1A908F" fontSize="24px" fontWeight="bold">
              0115 577 111
            </Text>
            <Text ml={2} color="#43467B" fontSize="18px">
              Call us on our 24/7 Hotline
            </Text>
          </Flex>
        </HStack>
        <HStack align="center" spacing={2}>
          <IconButton
            // colorScheme="yellow"
            bg="#FFC113"
            aria-label="Call Segun"
            borderRadius="full"
            icon={<FaWhatsapp />}
            size="lg"
            color="white"
          />
          <Flex flexDirection={"column"}>
            <Text ml={2} color="#1A908F" fontSize="24px" fontWeight="bold">
              Live Chat
            </Text>
            <Text ml={2} color="#43467B" fontSize="18px">
              Contact Online support
            </Text>
          </Flex>
        </HStack>
        <HStack align="center" spacing={2}>
          <IconButton
            // colorScheme="yellow"
            bg="#FFC113"
            aria-label="Call Segun"
            borderRadius="full"
            icon={<EmailIcon />}
            size="lg"
            color="white"
          />
          <Flex flexDirection={"column"}>
            <Text ml={2} color="#1A908F" fontSize="24px" fontWeight="bold">
              nawaloka@slt.lk
            </Text>
            <Text ml={2} color="#43467B" fontSize="18px">
              Contact us through mail
            </Text>
          </Flex>
        </HStack>

        {/* <!--       </Grid>
      <FooterItem /> --> */}
      </Grid>

      <Flex p={2} justifyContent="center" alignItems="center" mb={5}>

        <Img src={require("../img/SLC-Banner1.png")} alt="SLC Banner" />
      </Flex>

      <FooterItem />
    </Box>
  );
}

export default LandingPage;
