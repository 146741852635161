import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import Header from "../components/Header";
import {
  ArrowForwardIcon,
  EmailIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import FooterItem from "../components/FooterItem";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { JsonToForm, JsonToParams } from "../utils";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import * as actions from "./store/actions";
import Preloader from '../components/Preloader';

export default function Emergency() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(null);
  const [spList, setSpList] = useState([]);
  const [doctorName, setDoctorName] = useState(null);
  const [spId, setSpId] = useState("*");
  const [isLoading, setIsLoading] = useState(false);

  //const doctorList = useSelector(doctorSelector);

  useEffect(() => {
    const body = {
      // key: "12345",
    };
    getSpList(Methods.POST, JsonToForm(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSpList = (method, body) => {
    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.specializations;
    fetch(URL, {
      method,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      body,
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          setSpList(object.specialityList);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  //--------------------------------------------------------------------------------------------------------------

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getDoctorList();
    }
  };

  const getDoctorList = () => {
    setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {
      sessionStorage.setItem('selectedDate', selectedDate);
      sessionStorage.setItem('spId', spId);
      sessionStorage.setItem('doctorName', doctorName);
      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          sp_id: spId,
          date: selectedDate,
          name: doctorName,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {
            // const payload = {
            //   data: {
            //     sp_id : spId,
            //     date : selectedDate,
            //     name : doctorName,
            //   },
            // };
            const payload = {
              data: object.doctort_list,
            };

            dispatch(actions.getDoctorList(payload));
            // Link
            navigate("/doctorsearch");
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);

    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <Box>
          <Header />
          {/* <Heading mt={10} mb={10} ml={10}>
          Doctor Channeling
        </Heading> */}

          <Box
            backgroundImage={require('../img/pagecovers/channeling-strip.png')}
            backgroundSize="cover"
            backgroundPosition="center"
            height="200px"
            display="flex"
            mt="10px"
            alignItems="center"
          // justifyContent="center"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              ml="40px"
              color="white"
              textShadow="0 2px 4px rgba(0,0,0,0.5)"
            >
              Doctor Channeling
            </Text>
          </Box>

          <Grid templateColumns={['1fr', '1fr', '2fr 2fr', '2fr 2fr']} flexDirection={['row', 'column']} pl={['2','5']} pr={['2','5']}>
            
          <Flex 
          p={2}
          mt="20px"
          mb="30px"
          maxWidth="600px"
          alignItems="right"
          mx="auto"
          flexDirection="column"
          width="100%"
          >
          <Card 
          borderRadius="10px 10px 10px 10px"
          boxShadow="0 10px 8px rgba(0, 0, 0, 0.1)"
          position="relative"
          >
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Box>
              <Flex justifyContent="center">
                <Heading size='md' mb={8} textTransform='uppercase'>
                  For Channeling and More Details
                </Heading>
                </Flex>
                <Flex justifyContent="center">
                <Badge colorScheme='purple' p='4' alignContent={'center'} borderRadius={6} fontSize='2xl'>
                  0115 - 777 888
                </Badge>
                </Flex>
              </Box>
            </Stack>
          </CardBody>
        </Card>
          </Flex>

          <Flex
            background="#1A908F"
            p={5}
            mt="20px"
            mb="30px"
            maxWidth="600px"
            borderRadius="8px"
            alignItems="left"
            mx="auto"
            flexDirection="column"
            width="100%"
          >
            <FormControl onKeyPress={handleKeyPress}>
              {/* <FormLabel>First name</FormLabel> */}
              <Input
                backgroundColor={"white"}
                mt="5px"
                placeholder="Doctor's Name"
                mb={2}
                onChange={(event) => setDoctorName(event.target.value)}
              />
              <Select
                backgroundColor={"white"}
                placeholder="Select Speciality"
                mt="5px"
                onChange={(event) => setSpId(event.target.value)}
                value={spId}
              >
                {/* <option>ENT</option> */}
                {spList.map((option) => (
                  <option key={option.sp_id} value={option.sp_id}>
                    {option.sp_name}
                  </option>
                ))}
                {/* <option>Cardiac</option> */}
              </Select>
              <Box>
                <Input
                  type="date"
                  value={selectedDate || ""}
                  onChange={handleDateChange}
                  px={3}
                  py={2}
                  borderRadius="md"
                  borderColor="gray.300"
                  background="white"
                  top="15px"
                  mb={65}
                  _hover={{ borderColor: "gray.400" }}
                  _focus={{
                    outline: "none",
                    borderColor: "blue.500",
                    boxShadow: "0 0 0 2px rgba(66, 153, 225, 0.6)",
                  }}
                />
                {/* {selectedDate && (
                  <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
                  top="50px"
                  left="30px">
                    Clear
                  </Button>
                )} */}
              </Box>
            </FormControl>

            <Stack>
              {/* <Link as={RouterLink} to="/doctorsearch"> */}
              <Button
                leftIcon={<SearchIcon />}
                bg="#0E5453"
                color="white"
                variant="solid"
                // top="170px"
                // left="-190px"
                _hover={{
                  bg: "#004242",
                }}
                _focus={{
                  bg: "#004242",
                }}
                onClick={() => getDoctorList()}
              >
                Find Your Doctor
              </Button>
              {/* </Link> */}
            </Stack>
          </Flex>

          </Grid>
          
          <FooterItem />
        </Box>
      )}
    </div>
  );
}
