import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { JsonToForm, JsonToParams } from "../utils";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import { isEmpty, isSet } from "lodash";
// import {CompanyLogo} from '../img/icons/NawalokaPLC.png'

function BookingSummery() {
  const [next, setNext] = useState(null);

  useEffect(() => {
    getNext();
  }, []);

  const getNext = () => {
    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.nextAppointmentNo;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        doc_id: sessionStorage.getItem("doc_id"),
        show_date: sessionStorage.getItem("showDate"),
        t_time: sessionStorage.getItem("tTime"),
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          console.log(object);
          setNext(object);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };
  const payNow = () => {
    const URL = ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.payNow;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        patient_title: sessionStorage.getItem("title"),
        patient_name: sessionStorage.getItem("p_name"),
        mobile_number: sessionStorage.getItem("p_phone"),
        nic: sessionStorage.getItem("p_nic"),
        payment_mode: sessionStorage.getItem("payment_mode"),
        doc_id: sessionStorage.getItem("doc_id"),
        amount: sessionStorage.getItem("amount"),
        booking_date: sessionStorage.getItem("showDate"),
        booking_day: sessionStorage.getItem("sday"),
        booking_time: sessionStorage.getItem("tTime"),
        email: sessionStorage.getItem("p_email"),
        app_no: next,
        foreign: sessionStorage.getItem("nationality"),
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          // console.log(object.data_result.url);
          const URL =
            ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.redirectUrl;
          fetch(URL, {
            method: Methods.POST,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: JSON.stringify({
              url: object.data_result.url,
            }),
          })
            .then((response) => response.json())
            .then((object) => {
              if (object) {
                console.log(object);
                window.location.href = object.data_result.url;
              }
            })
            .catch();
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <Box m="40px">
      <Heading mb="20px">Booking Summery</Heading>
      {/* <Flex alignContent="center" m="20px 0px 0px 20px">
                <Image src={require('../img/NawalokaPLC.png')} alt="Company Logo" w="50px" mr="4" />
                <Heading size="md">Nawaloka Hospitals PLC</Heading>
        </Flex> */}
      <Box
        border="2px"
        borderRadius="10px"
        boxShadow="md"
        borderColor="blackAlpha.400"
      >
        <Flex alignItems="center" m="20px 0px 0px 20px">
          <Image
            src={require("../img/NawalokaPLC.png")}
            alt="Logo"
            maxH="50px"
            mr="10px"
            mt="15px"
          />
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight="bold"
            color="#00A550"
            lineHeight={{ base: "1.2em", md: "normal" }}
            textAlign={{ base: "center", md: "left" }}
          >
            Nawaloka Hospitals PLC
          </Text>
        </Flex>
        <SimpleGrid
          boxShadow="lg"
          borderRadius="md"
          p="10px"
          mt="30px"
          columns={{ base: 1, md: 2 }}
          gap="30px"
        >
          <Box ml="25px" mb="20px">
            <Heading size={"md"} mb={5}>
              Patient Details
            </Heading>
            {/* <Text><strong></strong> </Text>
                <Text><strong></strong> </Text>
                <Text><strong></strong> </Text>
                <Text><strong></strong> </Text> */}

            <TableContainer>
              <Table
                size="sm"
                variant="striped"
                colorScheme="blackAlpha"
                borderRadius="8px"
              >
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{sessionStorage.getItem("p_name")}</Td>
                  </Tr>
                  <Tr>
                    <Td>NIC</Td>
                    <Td>{sessionStorage.getItem("p_nic")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Phone</Td>
                    <Td>{sessionStorage.getItem("p_phone")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{sessionStorage.getItem("p_email")}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <Box>
            <Heading size={"md"} ml="25px" mb={5}>
              Appoinment Details
            </Heading>
            <TableContainer>
              <Table size="md" variant="striped" colorScheme="blackAlpha">
                <Tbody>
                  <Tr>
                    <Td>Doctor Name</Td>
                    <Td>{sessionStorage.getItem("docName")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Specialization</Td>
                    <Td>{sessionStorage.getItem("spName")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Date</Td>
                    <Td>{sessionStorage.getItem("displayDate")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Doctor Arrival Time</Td>
                    <Td>{sessionStorage.getItem("tTime")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Appointment No</Td>
                    <Td>
                      <Tag size={"md"} variant={"outline"} colorScheme="green">
                        {!isEmpty(sessionStorage.getItem("countActivePatient"))
                          ? sessionStorage.getItem("countActivePatient")
                          : 0}
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr fontWeight={"bold"}>
                    <Td>Total Payable (Rs.)</Td>
                    <Td>
                      {(
                        parseFloat(sessionStorage.getItem("amount")) + 399
                      ).toFixed(2)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>

              <Flex justify="flex-end">
                <Button
                  size="md"
                  mt="30px"
                  mr="20px"
                  mb="20px"
                  colorScheme="teal"
                  onClick={() => payNow()}
                >
                  Confirm Appointment
                </Button>
              </Flex>
            </TableContainer>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default BookingSummery;
