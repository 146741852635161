import React from "react";
import { Box, Button, Heading, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

function PromoVideo() {
    return (
      <Box mt="10px" mb="20px">
        {/* <iframe 
        width="100%" 
        height="315" 
        src="https://www.youtube.com/embed/q0mbKsKG-ng" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen>
        </iframe> */}

      <Box
        backgroundImage={require('../img/pagecovers/sigiriyapromo.jpg')}
        backgroundSize="cover"
        backgroundPosition="center"
        // backgroundRepeat="no-repeat"
        height="400px"
        display="flex"
        mt="10px"
        alignItems="center"

      >
      </Box>
        <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="10vh"
        marginTop={{ base: "80px", lg: "50px"}}
        mb={{base: "150px", lg: "120px"}}
        
        >
          <Heading color={"yellow.600"} textAlign="center" fontSize={28} mt="55px" marginBottom={0} fontWeight={"bold"}>Welcome International Patients</Heading>
          <Text color={"gray.600"} fontSize={24} fontWeight={"bold"}>We are open to the world</Text>
          <Text as="p" mb="25px" textAlign={"center"} p={2} ml={{ base: "10px", lg: "300px"}} mr={{ base: "10px", lg: "300px"}} >Affordable Health care in the island paradise is as good as anywhere in the World
                        Convalesce in the most beautiful country, on the beaches, on the hills, in the wildness.
          </Text>
          <Link as={RouterLink} to="/international">
            <Button colorScheme="yellow" fontSize="xl" px={8} borderRadius={20}>
              More
            </Button>
          </Link>
        </Box>
      </Box>
    );
  }
  
  export default PromoVideo;