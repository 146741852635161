import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, Heading, Input, Link, List, ListItem, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import LatestItems from '../../components/LatestItems';
import LatestItems1 from '../../components/LatestItems1';

function FertilityCare() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '318');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/fertlity-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Fertility Care
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "2fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Fertility Care</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>
      {/* <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="5px">Resident Rheumatologist</Heading>
      </Flex>
      <Flex borderWidth="1px" borderRadius="lg" p={4} 
      display="flex" width="100%" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <Avatar size="2xl" src={require('../../img/doctorImages/dr-harindu.jpg')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
        <VStack spacing={0} alignItems="center">
            <Text fontSize="xl" fontWeight="bold" color="white">Dr. Harindu Wijesinghe</Text>
            <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                MBBS IS.L.I, MD [Coll, MCP (UK)]{'\n'}
                Consultant in Rheumatology & Rehabilitation{'\n'}
                Specialist in Sports Medicine
            </Text>
        </VStack>
      </Flex> */}

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0770 700 077{'\n'}
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box textAlign={'justify'}>
        {/* <Text mb={10} whiteSpace={"pre-line"}>
        Pregnancy is a miracle of life, but 1 in 6 couples worldwide face challenges when trying 
        to conceive. Factors like defective egg release, blocked fallopian tubes, or sperm 
        abnormalities can cause infertility. Fortunately, there are simple tests to assess 
        fertility.{'\n'}{'\n'}

        Nawaloka is the only hospital offering immune tests and treatments for individuals 
        experiencing repeated failures in conceiving. Our center uses state-of-the-art technology, 
        including the Invicell bench-top incubator. This advanced equipment ensures the highest 
        success rates for IVF and ICSI procedures in the country.

        </Text> */}

        <Text whiteSpace={"pre-line"}>
        Pregnancy is one of the best wonders of creation. Globally, 1 in 6 couples experience
        problems in conceiving a child. Defective egg release, blockage of the fallopian tube(s),
        abnormalities in the sperm, or a combination of the above can cause infertility. There are
        simple tests to check all these aspects of fertility.
        {'\n'}{'\n'}
        Nawaloka Fertility Centre (NFC) has completed 10 years of dedicated service to childless
        couples in Sri Lanka helping to conceive babies. ‘Triple-witnessing’ of all samples is
        undertaken by the doctor, embryologist, and nurse to prevent any errors in the lab.
        Nawaloka is the only hospital providing immune tests and treatment to those who had
        repeated failures. The centre uses an ultramodern bench-top incubator called ‘Invicell’, the first
        of its kind in the nation, to give the maximum success rate during IVF/ICSI.
        </Text>
        <Text mt={4} mb={2} fontWeight={'semibold'}>
        We offer the following services:
          </Text>
        
        <Text>
        So far, international reports have shown that the babies born through IVF and ICSI are
        as healthy as those conceived naturally. Contrary to the misconception, women do not
        need hospitalization, bed rest, or time off work during IVF. The treatment demands around
        6 visits to the hospital over a period of 5-6 weeks. The injections are also simple to self-
        administer.
        </Text>

        <UnorderedList>
          <ListItem>
            <Text mt={4} fontWeight={'semibold'}>
            Intra Uterine Insemination (1UI)
            </Text>
            <Text>
            Insemination of prepared sperm into the Uterine cavity.
            </Text>
          </ListItem>
          <ListItem>
            <Text mt={4} fontWeight={'semibold'}>
            In Vitro Fertilization (Test Tube Baby) & Embryo Transfer (IVF & ET)
            </Text>
            <Text>
            InVitro Fertilization ie. Mixing of the sperm and egg outside the body in a petridish and
            generation of embryos, and transfer of these embryos into the uterus.
            </Text>
          </ListItem>
          <ListItem>
            <Text mt={4} fontWeight={'semibold'}>
            Intra - Cytoplasmic Sperm Injection (ICSI)
            </Text>
            <Text>
            Injection of a single sperm in a mature egg, outside the body.
            </Text>
          </ListItem>
          <ListItem>
            <Text mt={4} fontWeight={'semibold'}>
            Testicular Biopsy (TB)
            </Text>
            <Text>
            Taking Biopsy of tests to collect sperm from the tests.
            </Text>
          </ListItem>
          <ListItem>
            <Text mt={4} fontWeight={'semibold'}>
            Micro Epididymal Sperm Aspiration (MESA)
            </Text>
            <Text>
            Removal of the sperm from the Epididymis.
            </Text>
          </ListItem>
          <ListItem>
            <Text mt={4} fontWeight={'semibold'}>
            Sperm Bank
            </Text>
            <Text>
            Frozen and screened semen samples which can be used for treating patients with no sperm.
            </Text>
          </ListItem>
        </UnorderedList>

        </Box>    

        <Box mt={'10'}>
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What factors can affect fertility and reproductive health?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Several factors can influence fertility and reproductive health, including age, underlying 
      medical conditions, lifestyle choices, hormonal imbalances, genetic factors, and 
      environmental factors. It's essential to understand these factors and seek appropriate 
      care to optimize fertility potential.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        When should couples seek fertility evaluation and treatment?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Couples should consider seeking fertility evaluation and treatment if they 
      have been actively trying to conceive for one year without success (or six months 
      if the woman is over 35 years old). However, individuals with known fertility issues 
      or medical conditions should consult a fertility specialist before attempting to conceive.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the common fertility tests and procedures available?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Fertility tests and procedures may include semen analysis, hormonal evaluations, ultrasound 
      imaging, hysterosalpingography (HSG) to assess the fallopian tubes, ovarian reserve testing, 
      and genetic screening. Additionally, assisted reproductive technologies (ART) like in vitro 
      fertilization (IVF) and intrauterine insemination (IUI) may be recommended based on 
      individual circumstances.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Are there any lifestyle changes that can enhance fertility?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Yes, certain lifestyle changes can positively impact fertility. Maintaining healthy body 
      weight, adopting a balanced diet, reducing stress levels, avoiding tobacco and excessive 
      alcohol consumption, exercising regularly, and managing chronic conditions can contribute 
      to improved reproductive health.
      </Text>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>    
            
      </Flex>
      </Grid>
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems1/>
      </Flex>
      </Grid>
      <FooterItem/>
    </div>
  )
}

export default FertilityCare
