import {
  Box, Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Grid,
  Heading, Input, Radio, RadioGroup, Select, Stack, StackDivider, Text, VStack
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import FooterItem from '../components/FooterItem'
import { useNavigate } from "react-router-dom";
import { JsonToForm, JsonToParams } from "../utils";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import { isEmpty, isSet } from "lodash";

function BookSession() {

  const [name, setName] = useState('');
  const [nic, setNIC] = useState('');
  const [email, setEmail] = useState('');
  const [nationality, setNationality] = useState('false');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [paymentModes, setPaymentModes] = useState([]);
  const [paymentMode, setSelectedPaymentMod] = useState('');
  const [title, setTitle] = useState('Mr');
  const navigate = useNavigate();

  useEffect(() => {
    const body = {
      key: "12345",
    };
    getPaymentModes(Methods.POST, JsonToForm(body));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submission logic here
    // You can send the form data to a backend API or perform any other required action
    console.log('Form submitted:', {
      name,
      nic,
      phoneNumber,
      date,
      email,
      time,
    });
  };

  const getPaymentModes = (method, body) => {
    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.paymentModes;
    fetch(URL, {
      method,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      body,
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          //console.log(object);
          setPaymentModes(object.data);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleNationalityChange = (value) => {
    setNationality(value);
  };

  const getPaymentSummery = () => {

    sessionStorage.setItem("p_nic", nic);
    sessionStorage.setItem("p_name", name);
    sessionStorage.setItem("p_phone", phoneNumber);
    sessionStorage.setItem("p_email", email);
    sessionStorage.setItem("payment_mode", paymentMode);
    sessionStorage.setItem("nationality", nationality);
    sessionStorage.setItem("title", title);
    navigate("/bookingsummery");
  };

  return (
    <div>
      <Header />
      {/* <Heading mt={10} ml={10}>Book Sessions</Heading> */}

      <Box
        backgroundImage={require('../img/WebsiteStrip1.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
      // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Book Session
        </Text>
      </Box>

      <Grid
        flexDirection={["row", "column"]}
        width="100%"
        templateColumns={["1fr", "1fr", "1fr 3fr"]}
        gap={4}
        p={4}
        mt={2}
        mx="auto"
      >
        <Box>
          <Flex
            alignItems="start"
            mx="auto"
            width="100%"
            flexDirection={"column"}
          >
            <Box
              borderWidth="1px"
              width="100%"
              borderRadius="lg"
              p={4}
              display="flex"
              alignItems="center"
              bg={"#266564"}
              mx="auto"
              color="white"
            >
              <Flex flexDirection={['column', 'row']} width="100%">
                <Stack divider={<StackDivider />} width="100%" spacing='2'>
                  <Heading size='md' mb="3">Channel Details</Heading>
                  <Box>
                    <Heading size='xs' textTransform='uppercase' mr={2}>
                      Doctor Name :
                    </Heading>
                    <Text pl='4' pt='2' fontSize='sm'>
                      {sessionStorage.getItem("docName")}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Specialization :
                    </Heading>
                    <Text pl='4' pt='2' fontSize='sm'>
                      {sessionStorage.getItem("spName")}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Date :
                    </Heading>
                    <Text pl='4' pt='2' fontSize='sm'>
                      {sessionStorage.getItem("displayDate")}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Time :
                    </Heading>
                    <Text pl='4' pt='2' fontSize='sm'>
                      {sessionStorage.getItem("tTime")} (24 hrs)
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Patient No :
                    </Heading>
                    <Text pl='4' pt='2' fontSize='sm'>
                      {sessionStorage.getItem("countActivePatient")}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Doctor Notes :
                    </Heading>
                    <Text pl='4' pt='2' fontSize='sm'>

                    </Text>
                  </Box>
                </Stack>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box mx="auto" width="100%" pr={2} pl={2}>
          <Heading mb="10px" size={"lg"} color="#F8D107">
            Patient Details
          </Heading>
          <Box border="2px" borderRadius="8px" p="20px" width="100%" mx="auto" borderColor="#266564">
            <form onSubmit={handleSubmit}>
              <FormControl mb="10px">
                <FormLabel>Patient Name(Mandatory)</FormLabel>
                <Flex>
                  <Select
                    backgroundColor={"white"}
                    mt="5px"
                    marginRight="2"
                    marginBottom="2"
                    width="120px"
                    onChange={(event) => setTitle(event.target.value)}
                    value={title}
                  >
                    <option>Mr</option>
                  </Select>
                  <Input
                    mt="5px"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Flex>
              </FormControl>

              <FormControl mb="10px">
                <FormLabel htmlFor="nationality" marginRight="2">
                  Nationality
                </FormLabel>
                <RadioGroup name="nationality" value={nationality} onChange={handleNationalityChange}>
                  <Stack direction="row">
                    <Radio value="false">Sri Lankan</Radio>
                    <Radio value="true">Foreign</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>National ID No.(Mandatory for Sri Lankans)</FormLabel>
                <Input
                  type="text"
                  placeholder="National ID No."
                  value={nic}
                  onChange={(e) => setNIC(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>Phone Number(Mandatory)</FormLabel>
                <Input
                  type="tel"
                  value={phoneNumber}
                  placeholder="Phone Number(0XXXXXXXXX)"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Please enter your email address to which the final receipt will be sent"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl mb="10px">
                <FormLabel>Choose Your Payments Method</FormLabel>
                <Select
                  backgroundColor={"white"}
                  mt="5px"
                  onChange={(event) => setSelectedPaymentMod(event.target.value)}
                  value={paymentMode}
                >

                  {
                    !isEmpty(paymentModes) ?
                      paymentModes.map((option) => (
                        <option value={option.id}>{option.paymentMode}</option>
                      )) : <option>Select Option</option>
                  }
                </Select>
              </FormControl>

              <Button mt={4} colorScheme="teal" type="submit" onClick={() => getPaymentSummery()}>
                Submit
              </Button>
            </form>
          </Box>
        </Box>
      </Grid>
      <FooterItem />
    </div>
  )
}

export default BookSession
