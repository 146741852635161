import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Link,
  Select,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import { SearchIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import FooterItem from "../components/FooterItem";
import DoctorCard from "../components/DoctorCard";
import { JsonToForm, JsonToParams } from "../utils";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import { doctorSelector } from "./store/selectors";
import * as actions from "./store/actions";
import { isEmpty, isSet } from "lodash";
import Preloader from '../components/Preloader';

function DoctorSearch() {

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doctorList = useSelector(doctorSelector);
  //console.log(doctorList);
  const [selectedDate, setSelectedDate] = useState(
    sessionStorage.getItem("selectedDate") != "null"
      ? sessionStorage.getItem("selectedDate")
      : "Any"
  );
  const [spList, setSpList] = useState([]);
  const [docList, setDocList] = useState(doctorList);
  const [doctorName, setDoctorName] = useState(
    sessionStorage.getItem("doctorName") != "null"
      ? sessionStorage.getItem("doctorName")
      : "*"
  );
  const [spId, setSpId] = useState(
    sessionStorage.getItem("spId") != "null"
      ? sessionStorage.getItem("spId")
      : "*"
  );
  //const [docId, setDocId] = useState(null);
  // const [date, setDate] = useState("Any");

  useEffect(() => {
    const body = {
      key: "12345",
    };
    getSpList(Methods.POST, JsonToForm(body));
    //setDocList(doctorList);
    // setDoctorName(doctorList.name);
    // setSpId(doctorList.sp_id);
    // setSelectedDate(doctorList.date);
    getDoctorList();
    //getDoctorList(Methods.POST, JsonToForm(getDoctorBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSpList = (method, body) => {
    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.specializations;
    fetch(URL, {
      method,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      body,
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          setSpList(object.specialityList);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };
  //--------------------------------------------------------------------------------------------------------------
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getDoctorList();
    }
  };
  
  const getDoctorList = () => {


    try {
      setIsLoading(true);

      // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', spId);
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: spId,
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          setDocList(object.doctort_list);
          console.log(object);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }finally{
      setIsLoading(false);
    }


  };

  //--------------------------------------------------------------------------------------------------------------
  const getDoctorSessions = (docId) => {
    console.log(docId);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorSessions;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        doc_id: docId,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          console.log(object);
          const payload = {
            data: object.doctor,
          };

          dispatch(actions.getDoctorSessions(payload));
          sessionStorage.setItem("doc_id", docId);
          navigate("/doctorprofile");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
   <div>
     {isLoading ? (
        <Preloader />
      ) : (
     <>
      <Header />
      <Heading mt="20px" ml="20px" size={"lg"} mb={5}>
        Find Your Doctor
      </Heading>
      <Grid
        flexDirection={["column", "row"]}
        width="100%"
        templateColumns={["1fr", "2fr", "1fr 3fr"]}
        p={2}
      >
        <Box background="#1A908F" p={5} borderRadius="8px">
          <FormControl width="100%" onKeyPress={handleKeyPress}>
            {/* <FormLabel>First name</FormLabel> */}
            <Input
              backgroundColor={"white"}
              width="100%"
              mt="5px"
              placeholder="First name"
              mb={2}
              value={doctorName}
              onChange={(event) => setDoctorName(event.target.value)}
            />
            <Select
              backgroundColor={"white"}
              placeholder="Select Speciality"
              width="100%"
              mt="5px"
              onChange={(event) => setSpId(event.target.value)}
              value={spId}
            >
              {spList.map((option) => (
                <option key={option.sp_id} value={option.sp_id}>
                  {option.sp_name}
                </option>
              ))}
            </Select>
            <Box display="flex" alignItems="center">
              <Input
                type="date"
                value={selectedDate || ""}
                onChange={handleDateChange}
                px={3}
                py={2}
                borderRadius="md"
                borderColor="gray.300"
                background="white"
                top="15px"
                mb={65}
                width="100%"
                _hover={{ borderColor: "gray.400" }}
                _focus={{
                  outline: "none",
                  borderColor: "blue.500",
                  boxShadow: "0 0 0 2px rgba(66, 153, 225, 0.6)",
                }}
              />
              {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
            </Box>
          </FormControl>

          <Stack direction="row" spacing={4}>
            {/* <Link as={RouterLink} to="/doctorsearch"> */}
            <Button
              leftIcon={<SearchIcon />}
              bg="#0E5453"
              color="white"
              variant="solid"
              // top="170px"
              // left="-190px"
              _hover={{
                bg: "#004242",
              }}
              _focus={{
                bg: "#004242",
              }}
              onClick={() => getDoctorList()}
            >
              Find Your Doctor
            </Button>
      {isLoading && (
        <div className="overlay">
          <Preloader />
        </div>
      )}
            {/* </Link> */}
          </Stack>
        </Box>
        <Grid
          m={3}
          templateColumns={["1fr", "1fr", "1fr", "1fr", "1fr 1fr 1fr"]}
          gap={4}
        >
          {!isEmpty(docList)
            ? docList.map((option) => (
                <DoctorCard
                  docName={option.DocName}
                  spName={option.SpecName}
                  docImage={option.profileImage}
                  onPress={() => getDoctorSessions(option.DoctorNo)}
                />
              ))
            : null}
        </Grid>
      </Grid>
      <FooterItem />
    </>
      )}
   </div>
  );
}

export default DoctorSearch;
