import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { isEmpty, isSet } from "lodash";

const SuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const chargeTotal = queryParams.get("charge_total");
  const transactionDateTime = queryParams.get("trx_date_time");
  const approvalCode = queryParams.get("code");
  const message = queryParams.get("message");
  const trx_ref_number = queryParams.get("trx_ref_number");
  const status = queryParams.get("status");
  const txn_token = queryParams.get("txn_token");
  const invoice_number = queryParams.get("invoice_number");
  const other_info = queryParams.get("other_info");

  useEffect(() => {
    saveSuccess();
  }, []);

  const saveSuccess = () => {
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.success;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          trx_ref_number: trx_ref_number,
          mobile: sessionStorage.getItem("merchantCustomerPhone"),
          email: sessionStorage.getItem("merchantCustomerEmail"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            // sessionStorage.setItem("merchantPgIdentifier", "");
            // sessionStorage.setItem("chargeTotal", "");
            // sessionStorage.setItem("orderId", "");
            // sessionStorage.setItem("invoiceNumber", "");
            // sessionStorage.setItem("storeName", "");
            // sessionStorage.setItem("transactionDateTime", "");
            // sessionStorage.setItem("txnToken", "");
            // sessionStorage.setItem("merchantCustomerPhone", "");
            // sessionStorage.setItem("merchantCustomerEmail", "");
            console.log("Success");
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/WebsiteStrip1.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Success!
        </Text>
      </Box>
      <Box p={4} bg="gray.100" borderRadius="md" shadow="md">
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Purchase Successfully Completed!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Thanks for Purchasing.
          </AlertDescription>
        </Alert>
      </Box>
      <FooterItem />
    </Box>
  );
};

export default SuccessPage;
