import { Box, Select, Text } from '@chakra-ui/react'
import React from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'

function SearchJobs() {
  return (
    <div>
        <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Search Jobs
        </Text>
      </Box>

      <Box display="flex" bottom="10px" justifyContent="center" p="2">
      <Box bg="purple.400" p="10" rounded="md" w="750px">
      <Box display="flex">
      <Select marginRight="10px" bg="white">
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </Select>

      <Select bg="white">
        <option value="option4">Option 4</option>
        <option value="option5">Option 5</option>
        <option value="option6">Option 6</option>
      </Select>
    </Box>
    </Box>
    </Box>

    <FooterItem/>
      </Box>
    </div>
  )
}

export default SearchJobs