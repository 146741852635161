import { Fragment } from 'react';
import React from 'react'
import { Container, Text, Stack, Avatar, Icon, Image, Box } from '@chakra-ui/react';
// Here we have used react-icons package for the icon
import { ImQuotesLeft } from 'react-icons/im';

interface TestimonialAttributes {
  name: string;
  position: string;
  company: string;
  content: string;
  image: string;
}

const testimonials: TestimonialAttributes[] = [
  {
    name: 'Ben Parker',
    position: 'CEO',
    company: 'Foodtesla',
    image:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb',
    content:
      'Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper'
  }
];

const Testimonials = () => {
  return (
    <Container maxW="6xl" p={{ base: 5, md: 8 }}>
      {testimonials.map((obj, index) => (
        <Fragment key={index}>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            bgGradient="linear(to-br, #42e14e, blue.300)"
            spacing={{ base: 0, sm: 10 }}
            p={{ base: 4, sm: 10 }}
            rounded="lg"
            justify="center"
          >
            <Box width="30rem" pos="relative" 
            display={{ base: 'none', sm: 'block' }}>
              <Image
                sizes="2xl"
                pos="absolute"
                rounded="lg"
                src={require('../img/nawaloka-chairman.jpg')}
                top="0.2rem"
                boxShadow="lg"
              />
            </Box>

            <Stack direction="column" spacing={4} textAlign="left" maxW="4xl">
              <Icon as={ImQuotesLeft} w={10} h={10} color="gray.700" />
              <Text fontSize="md" fontWeight="medium">
              Providing access to the State-of-Art diagnostic and treatment technologies is a hallmark of 
              Nawaloka Hospital. Driven by our Vision to be the Hospital of Tomorrow we give precedence to 
              superior care, accuracy, and excellence in all we do.<Box as="br" /><Box as="br" />

              Among the many healthy choices one can make in life, I sincerely believe Nawaloka Hospital to 
              be a supremely healthy choice - from many perspectives. To the customer, we are a provider of 
              the finest health care available in Sri Lanka today.<Box as="br" /><Box as="br" />

              Our State-of-the Art technology, medical systems and procedures, skilled professionals and 
              years of experience in the field equate to a top tier health care provider. Within the health 
              sector too Nawaloka's stature makes it a healthy choice amongst consultants and other 
              professionals, who have no qualms in selecting Nawaloka as a fitting entity through which 
              they serve the public.<Box as="br" /><Box as="br" />

              Our consultants enjoy national repute as being among the most experienced practitioners in 
              their fields. Together, we take great pride in the quality of the care we provide and in the 
              integrity of our institution.
              </Text>
              <Stack alignItems={{ base: 'center', sm: 'flex-start' }} spacing={0}>
                <Avatar
                  size="xl"
                  showBorder={true}
                  borderColor="green.400"
                  name="Chairman"
                  src={require('../img/nawaloka-chairman.jpg')}
                  display={{ base: 'block', sm: 'none' }}
                  borderRadius="md"
                  style={{
                    objectFit: 'contain',
                    width: '50%',
                    height: '50%',
                  }}
                />
                <Text fontWeight="bold" fontSize="lg">
                Dr. Jayantha Dharmadasa
                </Text>
                <Text fontWeight="medium" fontSize="sm" color="gray.600">
                Chairman, Nawaloka Hospitals PLC
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Fragment>
      ))}
    </Container>
  );
};

export default Testimonials;