import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import HeaderPic from "../../components/HeaderPic";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import {
  AccordionButton,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Accordion from "../../components/Accordion";
import FooterItem from "../../components/FooterItem";
import surgeryIcon1 from "../../img/healthcheck1.jpeg";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import { useNavigate } from "react-router-dom";
import { Methods } from "../../constants";
import { isEmpty, isSet } from "lodash";
import { useForm } from "react-hook-form";

function Promotions() {
  const navigate = useNavigate();
  const [item, setItem] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [name, setname] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [msg, setmsg] = useState(null);
  const toast = useToast();

  const { isOpen: isOpenModal1, onOpen: onOpenModal1, onClose: onCloseModal1 } = useDisclosure()
  const { isOpen: isOpenModal2, onOpen: onOpenModal2, onClose: onCloseModal2 } = useDisclosure()
  const { isOpen: isOpenModal3, onOpen: onOpenModal3, onClose: onCloseModal3 } = useDisclosure()
  const { isOpen: isOpenModal4, onOpen: onOpenModal4, onClose: onCloseModal4 } = useDisclosure()

  useEffect(() => {
    loadPackages(Methods.POST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPackages = (method) => {
    const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getPackages;
    fetch(URL, {
      method,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          setItem(object.data);
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const generateUniqueId = () => {
    const timestamp = Date.now().toString();
    const randomNum = Math.floor(Math.random() * 1000).toString();
    return timestamp + randomNum;
  };

  const addToCart = (item) => {
    //const sessionId = generateUniqueId();

    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.addCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          package_id: item.id,
          qty: 1,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            navigate("/healthchecks/cart");
            //window.location.reload();
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const viewPackage = (id) => {
    sessionStorage.setItem("selected_package_id", id);
    navigate("/healthchecks/package-details");
  };

  const mensitem = [
    {
      id: 1,
      name: "Classic Under 40",
      image: surgeryIcon2,
      price: "Rs. 22500.00",
      slug: "product-1",
    },
    {
      id: 2,
      name: "Premier Under 40 Men",
      image: surgeryIcon2,
      price: "Rs.34000.00",
      slug: "product-2",
    },

    {
      id: 3,
      name: "Standard Over 40",
      image: surgeryIcon2,
      price: "Rs.25000.00",
      slug: "product-3",
    },

    {
      id: 4,
      name: "Canser Screening Over 40",
      image: surgeryIcon2,
      price: "Rs.40000.00",
      slug: "product-4",
    },
    {
      id: 5,
      name: "Senior Citizens Over 50",
      image: surgeryIcon2,
      price: "Rs.55000.00",
      slug: "product-5",
    },
    // Add more items as needed
  ];

  const womensitem = [
    {
      id: 1,
      name: "Classic Under 40",
      image: surgeryIcon1,
      price: "Rs. 22500.00",
      slug: "product-1",
    },
    {
      id: 2,
      name: "Premier Under 40 Men",
      image: surgeryIcon1,
      price: "Rs.34000.00",
      slug: "product-2",
    },

    {
      id: 3,
      name: "Standard Over 40",
      image: surgeryIcon1,
      price: "Rs.25000.00",
      slug: "product-3",
    },

    {
      id: 4,
      name: "Canser Screening Over 40",
      image: surgeryIcon1,
      price: "Rs.40000.00",
      slug: "product-4",
    },
    {
      id: 5,
      name: "Senior Citizens Over 50",
      image: surgeryIcon1,
      price: "Rs.55000.00",
      slug: "product-5",
    },
    // Add more items as needed
  ];


  const onSubmit = async (data) => {
    setLoading(true);

    try {

      sendmail();

      reset(); // Clear form fields
    } catch (error) {
      // Display error toast
      // toast({
      //   title: 'Error',
      //   description: 'An error occurred. Please try again later.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    }

    setLoading(false);
  };


  const sendmail = () => {
    // setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {

      const URL =
        ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.sendPromoMail;
      fetch(URL, {
        method: Methods.POST,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify({
          name: name,
          mobile: mobile,
          msg: msg,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {

            console.log(object);
            if (!object.error) {
              toast({
                title: 'Success',
                description: object.msg,
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
             // window.location.reload();
            } else {
              toast({
                title: 'Error',
                description: object.msg,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      //setIsLoading(false);
      setMobile('');
      setname('');
      setmsg('');
      

    }
  };

  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/DoorstepStripNew.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Ongoing Promotions
        </Text>
      </Box>

      <Tabs variant='soft-rounded' colorScheme='green' p={5} size={"lg"}>
  <TabList gap={2} mb={2}>
    <Tab fontWeight={'bold'} color={'pink.600'}>Special Offers</Tab>
    {/* <Tab fontWeight={'bold'}>Seasonal Offers</Tab> */}
    {/* <Tab fontWeight={'bold'}>Free</Tab> */}
  </TabList>
  <Divider/>
  <TabPanels>

  {/* <TabPanel>
    <Grid 
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(2, 1fr)",
      }}
      gap={4}
      mt="10px"
      pl="4px"
      pr="4px"
      >
        <Box>
        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='contain'
            maxW={{ base: '100%', sm: '200px' }}
            src={require('../../img/promopics/nh-logo.jpg')}
            alt='Promo 2'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Win a Free Medical Check up</Heading>

              <Text py='2'>
              Elevate your well-being with Nawaloka Hospital! 
              Enjoy a Free Medical Checkup on us – because your health is the ultimate tune-up! 
              </Text>
            </CardBody>

            <CardFooter>
              <Button variant='solid' colorScheme='red' onClick={onOpenModal1}>
                Apply
              </Button>
            </CardFooter>
          </Stack>
        </Card>

        <Modal  isOpen={isOpenModal1} onClose={onCloseModal1} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={5} backgroundColor={'pink.600'}>
            <Stack>
              <Heading size={'md'} color={'whiteAlpha.800'} mb={4}>Fill out the form and click send</Heading>
            </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl id="name" mb={4}>
                    <FormLabel color={'white'}>Name *</FormLabel>
                    <Input required type="text" {...register('name')} value={name} bg="white" onChange={(event) => setname(event.target.value)}
                    />
                  </FormControl>
                  <FormControl id="mobile" mb={4}>
                    <FormLabel color={'white'}>Mobile No *</FormLabel>
                    <Input required value={mobile} {...register('mobile')} bg="white" onChange={(event) => setMobile(event.target.value)} type="number" />
                  </FormControl>

                  <FormControl id="message" mb={4}>
                    <FormLabel color={'white'}>How did you find out about this promotion?*</FormLabel>
                    <Textarea required {...register('message')} bg="white" defaultValue={msg} onChange={(event) => setmsg(event.target.value)} resize="vertical" />
                  </FormControl>

                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="Submitting"
                    bg="#eb1a3d"
                    color="white"
                    mt={4}
                    mb={4}
                    _hover={{
                      bg: '#611415',
                      color: 'white',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}

                  >
                    Send
                  </Button>
                </form>
          </ModalBody>
        </ModalContent>
      </Modal>
        </Box>
      </Grid>
    </TabPanel> */}



    <TabPanel>
      <Grid 
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(2, 1fr)",
      }}
      gap={4}
      mt="10px"
      pl="4px"
      pr="4px"
      >
       

         {/* ----------------- New Box -------------------- */}
         {/* <Box>
        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='contain'
            maxW={{ base: '100%', sm: '200px' }}
            src={require('../../img/heartcheck.jpg')}
            alt='promo pic'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Free Heart Clinic</Heading>

              <Text py='2'>
              Join us at our Free Heart Clinic and take charge of your heart health! ❤️
              </Text>
            </CardBody>

            <CardFooter>
              <Button variant='solid' colorScheme='green' onClick={onOpenModal4}>
                View More
              </Button>
            </CardFooter>
          </Stack>
        </Card>

        <Modal  isOpen={isOpenModal4} onClose={onCloseModal4} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={5}>
          
              <Image
                src={require('../../img/heartcheck.jpg')}
                alt='Free Headache Clinic'
                borderRadius='lg'
                shadow={'md'}
              />
              <Stack mt='6' spacing='3'>
                <Heading size='md'>Free Heart Clinic</Heading>
                
                <Text fontWeight={"semibold"}>
                Are you suffering from a Chest Pain?
                </Text> 
                <Text>
                Visit the free Nawaloka Headache clinic from 26th to 
                2nd of April (Monday to Friday only) from 9 am to 7 pm
                <Box as="br" /><Box as="br" />  
                For info, call <strong>0766 713 344 / 0771 700 007</strong> or 
                 <a 
                 href="https://web.facebook.com/NawalokaHospitalColombo/posts/pfbid02ZSN2YmnZtQ58xAwZLV1WjqKmZqzmAzyhms6ZF894hnSjxgzNHUM3yMyHwyV4mcp6l?__cft__[0]=AZVEU2ZxI86ZN5S1Id5TbQojbS0nHxiqcbPu-FeRml5irf2tEwRz7MvHm3g0hZZWjAH1IlMjm4zEZIm38FmA8oRLZueBErv_NT_HgJi5mmDAdylJXSTczX4baRed31dt7LS_H-DVcBkUlhfNJFBXcyaYXCO9r_7leNy76rhc5jqbgXXQDmJmcVUEOIkrSR3Hk1E&__tn__=%2CO%2CP-R" 
                 target="_blank" style={{ fontWeight: 'bold', color: 'blue.200' }}> click here</a> to register.
                </Text>
              </Stack>
          </ModalBody>

        </ModalContent>
      </Modal>
        </Box> */}
        {/* ----------------- End of New Box -------------------- */}


        {/* ----------------- 1st Box -------------------- */}
        <Box>
        {/* <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='contain'
            maxW={{ base: '100%', sm: '200px' }}
            src={require('../../img/mdv_image.jpeg')}
            alt='promo pic'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Mother's Day Offer</Heading>

              <Text py='2'>
              In honor of Mother's Day, we are pleased to announce a special promotion
              </Text>
            </CardBody>
            <CardFooter>
             <a href="/voucher">
                          <Button variant='solid' colorScheme='green'>
                            Purchase Voucher
                          </Button>
                        </a>
            </CardFooter>
          </Stack>
        </Card> */}

        <Modal  isOpen={isOpenModal3} onClose={onCloseModal3} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={5}>
          
              <Image
                src={require('../../img/headache_pic.jpg')}
                alt='Free Headache Clinic'
                borderRadius='lg'
                shadow={'md'}
              />
              <Stack mt='6' spacing='3'>
                <Heading size='md'>Free Headache Clinic</Heading>
                
                <Text fontWeight={"semibold"}>
                Are you suffering from Headache or Migraine? 
                </Text> 
                <Text>
                Visit the free Nawaloka Headache clinic from 19th to 
                29th March (Monday to Saturday only) from 9 am to 7 pm
                <Box as="br" /><Box as="br" />  
                For info, call <strong>0771700007</strong> or 
                 <a href="https://forms.gle/Fq1HS9uHZRqLoEAj8" target="_blank" style={{ fontWeight: 'bold', color: 'blue.200' }}> click here</a> to register.
                </Text>
              </Stack>
          </ModalBody>

        </ModalContent>
      </Modal>
        </Box>
        {/* ----------------- End of 1st Box -------------------- */}



        {/* <Box>
        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='contain'
            maxW={{ base: '100%', sm: '200px' }}
            src={require('../../img/promopics/womens-promo.jpg')}
            alt='promo pic'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Women's Day Offer</Heading>

              <Text py='2'>
              Book yours now and take charge of your well-being.
              </Text>
            </CardBody>

            <CardFooter>
              <Button variant='solid' colorScheme='green' onClick={onOpenModal2}>
                View More
              </Button>
            </CardFooter>
          </Stack>
        </Card>

        <Modal  isOpen={isOpenModal2} onClose={onCloseModal2} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={5}>
          
              <Image
                src={require('../../img/promopics/womens-promo.jpg')}
                alt='Womens Day Offer'
                borderRadius='lg'
                shadow={'md'}
              />
              <Stack mt='6' spacing='3'>
                <Heading size='md'>Women's Day Offer</Heading>
                <Text>
                  Invest in women - Accelerate Progress. 
                  <Box as="br" />20% Off on 3D Mammogram and Pap Smear Test. 
                <small> (excluding professional fee)</small>
                <Box as="br" /><small>Valid till: 8th of April 2024</small>
                </Text>
                <Text color='blue.600' fontSize='xl'>
                  For more informations : 0115 730 022 / 0115 777 777
                </Text>
              </Stack>
          </ModalBody>

        </ModalContent>
      </Modal>
        </Box> */}
      {/* ----------------- End of 2nd Box -------------------- */}
    
      </Grid>
    </TabPanel>

    {/* 2nd Tab  */}
    

  </TabPanels>
</Tabs>

      <FooterItem />
    </Box>
  );
}

export default Promotions;
