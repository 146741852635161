import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Header from '../components/Header'
import Navbar from "../components/Navbar";
import HeaderPic from "../components/HeaderPic";
import FooterItem from "../components/FooterItem";

export default function Emergency() {
    return(
        <div>
            <Box>
            <Header/>
            {/* <Heading mt={10} ml={10}>Virtual Assistance</Heading> */}
            <Box
                backgroundImage={require('../img/VirtualStrip.png')}
                backgroundSize="cover"
                backgroundPosition="center"
                height="200px"
                display="flex"
                alignItems="center"
                mt="-20px"
        // justifyContent="center"
            >
                <Text
                fontSize="4xl"
                fontWeight="bold"
                ml="40px"
                color="white"
                textShadow="0 2px 4px rgba(0,0,0,0.5)"
                >
                Virtual Assistance
                </Text>
            </Box>
            {/* <Flex
                w={'full'}
                h={'205px'}
                mt={'20px'}
                backgroundImage={require('../img/virtual-assistance-images.jpg')}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
            </Flex> */}
           <Flex flex={'1'} flexDirection={['column', 'column', 'row']}>
            <Box  width={['100%', '100%', '50%']}>
                <Flex
                // mt="20px"
                w="100%"
                h={'600px'}
                // left={'698px'}
                overflow="hidden"
                backgroundImage={require('../img/virtual-assistance-img.jpeg')}
                backgroundSize={"100% 100%"}
                borderRadius="8px"
                >
                </Flex>
            </Box>
            <Box width={['100%', '100%', '50%']} mt={['5px', '10px', '30px']}>
                <Text p={10} fontSize="20px" width="100%" whiteSpace={"pre-line"}>
                Welcome to our hospital website, where we offer a comprehensive virtual assistant for your
                healthcare needs. Our virtual assistant provides a convenient and efficient way to manage your
                appointments, reservations, and bill payments online.{"\n"}{"\n"}
                With just a few clicks, you can schedule appointments with your preferred doctors, reserve 
                hospital services, and conveniently pay your bills from the comfort of your home.{"\n"}{"\n"}
                
                Our virtual assistant is designed to streamline your healthcare experience, saving you time and effort. Experience the convenience of managing your
                healthcare needs online with our virtual assistant.
                </Text>
            </Box>
            </Flex>

            <FooterItem/>
            </Box>
        </div>
    );
}