import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, GridItem, Heading, Input, Link, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import { MdArrowForward, MdCheckCircle, MdSettings } from 'react-icons/md';
import LatestItems from '../../components/LatestItems';
import LatestItems2 from '../../components/LatestItems2';

function MomAndBaby() {

  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '11');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };


  const gridItems = [
    {
      imageSrc: 'https://drive.google.com/uc?id=1jkAp6VM8PjwGvhqqmvkteCmbGCUELMBM',
      title: 'Normal Delivery',
      description: 'Maximum of 2 days hospital stay',
    listItems: ['Room charges (Non-AC including VAT)', 'Labour room charges and pharmacy products',
     'Ward procedure and surgical consumables','Resident medical officer','General nursing care','Administration costs'],
    },
    
    
    
    
    
    {
      imageSrc: 'https://drive.google.com/uc?id=1gzzBaI96jcqo8GAZFGGZS8eqFmQ3ZAlI',
      title: 'Cesarean Section',
      description: 'Maximum of 2 days hospital stay',
      listItems: ['Room charges (Non-AC including VAT)', 'Theatre drugs and consumables',
     'Operating theatre and procedure charges','Ward procedure and surgical consumables','Resident medical officer',
     'General nursing care','Administration costs'],
    },
  ];


  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/fertlity-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Mother & Baby
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Mother & Baby</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0770 700 077{'\n'}
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Box position="relative">
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box textAlign={'justify'}>
        <Text mb={2} whiteSpace={"pre-line"}>
        Welcome to the heart of compassionate care for mothers and newborns at Nawaloka Hospitals. 
        Our Mother & Baby Care Unit is designed to provide a nurturing environment, ensuring the well-being of 
        both mother and child during the transformative journey of childbirth.
        {/* {'\n'} */}

        <Text fontSize={'xl'} fontWeight={'bold'} mt={6}>Our Commitment to Excellence</Text>
        <Text mb={4} whiteSpace={"pre-line"}>
        Our expert team is dedicated to providing personalized and comprehensive care throughout 
        your pregnancy journey.
        </Text>
        <Text fontSize={'xl'} fontWeight={'bold'}>Modern Facilities</Text>
        <Text mb={4} whiteSpace={"pre-line"}>
        Our state-of-the-art facilities ensure a comfortable and safe environment for labor, 
        delivery, and postnatal care.
        </Text>
        <Text fontSize={'xl'} fontWeight={'bold'}>Neonatal Expertise</Text>
        <Text mb={4} whiteSpace={"pre-line"}>
        In case of any newborn concerns, our Neonatal Intensive Care Unit (NICU) and lactation support 
        are here for you.
        </Text>
        <Text fontSize={'xl'} fontWeight={'bold'}>Family-Centered Approach</Text>
        <Text mb={4} whiteSpace={"pre-line"}>
        We believe in including your loved ones in the experience, fostering a supportive environment for the 
        entire family.{'\n'}
        Choose Nawaloka Hospitals for compassionate and excellent Mother & Baby Care.
        </Text>

        </Text>
        <Text fontSize={'xl'} fontWeight={'bold'}>A few guidelines for expectant mothers</Text>
        <UnorderedList>
          <ListItem>
            Proper nutrition plays a vital role during pregnancy. It is very important to have
            wholesome, healthy food which contains all the necessary nutritional requirements.
            (eg. fruit, vegetables, meat, fish, eggs, yogurt, other milk foods and cereals).
          </ListItem>
          <ListItem>
          Take essential vitamins (Folic acid) minerals (iron and Calcium tablets) vaccination
          (against tetanus), and worm treatment according to your doctor's instructions.
          </ListItem>
          <ListItem>
          Attending the pre-natal clinic is a must.
          </ListItem>
          <ListItem>
          Try to relax and take things easy. Stress could affect the pregnancy and the baby.
          </ListItem>
          <ListItem>
          Drink a lot of water, at least ten glasses a day. It is beneficial to take fruit drinks too.
          </ListItem>
          <ListItem>
          Walking is an excellent exercise during pregnancy. Seek medical advice before
          engaging in other forms of exercise.
          </ListItem>
          <ListItem>
          Breastfeed the baby at least during the first six months.
          </ListItem>
          <ListItem>
          Alcohol and tobacco are both very harmful for the baby, even indirect exposure to
          tobacco smoke should be avoided.
          </ListItem>
          <ListItem>
          Avoid the use or exposure to toxic chemicals.
          </ListItem>
          <ListItem>
          Watch your weight (not to be overweight or underweight)
          </ListItem>
        </UnorderedList>

        <Text fontSize={'xl'} fontWeight={'bold'} mt={6}>Painless Child Birth</Text>
        <Text mb={2} whiteSpace={"pre-line"}>
        The birth of a child is a highly significant event in a family. As all of us are aware, 
        natural childbirth is a strenuous event. With advanced technology, Nawaloka Hospital now offers
        painless childbirth. In most Western countries this is a popular method of painless
        delivery referred to as epidural labor Analgesia. The whole procedure is conducted by a
        friendly qualified consultant anesthetist. After injecting pain-reducing medicine you can
        have a pain-free childbirth and enjoy the newborn.
        {/* {'\n'} */}

        </Text>
        
        <Text fontSize={'xl'} fontWeight={'bold'} mt={6}>Facilities</Text>
        <UnorderedList>
          <ListItem>
          Private Labor Room suites (with A/C and private music facility)
          </ListItem>
          <ListItem>
            Luxury / Deluxe and standard room facilities
          </ListItem>
          <ListItem>
          State-of-the-art Neonatal Intensive Care Unit
          </ListItem>
          <ListItem>
          Well-trained Nursing Staff
          </ListItem>
          <ListItem>
          Special Immunization package prices
          </ListItem>
        </UnorderedList>

        <Text mt={6}>*Conditions</Text>
        <Text fontSize={'xs'}>These packages cannot be combined or redeemed with other promotion schemes.
        Management reserves all rights to terminate the packages without prior notice.
        </Text>

        
        </Box>  

        {/* <Box>
        <Text fontSize={'2xl'} fontWeight={'bold'}>Delivery Package Details</Text>
        <Grid
          templateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={6}
          pl={4}
          pr={4}
          mt={4}
          width="100%"
          textAlign="center"
          >
      {gridItems.map((item, index) => (
        <GridItem key={index} w="100%" h="280" position="relative" borderRadius={'50px'}>
          <img src={item.imageSrc} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%', borderRadius: '50px' }} />
          <Box
            onClick={() => onOpen(index)}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            background="rgba(206, 162, 246, 1.0)"
            opacity="0.5"
            borderRadius={'50px'}
            _hover={{
              background: 'rgba(206, 162, 246, 0.3)',
              cursor: 'pointer',
            }}
          ></Box>
          <Box
            onClick={() => onOpen(index)}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="#ffffff"
            fontWeight="bold"
            fontSize="20px"
            textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)"
            _hover={{
              cursor: 'pointer',
            }}
          >
            {item.title}
          </Box>
        </GridItem>
      ))}

      {selectedModalIndex !== null && (
        <Modal isOpen={isOpen} onClose={onClose} >
          <ModalOverlay />
          <ModalContent>
          <Box
            bgImage={`url('${gridItems[selectedModalIndex].imageSrc}')`} // Replace '/path/to/cover-image.jpg' with the actual path to your cover image
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            borderBottom="1px solid"
            borderColor="gray.200"
            p={4}
          >
            <ModalHeader h="200px" color="white" textShadow="0 2px 4px rgba(0,0,0,0.5)"></ModalHeader>
            <ModalCloseButton />
          </Box>
            <ModalBody mb={'5'}>

            <Heading mb="4" fontSize={'2xl'}>{gridItems[selectedModalIndex].title}</Heading>
            <Text>{gridItems[selectedModalIndex].description}</Text>
            <List spacing={2} mt={4} ml={4} styleType="disc">
              {gridItems[selectedModalIndex].listItems.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </List>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Grid>
        </Box> */}
      </Flex>
      </Grid>
      </Box>
      
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems2/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default MomAndBaby
