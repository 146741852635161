import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, GridItem, Heading, Input, Link, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import { MdArrowForward, MdCheckCircle, MdSettings } from 'react-icons/md';
import LatestItems from '../../components/LatestItems';
import LatestItems1 from '../../components/LatestItems1';

function Diabetes() {

  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '21');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };



  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/fertlity-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Diabetes Centre
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "1fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center" pl={4} pr={4}>
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
        </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Diabetes Centre</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            {/* <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0770 700 077{'\n'}
            </Text> */}
        </VStack>
      </Box>
      </Flex>
      <Box position="relative">
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box textAlign={'justify'}>
          <Text mb={2} fontSize={'18px'} fontWeight={'semibold'}>Welcome to Nawaloka Diabetes Centre - Your Partner in Managing Diabetes</Text>
        <Text mb={2} whiteSpace={"pre-line"}>
        At Nawaloka Diabetes Centre, a cornerstone of Nawaloka Hospitals PLC, we are dedicated to empowering individuals 
        in their journey to manage and overcome diabetes. Our multidisciplinary team of skilled healthcare professionals 
        combines cutting-edge technology with compassionate expertise to provide personalized treatment plans, education, 
        and support. We strive to enhance the quality of life for our patients, fostering a community where health and 
        well-being thrive. Your wellness is our priority, and we are committed to being your trusted partner in the 
        fight against diabetes.
        {'\n'}
        </Text>

        <Box mt="40px">

        <Text mt={4} fontSize={'18px'} fontWeight={'semibold'}>
        High-Risk Factors for Diabetes
      </Text>

      <Text mb={4}>
      Understanding your risk factors is crucial in diabetes prevention and management. <Box as="br" /> The high-risk factors include:
      </Text>

      <UnorderedList>
        <ListItem>Family history of Diabetes</ListItem>
        <ListItem>Age over 45 years</ListItem>
        <ListItem>Diabetes during pregnancy or delivering &gt; 9 pounds.</ListItem>
        <ListItem>Prolonged use of certain medication</ListItem>
      </UnorderedList>


            <Heading size={'sm'} mb={'2'} mt={4}>Common Causes</Heading>
      <UnorderedList>
        <ListItem>Obesity</ListItem>
        <ListItem>Unhealthy eating habits</ListItem>
        <ListItem>High stress</ListItem>
        <ListItem>Frequent passing of urine</ListItem>
        <ListItem>Increased thirst</ListItem>
        <ListItem>Lethargy</ListItem>
        <ListItem>Numbness of toes and feet</ListItem>
      </UnorderedList>

      <Text mt={4}>
      If you have any of the above symptoms, have your diabetes screening done at Nawaloka
      Diabetic Center today, as tomorrow may be too late.
      </Text>

      <Text mt={4} fontWeight={'semibold'}>
      Prevention of Diabetes.
      </Text>

      <UnorderedList>
        <ListItem>Healthy Diet</ListItem>
        <ListItem>Stress Management</ListItem>
        <ListItem>Regular Exercise</ListItem>
        <ListItem>Regular checkups - will help you detect the pre-diabetes state so that you can take action
to prevent yourself from going into a diabetes state</ListItem>
      </UnorderedList>

      <Text mt={4} fontWeight={'semibold'}>
      The Nawaloka Serene Diabetes Center now offers you,
      </Text>

      <UnorderedList>
        <ListItem>Initial Diabetes Screening Package</ListItem>
        <ListItem>3 months screening package</ListItem>
        <ListItem>6 months screening package</ListItem>
        <ListItem>Annual screening package</ListItem>
        <ListItem>Complete Neuropathy (Nerve) assessment</ListItem>
      </UnorderedList>

    </Box>
  </Box>  

      </Flex>
      </Grid>
      </Box>
      
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems1/>
      </Flex>

      </Grid>
      <FooterItem/>
    </div>
  )
}

export default Diabetes
